import { FlexView } from 'components/common'
import StockLinkSelect from 'components/home/StockLinkSelect'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Manager, Popper, Reference } from 'react-popper'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled(FlexView)`
    cursor: pointer;
    position: relative;
    width: 260px;
    min-height: 130px;
    background: transparent;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    margin: 8px 8px 0px;
    box-shadow: none;
    padding: 16px;
    border: 3px solid ${({ theme }) => theme.colors.lightGray};
    transition: all 0.2s;

    & > ${FlexView}:first-child {
        margin-top: 0px;
        font-family: Arvo;
        font-size: 32px;
    }

    & > ${FlexView} {
        transition: all 0.2s;
        filter: grayscale(100%);
        cursor: pointer;
        user-select: none;
    }

    &:hover {
        & > ${FlexView} {
            filter: grayscale(0%);
        }

        box-shadow: ${({ theme }) => theme.boxShadows.high};
    }

    @media screen and (min-width: 1400px) {
        width: 280px;
    }

    @media screen and (min-width: 1800px) {
        width: 400px;

        & > ${FlexView} {
            font-size: ${({ theme }) => theme.fontSizes.subtitle};
        }
    }
`

const HomeCard = ({ option, onCardInfoClick }) => {
  const node = useRef(null)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { title, baseUrl } = option 

  const redirectOptions = [
    {
      value: 'nominal-data',
      label: t('Nominal Data'),
      role: 'nominal-data', 
      type: 'view'
    },
    {
      value: 'actual-data',
      label: t('Actual Data'),
      role: 'view-data', 
      type: 'view'
    }, 
    {
      value: 'nominal-data',
      label: t('Nominal Data'),
      role: 'nominal-data', 
      type: 'upload'
    },
    {
      value: 'actual-data',
      label: t('Actual Data'),
      role: 'view-data', 
      type: 'upload'
    }, 
    {
      value: 'certificates',
      label: t('Certificates'),
      role: 'certificates',
      type: 'upload'
    }, 
    {
      value: 'end-users',
      label: t('End Users'),
      role: 'management',
      type: 'management'
    },
    {
      value: 'supplier-management',
      label: t('Suppliers'),
      role: 'supplier-management',
      type: 'management'
    },
    {
      value: 'printers-management',
      label: t('Printers'),
      role: 'printers-management',
      type: 'management'
    }
   ]

   const options = useMemo(
        () =>
            _.chain(redirectOptions)
                .filter(({ type }) => baseUrl.includes(type))
                .map(({ value, label }) => ({
                    value,
                    label: t(label),
                }))
                .value(),
        [t, baseUrl, redirectOptions],
    )

    const handleOutsideClick = (e) => {
        node && node.current && !node.current.contains(e.target) && setIsOpen(false)
    }

    const onSelect = (value) => {
        history.push(`${baseUrl}/${value}`)
    }

    const onCardClick = () => {
        if (option.baseUrl === 'validation') history.push(`${baseUrl}`)
        else setIsOpen(true)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    return (
        <Manager>
            <div ref={node}>
                <Reference>
                    {({ ref }) => (
                        <Wrapper ref={ref} onClick={onCardClick}>
                            <FlexView fontWeight="bold" fontSize="big" color="darkGray">
                                {' '}
                                {title}{' '}
                            </FlexView>
                            {/* <FlexView fontWeight="bold" fontSize="big" color="primary" margin="8px 0px 0px" flex="1"> {key} </FlexView> */}
                        </Wrapper>
                    )}
                </Reference>
                <Popper placement="right">
                    {({ ref, style, placement, scheduleUpdate }) => (
                        <StockLinkSelect
                            {...{
                                style,
                                scheduleUpdate,
                                placement,
                                isOpen,
                                options,
                                onSelect,
                                ref,
                            }}
                        />
                    )}
                </Popper>
            </div>
        </Manager>
    )
}

export default HomeCard
