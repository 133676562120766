import { FlexView } from 'components/common'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const OptionsWrapper = styled(FlexView)`
    font-family: 'Roboto';
    display: flex;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    transform: ${({ popperTransform, open }) =>
        `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
    flex-direction: column;
    margin: 8px;
    background: #ffffff;
    max-height: 400px;
    box-shadow: ${({ theme }) => theme.boxShadows.high};
    overflow-y: auto;
    border-radius: 8px;
    z-index: 999;
    transition: all 0.2s ease;
`

const Option = styled.div`
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 32px);
    font-family: 'Roboto';
    font-size: ${({ theme }) => theme.fontSizes.big};
    user-select: none;
    min-width: 100px;
    background-color: ${({ active, theme }) => (active ? theme.colors.secondary : 'transparent')};
    color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.gray)};
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${({ active, theme }) => (active ? theme.colors.secondary : theme.colors.offWhite)};
    }
`

const StockLinkSelect = React.forwardRef(({ style, scheduleUpdate, placement, isOpen, options, onSelect }, ref) => {
    useEffect(() => {
        scheduleUpdate()
    }, [isOpen, scheduleUpdate])

    const onOptionClick = (value) => () => onSelect(value)
    const history = useHistory()

    return (
        <OptionsWrapper
            ref={ref}
            style={style}
            data-placement={placement}
            open={isOpen}
            popperTransform={style.transform}
        >
            {options.map(({ value, label }) => (
                <Option
                    key={value}
                    value={value}
                    onClick={onOptionClick(value)}
                    active={value === history.location.pathname.split('/')[2]}
                >
                    {label}
                </Option>
            ))}
        </OptionsWrapper>
    )
})

StockLinkSelect.displayName = 'StockLinkSelect'

export default StockLinkSelect
