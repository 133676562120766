export default {
    colors: {
        primary: '#59CBE4',
        secondary: '#6a5cff',
        tertiary: '#fb7171',
        success: '#59e480',
        fontSuccess: '#1ebf4b',
        info: '#59CBE4',
        warning: '#fbca4d',
        error: '#FB7171',
        background: '#c0c0c01a',
        header: '#f8faff',
        black: '#000000',
        darkGray: '#303030',
        gray: '#777777',
        metalic: '#919098',
        lightGray: '#e0e0e0',
        whitesmoke: '#f1f1f1',
        offWhite: '#f5f5f5',
        white: '#ffffff',
        purple: '#6A5CFF',
    },
    gradients: {
        main: 'linear-gradient(90deg, #00cdff 0%, #6a5cff 100%)',
    },
    borderRadius: {
        card: '16px',
        input: '8px',
        component: '8px',
        button: '4px',
    },
    iconSizes: {
        small: '30px',
        medium: '40px',
        large: '50px',
    },
    fontSizes: {
        title: '28px',
        subtitle: '24px',
        big: '20px',
        medium: '16px',
        small: '14px',
        tiny: '10px',
    },
    boxShadows: {
        none: 'none',
        low: '0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132)',
        medium: '0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132)',
        high: '0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132)',
        veryHigh: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
    },
}
