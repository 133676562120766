import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
        'VLR-Authorization': btoa(JSON.stringify({ id: process.env.REACT_APP_ID })),
    },
})

addLogger(instance, SHOULD_DECOMPRESS)

const requests = {
    getPipeDataFilters: async (params, idToken) => {
        try {
            const response = await instance.post('/data/get-pipe-data-filters', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPipeDataFromFilters: async (params, idToken) => {
        try {
            const response = await instance.post('data/get-pipe-data-from-filters', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getNominalData: async (params, idToken) => {
        try {
            const response = await instance.post('data/get-nominal-data', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getDataLoads: async (params, idToken) => {
        try {
            const response = await instance.post('data/get-data-loads', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getDataFromLoad: async (params, idToken) => {
        try {
            const response = await instance.post('data/get-data-from-load', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getDataCountFromLoad: async (params, idToken) => {
        try {
            const response = await instance.post('data/get-data-count-from-load', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getQualityCertificate: async (params, idToken) => {
        try {
            const response = await instance.post(
                'documents-management/get-quality-certificate',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getAvailableCertificates: async (params, idToken) => {
        try {
            const response = await instance.post('data/get-available-certificates', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getAllCertificatesByClient: async (params, idToken) => {
        try {
            const response = await instance.post(
                'data/get-all-certificates-by-client',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPreSignedURL: async (params, idToken) => {
        try {
            const response = await instance.post(
                'documents-management/get-pre-signed-url',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    updateQualityCertificate: async (params, idToken) => {
        try {
            const response = await instance.post('quality-certificate/update', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    validateLoad: async (params, idToken) => {
        try {
            const response = await instance.post('validation-process/validate-load', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getExcelTemplate: async (params, idToken) => {
        try {
            const response = await instance.post(
                'documents-management/get-excel-template',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getValidationSchema: async (params, idToken) => {
        try {
            const response = await instance.post(
                'documents-management/get-validation-schema',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    createMaterial: async (params, idToken) => {
        try {
            const response = await instance.post('material/create', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    createProduct: async (params, idToken) => {
        try {
            const response = await instance.post('product/create', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getAllMaterialsFromFilters: async (params, idToken) => {
        try {
            const response = await instance.post('material/get-all-from-filters', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getValidatedErpRefs: async (params, idToken) => {
        try {
            const response = await instance.post('material/get-validated-erp-refs', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getAvailableEndUsers: async (params, idToken) => {
        try {
            const response = await instance.post('end-user/get-available', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    createEndUser: async (params, idToken) => {
        try {
            const response = await instance.post('end-user/create', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    deleteEndUser: async (params, idToken) => {
        try {
            const response = await instance.post('end-user/delete', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getEndUsers: async (params, idToken) => {
        try {
            const response = await instance.post('end-user/get', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getSuppliers: async (params, idToken) => {
        try {
            const response = await instance.post('supplier-management/get', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    createSupplier: async (params, idToken) => {
        try {
            const response = await instance.post('supplier-management/create', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    deleteSupplier: async (params, idToken) => {
        try {
            const response = await instance.post('supplier-management/delete', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getActualDataCountFromFilters: async (params, idToken) => {
        try {
            const response = await instance.post(
                'data/get-actual-data-count-from-filters',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getTotalAmountOfLoads: async (params, idToken) => {
        try {
            const response = await instance.post(
                'load/total-amount',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },

    getDataLoadsWithLimitAndOffset: async (params, idToken) => {
        try {
            const response = await instance.post(
                'load/data-loads-with-pagination',
                params,
                getDefaultHeader(idToken),
            )
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
}
export default requests
