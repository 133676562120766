import moment from 'moment'

/**
 * Formats a given date to a given format
 * @param {string} date Date to which the format will be applied
 * @param {string} format Date format. By default is 'YYYY-MM-DD   HH:mm'
 * @returns The formatted date
 */
export const dateFormatter = (date, format = 'YYYY-MM-DD  HH:mm:ss') => {
    return date ? moment(date).format(format) : ''
}
