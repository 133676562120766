import api from 'api/api'
import { Button, Card, FlexView, Icon, Modal } from 'components/common'
import LoadingOverlay from 'components/common/LoadingOverlay'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'

const Title = styled.div`
    font-family: Roboto;
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CloseButton = styled(Button)`
    border-radius: 5px;
    border: 0px;
    display: inline-block;
    cursor: pointer;
    font-size: 15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position: relative;
        top: 1px;
    }
`
const GuideModalCard = styled(Card)`
    width: 528px;
`

const GuideModal = ({ isOpen, onOutsideClick, onCancel, type }) => {
    const { t } = useTranslation()
    const { setPageLoading, pageLoading } = useContext(AppContext)
    const { idToken } = useContext(UserContext)

    const downloadExcelTemplate = useCallback(
        () =>
            new Promise((resolve, reject) => {
                const params = { templateType: type }
                api.getExcelTemplate(params, idToken)
                    .then((result) => {
                        const link = document.createElement('a')
                        link.href = result.url
                        link.target = '_blank'
                        link.click()
                        setPageLoading(false)
                    })
                    .catch((e) => {
                        setPageLoading(false)
                        toast.error(e.data || e.message)
                        reject(e)
                    })
            }),
        [idToken, setPageLoading, type],
    )

    const onDownloadClick = () => {
        try {
            setPageLoading(true)
            downloadExcelTemplate()
        } catch (e) {
            setPageLoading(false)
            toast.error(e.data || e.message)
        }
    }

    return (
        <Modal isOpen={isOpen} onOutsideClick={onOutsideClick} onModalExit={onCancel}>
            <LoadingOverlay visible={pageLoading} />
            <GuideModalCard flexDirection="column" alignItems="center" justifyContent="center">
                <FlexView
                    flexDirection="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    margin="0px 0px 30px 0px "
                >
                    <FlexView flexDirection="row" justifyContent="flex-start" width="90%">
                        <FlexView flexDirection="column" alignItems="flex-start" justifyContent="center">
                            <Title>
                                {' '}
                                <i> {t('Guide')} </i>{' '}
                            </Title>
                            {/* <Subtitle> { subtitle } </Subtitle> */}
                        </FlexView>
                    </FlexView>
                    <FlexView flexDirection="row" justifyContent="flex-end">
                        <CloseButton onClick={onOutsideClick}>
                            <Icon name="cross" width="20px" height="20px" color="error" />
                        </CloseButton>
                    </FlexView>
                </FlexView>
                <FlexView>{t('Guide Message')}</FlexView>
                <FlexView flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                    <Button backgroundColor="primary" color="white" margin="15px" onClick={() => onDownloadClick()}>
                        {' '}
                        {t('DOWNLOAD')}{' '}
                    </Button>
                </FlexView>
            </GuideModalCard>
        </Modal>
    )
}

export default GuideModal
