import { FlexView } from 'components/common'
import TableCard from 'containers/table/TableCard'
import TableColumnSelect from 'containers/table/TableColumnSelect'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const FlexColumnData = styled(FlexView)`
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    margin-right: 1 rem;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
`

const ActualLoadDetails = ({ tableData, columns }) => {
    const { height } = useWindowSize(0)
    const [deadSpace, setDeadSpace] = useState(0)
    useEffect(() => {
        setDeadSpace(450)
    }, [height])

    return (
        <FlexView width="100%" flexWrap="wrap">
            <FlexView flexDirection="row" justifyContent="flex-start" maxWidth="100%">
                <FlexColumnData maxWidth="100%">
                    <TableColumnSelect
                        columns={columns}
                        data={tableData}
                        isLoad={true}
                        isNominal={false}
                        deadSpace={deadSpace}
                    ></TableColumnSelect>
                </FlexColumnData>
                <FlexColumnData maxWidth="100%" minWidth="10rem" height="100%">
                    <TableCard columns={columns} tableData={tableData} customDeadSpace={deadSpace}>
                        {' '}
                    </TableCard>
                </FlexColumnData>
            </FlexView>
        </FlexView>
    )
}

export default ActualLoadDetails
