import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { DialogModal } from 'components/form'
import UploadBox from 'components/upload/UploadBox'
import CertificateDescriptionSelect from 'containers/common/CertificateDescriptionSelect'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'stores/AppStore'
import { UploadContext } from 'stores/UploadStore'
import styled from 'styled-components'

const Title = styled.div`
    font-family: Roboto;
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CloseButton = styled(Button)`
    border-radius: 5px;
    border: 0px;
    display: inline-block;
    cursor: pointer;
    font-size: 15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position: relative;
        top: 1px;
    }
`

const UploadCertificatesModal = ({ isOpen, title, subtitle, onOutsideClick, onCancel }) => {
    const { t } = useTranslation()

    const {
        certificateFile,
        dscVersion,
        getUploadPreSignedUrl,
        uploadQualityCertificate,
        setCertificateFile,
        toggleStatus,
        certificateName,
    } = useContext(UploadContext)

    const { pageLoading } = useContext(AppContext)

    const [isOpenDialog, setIsOpenDialog] = useState(false)

    const OutSideClick = () => {
        setIsOpenDialog(false)
        onOutsideClick(false)
    }

    const validateButton = () => {
        if (_.isEmpty(certificateFile)) {
            return true
        } else if (!dscVersion && toggleStatus) {
            return true
        }
        return false
    }

    const onUpload = async (isConfirmation) => {
        if (isConfirmation) {
            confirmUpload()
        }

        const name = certificateFile?.name?.split('.').slice(0, -1).join('.')

        if (certificateFile && name !== certificateName) {
            setIsOpenDialog(true)
        } else {
            confirmUpload()
        }
    }

    const confirmUpload = async () => {
        const result = await getUploadPreSignedUrl()
        if (result) {
            await uploadQualityCertificate(result.url)
            setIsOpenDialog(false)
            await onOutsideClick(true)
        }
    }

    const onDialogCancel = async () => {
        setIsOpenDialog(false)
    }

    return (
        <Modal isOpen={isOpen} onOutsideClick={OutSideClick} onModalExit={onCancel}>
            <Card width="900px" flexDirection="column" alignItems="center" justifyContent="space-between">
                <LoadingOverlay visible={pageLoading} />
                <FlexView flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
                    <FlexView
                        flexDirection="row"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        margin="0px 0px 30px 0px "
                    >
                        <FlexView flexDirection="row" justifyContent="flex-start" width="90%">
                            <FlexView flexDirection="column" alignItems="flex-start" justifyContent="center">
                                <Title>
                                    {' '}
                                    <i> {t(title)} </i>{' '}
                                </Title>
                                <Subtitle> {t(subtitle)} </Subtitle>
                            </FlexView>
                        </FlexView>
                        <FlexView flexDirection="row" justifyContent="flex-end">
                            <CloseButton onClick={OutSideClick}>
                                <Icon name="cross" width="20px" height="20px" color="error" />
                            </CloseButton>
                        </FlexView>
                    </FlexView>
                    <FlexView flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
                        <CertificateDescriptionSelect></CertificateDescriptionSelect>
                        <UploadBox
                            fontSize="5"
                            setFile={setCertificateFile}
                            text={
                                !_.isEmpty(certificateFile)
                                    ? certificateFile?.name
                                    : t('Click or drag a file to upload')
                            }
                        ></UploadBox>
                    </FlexView>
                </FlexView>
                <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" width="100%">
                    <Button
                        backgroundColor="primary"
                        color="white"
                        width="20%"
                        margin="10px"
                        onClick={() => onUpload(false)}
                        disabled={validateButton()}
                    >
                        {t('Upload').toUpperCase()}
                    </Button>
                </FlexView>
            </Card>
            <DialogModal
                isOpen={isOpenDialog}
                onConfirm={() => onUpload(true)}
                title={t('Different certificate file name')}
                text={t('Different certificate file name confirmation message') + '?'}
                confirmText={t('Yes')}
                cancelText={t('No')}
                onCancel={() => onDialogCancel()}
            ></DialogModal>
        </Modal>
    )
}

export default UploadCertificatesModal
