import { css } from 'styled-components'

export const withColor =
    (defaultValue = 'inherit') =>
    ({ color, theme }) => {
        return css`
            color: ${theme.colors[color] || color || theme.colors[defaultValue] || defaultValue};
        `
    }

export const withBackgroundColor =
    (defaultValue = 'inherit') =>
    ({ backgroundColor, theme }) => {
        return css`
            background-color: ${theme.colors[backgroundColor] ||
            backgroundColor ||
            theme.colors[defaultValue] ||
            defaultValue};
        `
    }

export const withBackgroundGradient =
    (defaultValue = 'inherit') =>
    ({ backgroundGradient, theme }) => {
        return (
            backgroundGradient &&
            css`
                background: ${theme.gradients[backgroundGradient] ||
                theme.gradients[defaultValue] ||
                theme.colors[defaultValue] ||
                defaultValue};
            `
        )
    }

export const withWidth =
    (defaultValue = 'auto') =>
    ({ width }) => {
        return css`
            width: ${width || defaultValue};
        `
    }

export const withMinWidth =
    (defaultValue = 'none') =>
    ({ minWidth }) => {
        return css`
            min-width: ${minWidth || defaultValue};
        `
    }

export const withMaxWidth =
    (defaultValue = 'none') =>
    ({ maxWidth }) => {
        return css`
            max-width: ${maxWidth || defaultValue};
        `
    }

export const withHeight =
    (defaultValue = 'auto') =>
    ({ height }) => {
        return css`
            height: ${height || defaultValue};
        `
    }

export const withMinHeight =
    (defaultValue = 'none') =>
    ({ minHeight }) => {
        return css`
            min-height: ${minHeight || defaultValue};
        `
    }

export const withMaxHeight =
    (defaultValue = 'none') =>
    ({ maxHeight }) => {
        return css`
            max-height: ${maxHeight || defaultValue};
        `
    }

export const withMargin =
    (defaultValue = '0px') =>
    ({ margin }) => {
        return css`
            margin: ${margin || defaultValue};
        `
    }

export const withPadding =
    (defaultValue = '0px') =>
    ({ padding }) => {
        return css`
            padding: ${padding || defaultValue};
        `
    }

export const withBorderRadius =
    (defaultValue = '0px') =>
    ({ borderRadius, theme }) => {
        return css`
            border-radius: ${theme.borderRadius[borderRadius] ||
            borderRadius ||
            theme.borderRadius[defaultValue] ||
            defaultValue};
        `
    }

export const withElevation =
    (defaultValue = 'none') =>
    ({ elevation, theme }) => {
        return css`
            box-shadow: ${theme.boxShadows[elevation] || theme.boxShadows[defaultValue] || defaultValue};
        `
    }

export const withFlex =
    (defaultValues = {}) =>
    ({ flex, flexWrap, flexDirection, alignItems, alignSelf, justifyContent }) =>
        css`
            display: flex;
            ${flex &&
            css`
                flex: ${flex};
            `}
            flex-wrap: ${flexWrap || defaultValues.flexWrap || 'nowrap'};
            flex-direction: ${flexDirection || defaultValues.flexDirection || 'column'};
            align-items: ${alignItems || defaultValues.alignItems || 'flex-start'};
            align-self: ${alignSelf || defaultValues.alignSelf || 'auto'};
            justify-content: ${justifyContent || defaultValues.justifyContent || 'flex-start'};
        `

export const withFontSize =
    (defaultValue = 'medium') =>
    ({ fontSize, theme }) => {
        return css`
            font-size: ${theme.fontSizes[fontSize] || fontSize || theme.fontSizes[defaultValue] || defaultValue};
        `
    }

export const withFontWeight =
    (defaultValue = 'normal') =>
    ({ fontWeight }) => {
        return css`
            font-weight: ${fontWeight || defaultValue};
        `
    }

export const withPosition =
    (defaultValue = 'static') =>
    ({ position }) => {
        return css`
            position: ${position || defaultValue};
        `
    }
