import { Card } from 'components/common'
import React from 'react'

import FormCardContent from '../upload/FormCardContent'
import FormCardHeader from '../upload/FormCardHeader'

const FormCard = ({ type }) => {
    return (
        <Card flexDirection="column" justifyContent="flex-start" width="100%">
            <FormCardHeader type={type}></FormCardHeader>
            <FormCardContent type={type}></FormCardContent>
        </Card>
    )
}

export default FormCard
