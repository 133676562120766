import api from 'api/api'
import apiS3 from 'api/api-s3'
import _ from 'lodash'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { s3Actions } from 'utils/constants'

const initialState = {
    actualFile: {},
    canSave: false,
    certificateData: [],
    certificateFile: {},
    dscVersion: '',
    endUser: null,
    filePath: '',
    idCertificate: null,
    isSaving: false,
    isUploading: false,
    loadTitle: '',
    nominalFile: {},
    packingListFile: {},
    plant: null,
    schemaErrors: [],
    toggleStatus: false,
    uploadData: {},
    fileType: '',
    certificateName: '',
}

export const UploadContext = React.createContext(initialState)

export const UploadProvider = ({ children }) => {
    const { setPageLoading, lengthUnit, weightUnit, odNominal, wtNominal, mkloss } = useContext(AppContext)
    const { idToken, currentUser } = useContext(UserContext)
    const history = useHistory()
    const { t } = useTranslation()

    const [actualFile, setActualFile] = useState({})
    const [canSave, setCanSave] = useState(false)
    const [certificateData, setCertificateData] = useState([])
    const [certificateFile, setCertificateFile] = useState({})
    const [certificateName, setCertificateName] = useState('')
    const [dscVersion, setDscVersion] = useState('')
    const [endUser, setEndUser] = useState(null)
    const [enable, setEnable] = useState(true)
    const [filePath, setFilePath] = useState('')
    const [fileType, setFileType] = useState('')
    const [idCertificate, setIdCertificate] = useState(null)
    const [isUploading, setIsUploading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [loadTitle, setLoadTitle] = useState('')
    const [nominalFile, setNominalFile] = useState({})
    const [packingListFile, setPackingListFile] = useState({})
    const [plant, setPlant] = useState(null)
    const [schemaErrors, setSchemaErrors] = useState([])
    const [uploadData, setUploadData] = useState({})
    const [toggleStatus, setToggleStatus] = useState(false)

    history.listen(() => {
        init()
    })

    const init = useCallback(() => {
        setActualFile({})
        setNominalFile({})
        setPackingListFile({})
        setUploadData({})
        setPlant(null)
        setDscVersion('')
        setCanSave(false)
        setEndUser(null)
        setLoadTitle('')
        setSchemaErrors([])
        setIsUploading(false)
        setIsSaving(false)
        setToggleStatus(false)
        setFileType('')
    }, [])

    const definePath = useCallback(() => {
        const lastIndex = filePath.lastIndexOf('.')
        const newPath = lastIndex > 0 ? filePath.slice(0, lastIndex + 1) : filePath + '.'
        return newPath + fileType
    }, [filePath, fileType])

    const getUploadPreSignedUrl = useCallback(async () => {
        setPageLoading(true)
        try {
            const path = definePath()
            const params = { path: path, action: s3Actions.putObject }
            return await api.getPreSignedURL(params, idToken)
        } catch (error) {
            console.log(error)
            setPageLoading(false)
            toast.error(error.message)
        }
    }, [setPageLoading, idToken, definePath])

    const uploadQualityCertificate = useCallback(
        async (signedUrl) => {
            setPageLoading(true)
            try {
                let reader = new FileReader()
                reader.readAsArrayBuffer(certificateFile)
                const path = definePath()
                reader.onload = async (e) => {
                    await apiS3.uploadFile(signedUrl, e.target.result)
                    const params = {
                        dscVersion: dscVersion,
                        idCertificate: idCertificate,
                        path: path,
                    }
                    const certificates = await api.updateQualityCertificate(params, idToken)
                    if (certificates) {
                        setCertificateData(certificates)
                        setPageLoading(false)
                        toast.success(t('Successfull Upload') + '!')
                        init()
                    }
                }
            } catch (error) {
                console.log(error)
                setPageLoading(false)
                toast.error(error.message)
            }
        },
        [setPageLoading, certificateFile, dscVersion, idCertificate, idToken, definePath, t, init],
    )

    const createMaterial = useCallback(async () => {
        try {
            setPageLoading(true)
            setIsSaving(true)
            if (loadTitle && endUser && !_.isEmpty(uploadData)) {
                const params = {
                    idClient: Number(endUser),
                    changeOwner: currentUser.firstName,
                    loadTitle: loadTitle,
                    data: uploadData,
                    odNominalUnit: odNominal,
                    wtNominalUnit: wtNominal,
                    makeupLossUnit: mkloss,
                    isExternalRequest: false,
                }
                const response = await api.createMaterial(params, idToken)
                if (response) {
                    setIsSaving(false)
                    setPageLoading(false)
                    toast.success(t('File upload successfully') + '!')
                    init()
                }
            } else {
                setCanSave(false)
                toast.warning('You can not save! Before saving, fill all fields and upload file.')
                setPageLoading(false)
            }
        } catch (error) {
            if (error.isUploadError) {
                setSchemaErrors(error.messageError)
                toast.error(t('Upload Error Message'))
            } else {
                toast.error(error.message)
            }
            setEnable(false)
            setPageLoading(false)
            console.log(error)
            setTimeout(() => setEnable(true), 2000)
        }
    }, [setPageLoading, loadTitle, endUser, uploadData, currentUser, odNominal, wtNominal, mkloss, idToken, t, init])

    const createProduct = useCallback(async () => {
        try {
            setPageLoading(true)
            setIsSaving(true)

            if (loadTitle && plant && endUser && !_.isEmpty(uploadData)) {
                const params = {
                    idClient: endUser,
                    changeOwner: currentUser.firstName,
                    idPlant: plant,
                    lengthRealUnit: lengthUnit,
                    weightRealUnit: weightUnit,
                    loadTitle: loadTitle,
                    data: uploadData,
                    isExternalRequest: false,
                }

                const response = await api.createProduct(params, idToken)
                if (response) {
                    setIsSaving(false)
                    setPageLoading(false)
                    toast.success(t('Successfull Upload') + '!')
                    init()
                }
            } else {
                setCanSave(false)
                toast.warning('You can not save! Before saving, fill all fields and upload file.')
                setPageLoading(false)
            }
        } catch (error) {
            if (error.isUploadError) {
                setSchemaErrors(error.messageError)
                toast.error(t('Upload Error Message'))
            } else {
                let message = error?.message
                if(message?.toUpperCase()?.includes('Missing Erp References'.toUpperCase())) {
                    const missingErpReferences = message?.split(':')[1]
                    message = t('missingErpReferences', { erpReferences: missingErpReferences })
                }

                if(message?.toUpperCase()?.includes('ValidError'.toUpperCase())){
                    const quantities = message?.split(':')[1]?.split(',')
                    const requested = quantities?.[0].split('-')[1]
                    const available = quantities?.[1].split('-')[1]
                    message = t('validError', { requested: requested, available:  available})
                }
                toast.error(message)
            }
            setEnable(false)
            setPageLoading(false)
            console.log(error)
            setTimeout(() => setEnable(true), 2000)
        }
    }, [setPageLoading, loadTitle, plant, endUser, uploadData, currentUser, lengthUnit, weightUnit, idToken, t, init])

    return (
        <UploadContext.Provider
            value={{
                actualFile,
                setActualFile,
                canSave,
                setCanSave,
                certificateFile,
                setCertificateFile,
                certificateData,
                setCertificateData,
                createMaterial,
                createProduct,
                dscVersion,
                setDscVersion,
                endUser,
                setEndUser,
                enable,
                setEnable,
                filePath,
                setFilePath,
                getUploadPreSignedUrl,
                idCertificate,
                setIdCertificate,
                isSaving,
                setIsSaving,
                isUploading,
                setIsUploading,
                loadTitle,
                setLoadTitle,
                nominalFile,
                setNominalFile,
                packingListFile,
                setPackingListFile,
                plant,
                setPlant,
                schemaErrors,
                setSchemaErrors,
                toggleStatus,
                setToggleStatus,
                uploadQualityCertificate,
                uploadData,
                setUploadData,
                fileType,
                setFileType,
                certificateName,
                setCertificateName,
            }}
        >
            {children}
        </UploadContext.Provider>
    )
}
