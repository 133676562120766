import { Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'
import { useWindowSize } from 'hooks/useWindowSize'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { REFERENCE_SCREEN_SIZE } from 'utils/constants'

const CardWrap = styled(Card)`
    flex-wrap: wrap;
    margin: 10px 20px 10px 0px;
    padding: 0.3rem 1rem;
`

const TableCard = ({ columns, tableData, title = 'Pipe Data', customDeadSpace }) => {
    const { t } = useTranslation()
    const { height } = useWindowSize()
    const deadSpace = customDeadSpace ? customDeadSpace : height > REFERENCE_SCREEN_SIZE ? 800 : 620
    const filteredColumns = _.filter(columns, (column) => column.show === true)

    return (
        <>
            <CardWrap alignSelf="stretch" justifyContent="flex-start" alignItems="center" height="100%">
                <font size="5">
                    {' '}
                    <b> {t(title)} </b>
                </font>
                <FilterableTable columns={filteredColumns} data={tableData ? tableData : [] } deadSpace={deadSpace} />
            </CardWrap>
        </>
    )
}

export default TableCard
