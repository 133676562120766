import { Button, FlexView } from 'components/common'
import { Select } from 'components/form'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormContext } from 'stores/FormStore'
import styled from 'styled-components'

const FlexSelect = styled(FlexView)`
    padding: 1px 8px 0px;
    flex-wrap: wrap;
    min-width: 1rem;
    max-width: 13rem;
    margin: 0px 10px 0px 10px;
    /* align-self: stretch; */
`
const FlexButton = styled(FlexView)`
    padding: 1px 1px 0px;
    flex-wrap: wrap;
    min-width: 1rem;
    max-width: 13rem;
    margin: 0px 0px 0px 15px;
    /* align-self: center; */
`

const FormButton = styled(Button)`
    margin: 20px 10px 5px 0px;
    max-width: 13rem;
    width: 13rem;
    flex-wrap: wrap;
    display: flex;
    align-self: stretch;
    color: white;
`

const FieldsNominalForm = () => {
    const { t } = useTranslation()

    const {
        endUserOptions,
        endUser,
        setEndUser,
        clearNominalFilters,
        setIsToSearchNominal,
        erpRef,
        setErpRef,
        setFilteredErpRefs,
        filteredErpRefs,
        filteredEndUsers,
        setFilteredEndUsers,
        nominalFilters,
    } = useContext(FormContext)

    const canSearch = erpRef || endUser ? false : true

    const formatEndUsers = useCallback(
        (data, prop) => {
            return data.map((item) => ({
                value: item[prop] ? item[prop] : '',
                label: item[prop] ? endUserOptions.find((endUser) => endUser.value === item[prop]).label : '<Empty>',
            }))
        },
        [endUserOptions],
    )

    const formatRefs = useCallback((data, prop) => {
        return data.map((item) => ({
            value: item[prop] ? item[prop] : '',
            label: item[prop] ? item[prop] : '<Empty>',
        }))
    }, [])

    const onSetErpRef = (erpRef) => {
        if (!endUser) setEndUser(null)
        setErpRef(erpRef)
        const filteredEndUsers = formatEndUsers(
            nominalFilters.filter((nominal) => nominal.erp_ref === erpRef),
            'id_client',
        )
        setFilteredEndUsers(filteredEndUsers)
    }

    const onSetEndUser = (endUser) => {
        if (!erpRef) setErpRef(null)
        setEndUser(endUser)
        const filteredErpRefs = formatRefs(
            nominalFilters.filter((nominal) => nominal.id_client === endUser),
            'erp_ref',
        )
        setFilteredErpRefs(filteredErpRefs)
    }

    return (
        <>
            <FlexSelect margin-left="0px">
                <Select
                    placeholder={t('Select Erp Reference')}
                    label={t('Erp Reference')}
                    options={filteredErpRefs}
                    value={erpRef}
                    onChange={(erpRef) => onSetErpRef(erpRef)}
                    searchable={true}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexSelect>
            <FlexSelect>
                <Select
                    placeholder={t('Select End User')}
                    label={t('End User')}
                    options={filteredEndUsers}
                    value={endUser}
                    onChange={(endUser) => {
                        onSetEndUser(endUser)
                    }}
                    searchable={true}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexSelect>
            <FlexButton alignSelf="center" justifyContent="center">
                <FormButton
                    backgroundColor="error"
                    maxWidth="13rem"
                    width="13rem"
                    onClick={() => clearNominalFilters()}
                    disabled={canSearch}
                >
                    {t('CLEAR')}
                </FormButton>
            </FlexButton>
            <FlexButton alignSelf="center" justifyContent="center">
                <FormButton backgroundColor="primary" onClick={() => setIsToSearchNominal(true)} disabled={canSearch}>
                    {t('SEARCH')}
                </FormButton>
            </FlexButton>
        </>
    )
}

export default FieldsNominalForm
