import api from 'api/api'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { exportAsExcelFile } from 'utils/excel'
import { exportAsTablePdfFile } from 'utils/pdf'

import { AppContext } from './AppStore'
import { UserContext } from './UserStore'

const initialState = {}

export const ExportContext = React.createContext(initialState)

export const ExportProvider = ({ children }) => {
    const { t } = useTranslation()
    const { setPageLoading } = useContext(AppContext)
    const { idToken } = useContext(UserContext)

    const downloadCertificate = useCallback(
        (path) =>
            new Promise((resolve, reject) => {
                if (path === null || path === undefined) {
                    throw new Error('The certificate is not available')
                }
                setPageLoading(true)
                const params = { path: path }
                api.getQualityCertificate(params, idToken)
                    .then((result) => {
                        const link = document.createElement('a')
                        link.href = result.url
                        link.target = '_blank'
                        link.click()
                    })
                    .catch((e) => {
                        reject(e)
                    })
                    .finally(() => setPageLoading(false))
            }),
        [idToken, setPageLoading],
    )

    const onDownloadClick = async (path) => {
        try {
            await downloadCertificate(path)
        } catch (e) {
            toast.error(e.data || e.message)
        }
    }

    const exportToPdf = useCallback(
        (columnsConfig, data, title) => {
            setPageLoading(true)
            const header = {
                title: `${t(title)} ${moment().format('YYYY-MM-DD HH-mm-ss')}`,
                subTitle: ' ',
                logo: 'Smart',
            }
            const headers = _.map(columnsConfig, 'Header')
            const body = _.map(data, (row) =>
                _.reduce(
                    columnsConfig,
                    (acc, { accessor, formatter }) => {
                        const value = _.get(row, accessor)
                        return [...acc, value ? (formatter ? formatter(value) : value) : '-']
                    },
                    [],
                ),
            )
            const reportData = [headers, ...body]
            const options = {
                pageOrientation: 'landscape',
                pageSize: 'A3',
                watermark: false,
                zebraStyle: true,
            }
            exportAsTablePdfFile(header, reportData, options)
            setTimeout(() => {
                setPageLoading(false)
            }, 500)
        },
        [t, setPageLoading],
    )

    const onPdfExportClick = useCallback(
        (columns, data, title) => {
            exportToPdf(
                columns.filter((p) => p.show === true),
                data,
                title,
            )
        },
        [exportToPdf],
    )

    const exportToExcel = useCallback(
        (columnsConfig, data, title) => {
            setPageLoading(true)
            const excelData = _.map(data, (row) =>
                _.reduce(
                    columnsConfig,
                    (acc, { accessor, Header, formatter }) => {
                        const value = _.get(row, accessor)
                        return {
                            ...acc,
                            [Header]: formatter ? formatter(value) : value,
                        }
                    },
                    {},
                ),
            )
            exportAsExcelFile(excelData, `${t(title)} ${moment().format('YYYY-MM-DD HH-mm-ss')}`)
            setTimeout(() => {
                setPageLoading(false)
            }, 500)
        },
        [t, setPageLoading],
    )

    const onExcelExportClick = (columns, data, title) => {
        exportToExcel(
            columns.filter((p) => p.show === true),
            data,
            title,
        )
    }

    return (
        <ExportContext.Provider
            value={{
                downloadCertificate,
                exportToPdf,
                exportToExcel,
                onExcelExportClick,
                onPdfExportClick,
                onDownloadClick,
            }}
        >
            {children}
        </ExportContext.Provider>
    )
}
