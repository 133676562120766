import { FlexView } from 'components/common'
import { Toggle } from 'components/form'
import React, { useContext } from 'react'
import { AppContext } from 'stores/AppStore'

const YesNoSelect = (props) => {
    const { generateValid, setGenerateValid } = useContext(AppContext)

    return (
        <FlexView flexDirection="row" alignItems="center" margin="0px 16px" {...props}>
            <Toggle
                state={generateValid}
                textOff="No"
                textOn="Yes"
                onChange={(e) => setGenerateValid(e)}
                alternate
                margin="0px 0px 0px 8px"
            />
        </FlexView>
    )
}

export default YesNoSelect
