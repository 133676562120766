import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const initialState = {
    name: process.env.REACT_APP_NAME,
    subtitle: '',
    version: process.env.REACT_APP_VERSION,
}

const nullValue = (value) => value === null || value === undefined || value === ''

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {
    const [redirectUrl, setRedirectUrl] = useState('')
    const [pageTitle, setPageTitle] = useState(initialState.name)
    const [pageSubtitle, setPageSubtitle] = useState(initialState.subtitle)
    const [isHeaderVisible, setHeaderVisibility] = useState(true)
    const [lengthUnit, setLengthUnit] = useState('mm')
    const [weightUnit, setWeightUnit] = useState('kg')
    const [odNominal, setOdNominal] = useState('in')
    const [wtNominal, setWtNominal] = useState('in')
    const [mkloss, setMkloss] = useState('mm')
    const [generateValid, setGenerateValid] = useState(false)
    const { i18n } = useTranslation()
    const [pageLoading, setPageLoading] = useState(false)
    const { t } = useTranslation()

    moment.locale(i18n.language)

    const options = [
        {
            title: t('Upload Data').toUpperCase(),
            key: 'UPLOAD_DATA',
            route: '/upload',
            baseUrl: 'upload',
            endpoints: ['nominal-data', 'actual-data', 'certificates'],
        },
        {
            title: t('Data Validation').toUpperCase(),
            key: 'DATA_VALIDATION',
            route: '/validation',
            baseUrl: 'validation',
            endpoints: ['details'],
        },
        {
            title: t('View Data').toUpperCase(),
            key: 'VIEW_DATA',
            route: '/view',
            baseUrl: 'view',
            endpoints: ['nominal-data', 'actual-data'],
        },
        {
            title: t('Management').toUpperCase(),
            key: 'MANAGEMENT',
            route: '/management',
            baseUrl: 'management',
            endpoints: ['end-users'],
        },
    ]

    const formatNumberByCulture = useCallback(
        (value, digits) => {
            return new Intl.NumberFormat(i18n.language, {
                minimumFractionDigits: digits,
            }).format(value)
        },
        [i18n.language],
    )

    const convertToCurrentLengthUnit = useCallback(
        (value_m, value_ft, unit) => {
            const nullMetricValue = nullValue(value_m)
            const nullImperialValue = nullValue(value_ft)
            const selectedValueNull =
                (nullMetricValue && lengthUnit === 'm') || (nullImperialValue && lengthUnit === 'ft')
            if (nullMetricValue && nullImperialValue) {
                return 0
            }

            if (selectedValueNull) {
                return 0
            }

            if (unit === 'm') {
                return formatNumberByCulture(value_m, 3)
            }

            if (lengthUnit === 'ft') {
                return formatNumberByCulture(value_ft, 3)
            }
            return value_m
        },
        [lengthUnit, formatNumberByCulture],
    )

    const convertToCurrentWeightUnit = useCallback(
        (value, unit) => {
            if (value === null || value === undefined || value === '') {
                return 0
            }
            if (unit === weightUnit) {
                return formatNumberByCulture(value, 3)
            } else if (weightUnit === 'lb') {
                return formatNumberByCulture(value * 2.20462, 3)
            }
            return formatNumberByCulture(value / 2.20462, 3)
        },
        [weightUnit, formatNumberByCulture],
    )

    return (
        <AppContext.Provider
            value={{
                ...initialState,
                redirectUrl,
                pageTitle,
                pageSubtitle,
                isHeaderVisible,
                lengthUnit,
                weightUnit,
                odNominal,
                wtNominal,
                mkloss,
                generateValid,
                convertToCurrentLengthUnit,
                convertToCurrentWeightUnit,
                setLengthUnit,
                setWeightUnit,
                setOdNominal,
                setWtNominal,
                setMkloss,
                setRedirectUrl,
                setPageTitle,
                setPageSubtitle,
                setHeaderVisibility,
                formatNumberByCulture,
                setGenerateValid,
                pageLoading,
                setPageLoading,
                options,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
