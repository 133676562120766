import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router'

import { UserContext } from '../../stores/UserStore'

const PrivateRoute = ({ children, ...rest }) => {
    const { authState } = useContext(UserContext)
    const isAuthenticated = authState === 'SIGNED_IN'
    const isRefreshToken = authState === 'REFRESH_TOKEN'
    const isAccessDenied = authState === 'ACCESS_DENIED'
    const isWaitingAccess = authState === 'WAITING_FOR_ACCESS'
    const isReload = authState === 'RELOAD'

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : isReload ? (
                    <Redirect to={{ pathname: '/redirect/reload', state: { from: location } }} />
                ) : isRefreshToken ? (
                    <Redirect to={{ pathname: '/redirect/refresh', state: { from: location } }} />
                ) : isAccessDenied ? (
                    <Redirect to={{ pathname: '/redirect/request', state: { from: location } }} />
                ) : isWaitingAccess ? (
                    <Redirect
                        to={{
                            pathname: '/redirect/waiting-access',
                            state: { from: location },
                        }}
                    />
                ) : (
                    <Redirect to={{ pathname: '/redirect/logout', state: { from: location } }} />
                )
            }
        />
    )
}

export default PrivateRoute
