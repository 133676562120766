import moment from 'moment'
import { decompressResponse } from 'utils/decompress'

const formatLoggerString = (response) => `
    URL: ${response.config.baseURL}${response.config.url}
    Date: ${moment().format('LLLL')}
`

const loggerInterceptor = (response, shouldDecompress) => {
    const body = response.body ? response.body : response
    const responseToLog = shouldDecompress ? decompressResponse(body.data) : body.data
    console.log(formatLoggerString(response))
    console.log(responseToLog)
    return response
}

export const addLogger = (instance, shouldDecompress) => {
    if (process.env.REACT_APP_DOMAIN === 'localhost') {
        instance.interceptors.response.use((response) => loggerInterceptor(response, shouldDecompress))
    }
}
