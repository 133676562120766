import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import styled from 'styled-components'

import { FlexView } from '../../common'

const Wrapper = styled(FlexView)`
    padding: 8px;
    margin: -8px 0px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.offWhite};
    }
`

const Dropdown = styled.div`
    display: flex;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;

    svg {
        height: 10px;
        fill: ${({ theme }) => theme.colors.gray};
    }
`

const Options = styled.div`
    font-family: 'Roboto';
    display: flex;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    transform: ${({ popperTransform, open }) =>
        `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
    flex-direction: column;
    margin: 8px 0px 30px;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadows.high};
    overflow-y: auto;
    border-radius: 8px;
    z-index: 999;
    transition: all 0.2s ease;

    /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

    /* total width */
    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }
`

const Option = styled.div`
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Roboto';
    user-select: none;
    min-width: 150px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray};
    transition: background-color 0.2s ease;
    cursor: pointer;
    ${({ theme, divider }) => divider && `border-top: 1px solid ${theme.colors.lightGray}`};

    &:hover {
        background-color: ${({ theme }) => theme.colors.offWhite};
    }
`

const UserOptions = ({ username, options }) => {
    const node = useRef(null)
    const [isOpen, setDropdown] = useState(false)

    const toggleSelect = () => setDropdown((isOpen) => !isOpen)

    const onOptionClick = (onClick) => () => {
        toggleSelect()
        onClick()
    }

    const renderOptions = _.map(options, ({ key, label, onClick, divider }) => (
        <Option key={key} onClick={onOptionClick(onClick)} divider={divider}>
            {label}
        </Option>
    ))

    const handleOutsideClick = (e) => {
        node && node.current && !node.current.contains(e.target) && setDropdown(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    return (
        <Manager>
            <FlexView alignItems="stretch" position="relative" ref={node}>
                <Reference>
                    {({ ref }) => (
                        <Wrapper
                            ref={ref}
                            onClick={toggleSelect}
                            flexDirection="row"
                            alignItems="center"
                            fontSize="medium"
                            margin="0px 12px 0px"
                            color="gray"
                            style={{ cursor: 'pointer' }}
                        >
                            {username}
                            <Dropdown>
                                <svg
                                    width="21"
                                    height="12"
                                    viewBox="0 0 21 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.5 2H2L10.5 10L19 2H10.5Z"
                                        fill="#DDD"
                                        stroke="#DDD"
                                        strokeWidth="3"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Dropdown>
                        </Wrapper>
                    )}
                </Reference>
                <Popper placement="bottom">
                    {({ ref, style, placement }) => (
                        <Options
                            ref={ref}
                            style={style}
                            data-placement={placement}
                            open={isOpen}
                            popperTransform={style.transform}
                        >
                            {renderOptions}
                        </Options>
                    )}
                </Popper>
            </FlexView>
        </Manager>
    )
}

export default UserOptions
