import React from 'react'

import { FlexView } from '../../common'

const DateDisplay = ({ date, label }) => {
    const day = date ? date.format('DD') : '-'
    const monthAndYear = date ? date.format('MMMM YYYY') : ''
    const dayOfWeek = date ? date.format('dddd') : ''

    return (
        <FlexView fontSize="small" color="metalic">
            <span style={{ textTransform: 'uppercase' }}>{label}</span>
            <FlexView flexDirection="row" alignItems="center">
                <FlexView fontSize="40px" color="darkGray">
                    {day}
                </FlexView>
                <FlexView flexDirection="column" justifyContent="space-between" margin="0px 8px">
                    <FlexView color="darkGray" fontWeight="bold">
                        {monthAndYear}
                    </FlexView>
                    <FlexView fontSize="small" color="metalic">
                        {dayOfWeek}
                    </FlexView>
                </FlexView>
            </FlexView>
        </FlexView>
    )
}

export default DateDisplay
