import api from 'api/api'
import _, { range } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AppContext } from './AppStore'
import { PipeContext } from './PipeStore'
import { UserContext } from './UserStore'

const initialState = {
    nominalData: [],
    actualData: [],
    originalActualData: [],
    originalNominalData: [],
    loads: [],
    index: null,
    erpRefOptions: [],
    erpRef: null,
    numSalesOrderOptions: [],
    numSalesOrder: null,
    numSalesItemOptions: [],
    numSalesItem: null,
    coPurchaseOrderOptions: [],
    coPurchaseOrder: null,
    coPurchaseItemOptions: [],
    coPurchaseItem: null,
    endUser: null,
    plant: null,
    valid: '',
    pipeNo: '',
    isToSearchActual: false,
    isNominalView: true,
    endUserOptions: [],
    suppliersOptions: [],
}

export const FormContext = React.createContext(initialState)

export const FormProvider = ({ children }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { setActualData, setOriginalActualData, setNominalData, setOriginalNominalData } = useContext(PipeContext)
    const { setPageLoading } = useContext(AppContext)
    const { idToken } = useContext(UserContext)

    const [erpRefOptions, setErpRefOptions] = useState([])
    const [filteredErpRefs, setFilteredErpRefs] = useState([])
    const [erpRef, setErpRef] = useState(null)
    const [numSalesOrderOptions, setNumSalesOrderOptions] = useState([])
    const [numSalesOrder, setNumSalesOrder] = useState(null)
    const [numSalesItemOptions, setNumSalesItemOptions] = useState([])
    const [numSalesItem, setNumSalesItem] = useState(null)
    const [coPurchaseOrderOptions, setCoPurchaseOrderOptions] = useState([])
    const [coPurchaseOrder, setCoPurchaseOrder] = useState(null)
    const [coPurchaseItemOptions, setCoPurchaseItemOptions] = useState([])
    const [coPurchaseItem, setCoPurchaseItem] = useState(null)
    const [endUserOptions, setEndUserOptions] = useState([])
    const [filteredEndUsers, setFilteredEndUsers] = useState([])
    const [nominalFilters, setNominalFilters] = useState([])

    const [actualFilters, setActualFilters] = useState([])

    const [endUser, setEndUser] = useState(null)
    const [plant, setPlant] = useState(null)
    const [valid, setValid] = useState('')
    const [pipeNo, setPipeNo] = useState('')
    const [isToSearchActual, setIsToSearchActual] = useState(false)
    const [isToSearchNominal, setIsToSearchNominal] = useState(false)

    const [shouldGetNominalFilters, setShouldGetNominalFilters] = useState(true)
    const [shouldGetActualFilters, setShouldGetActualFilters] = useState(true)
    const [suppliersOptions, setSuppliersOptions] = useState([])

    const insertEndUser = useCallback(
        (data) =>
            data.map((item) => ({
                ...item,
                cod_client: endUserOptions.find((p) => Number(p.value) === Number(item.id_client)).label,
            })),
        [endUserOptions],
    )

    const initState = () => {
        setValid('')
        setPipeNo('')
        setNumSalesOrder(null)
        setNumSalesItem(null)
        setCoPurchaseOrder(null)
        setCoPurchaseItem(null)
        setErpRef(null)
        setPlant(null)
        setEndUser(null)
        setNumSalesOrderOptions([])
        setNumSalesItemOptions([])
        setCoPurchaseOrderOptions([])
        setCoPurchaseItemOptions([])
        setErpRefOptions([])
        setActualData([])
        setOriginalActualData([])
        setNominalData([])
        setOriginalNominalData([])
        setShouldGetNominalFilters(true)
        setShouldGetActualFilters(true)
    }

    history.listen((location, action) => {
        initState()
    })

    const MAX_DATA_LENGTH = 2000

    const handleResult = useCallback(
        (data) => {
            if (data) {
                if (data.length === 0) {
                    toast.warn(t('Not Found'))
                    setActualData([])
                    setOriginalActualData([])
                } else {
                    setActualData(data)
                    setOriginalActualData(data)
                }
            } else {
                toast.error(t("hasn't data"))
                setActualData([])
                setOriginalActualData([])
            }
            setPageLoading(false)
        },
        [setActualData, setOriginalActualData, setPageLoading, t],
    )

    const getActualDataFromFiltersChuncks = (timesToSearch, params, idToken) =>
        range(timesToSearch).map((execNo) =>
            api.getPipeDataFromFilters(
                {
                    filters: params,
                    offset: execNo * MAX_DATA_LENGTH,
                    limit: MAX_DATA_LENGTH,
                },
                idToken,
            ),
        )

    const executeManyCallsToGetDataFromFilters = useCallback(
        async (params, dataCount) => {
            const timesToSearch = Math.ceil(dataCount / MAX_DATA_LENGTH)
            const promisses = getActualDataFromFiltersChuncks(timesToSearch, params, idToken)
            const data = []

            const results = await Promise.all(promisses)

            if (results) {
                results.forEach((item) => {
                    data.push(...item)
                })
                handleResult(data)
            }
        },
        [idToken, handleResult],
    )

    useEffect(() => {
        async function fetchData() {
            try {
                if (isToSearchActual) {
                    setPageLoading(true)
                    setIsToSearchActual(false)
                    const params = {
                        val_id: valid ? valid : null,
                        pipe_no: pipeNo ? pipeNo : null,
                        num_sales_order: numSalesOrder,
                        num_sales_item: numSalesItem,
                        co_purchase_order: coPurchaseOrder,
                        co_purchase_item: coPurchaseItem,
                        erp_ref: erpRef,
                        id_plant: plant,
                        end_user: endUser,
                    }

                    const dataCount = await api.getActualDataCountFromFilters(params, idToken)

                    if (dataCount) {
                        setPageLoading(true)
                        setActualData([])
                        setOriginalActualData([])
                        executeManyCallsToGetDataFromFilters(params, dataCount)
                    }
                }
            } catch (error) {
                setPageLoading(false)
                setIsToSearchActual(false)
                toast.error(error.message)
            }
        }
        fetchData()
    }, [
        setOriginalActualData,
        setActualData,
        valid,
        pipeNo,
        numSalesOrder,
        numSalesItem,
        coPurchaseOrder,
        coPurchaseItem,
        erpRef,
        plant,
        endUser,
        setPageLoading,
        idToken,
        isToSearchActual,
        t,
        executeManyCallsToGetDataFromFilters,
    ])

    useEffect(() => {
        async function fetchData() {
            try {
                if (isToSearchNominal) {
                    setPageLoading(true)
                    const params = { erpRef: erpRef, endUser: endUser }
                    const result = await api.getAllMaterialsFromFilters(params, idToken)
                    if (!_.isEmpty(result)) {
                        const completeEndUser = insertEndUser(result)
                        setNominalData(completeEndUser)
                        setOriginalNominalData(completeEndUser)
                    } else {
                        setNominalData([])
                        setOriginalNominalData([])
                        toast.warn(t('Not Found'))
                    }
                    setPageLoading(false)
                    setIsToSearchNominal(false)
                }
            } catch (error) {
                setPageLoading(false)
                setIsToSearchNominal(false)
                toast.error(error.message)
            }
        }
        fetchData()
    }, [
        endUser,
        erpRef,
        idToken,
        insertEndUser,
        isToSearchNominal,
        plant,
        setNominalData,
        setOriginalNominalData,
        setPageLoading,
        t,
    ])

    const formatValues = useCallback(
        (data, prop) => {
            const uniqData = _.uniqBy(data, prop)
            return _.orderBy(
                uniqData.map((item) => ({
                    value: item[prop] ? item[prop] : '',
                    label: item[prop] ? item[prop] : '<' + t('Empty') + '>',
                })),
                'label',
            )
        },
        [t],
    )

    const clearActualFilters = () => {
        setValid('')
        setPipeNo('')
        setNumSalesOrder(null)
        setNumSalesItem(null)
        setCoPurchaseOrder(null)
        setCoPurchaseItem(null)
        setErpRef(null)
        setPlant(null)
        setEndUser(null)
        setActualData([])
        setOriginalActualData([])
        setNumSalesOrderOptions(formatValues(actualFilters, 'num_sales_order'))
        setNumSalesItemOptions(formatValues(actualFilters, 'num_sales_item'))
        setCoPurchaseItemOptions(formatValues(actualFilters, 'co_purchase_item'))
        setCoPurchaseOrderOptions(formatValues(actualFilters, 'co_purchase_order'))
        setErpRefOptions(formatValues(actualFilters, 'erp_ref'))
    }

    useEffect(() => {
        async function fetchData() {
            try {
                if (history.location.pathname.includes('/view/actual-data') && shouldGetActualFilters) {
                    setPageLoading(true)
                    const result = await api.getPipeDataFilters({}, idToken)
                    setShouldGetActualFilters(false)
                    if (result) {
                        setActualFilters(result)
                        setNumSalesOrderOptions(formatValues(result, 'num_sales_order'))
                        setNumSalesItemOptions(formatValues(result, 'num_sales_item'))
                        setCoPurchaseItemOptions(formatValues(result, 'co_purchase_item'))
                        setCoPurchaseOrderOptions(formatValues(result, 'co_purchase_order'))
                        setErpRefOptions(formatValues(result, 'erp_ref'))
                    }
                    setPageLoading(false)
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        }
        fetchData()
    }, [
        coPurchaseItemOptions,
        coPurchaseOrderOptions,
        erpRefOptions,
        formatValues,
        history,
        idToken,
        numSalesItemOptions,
        numSalesOrderOptions,
        setPageLoading,
        shouldGetActualFilters,
    ])

    useEffect(() => {
        async function fetchData() {
            try {
                if (history.location.pathname.includes('/view/nominal-data') && shouldGetNominalFilters) {
                    setPageLoading(true)
                    const result = await api.getValidatedErpRefs({}, idToken)
                    setShouldGetNominalFilters(false)
                    if (result) {
                        setNominalFilters(result)
                        setFilteredErpRefs(formatValues(result, 'erp_ref'))
                        setPageLoading(false)
                    }
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        }
        fetchData()
    }, [erpRefOptions, formatValues, history, idToken, setPageLoading, shouldGetNominalFilters])

    const formatEndUserValues = (data) => {
        return data.map((item) => ({
            value: item ? item.id_client : '',
            label: item ? item.cod_client : '<' + t('Empty') + '>',
        }))
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setPageLoading(true)
                const result = await api.getAvailableEndUsers({}, idToken)
                if (result) {
                    setEndUserOptions(formatEndUserValues(result.end_users))
                    setFilteredEndUsers(formatEndUserValues(result.end_users))
                    setPageLoading(false)
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        }
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clearNominalFilters = useCallback(() => {
        setErpRef(null)
        setPlant(null)
        setEndUser(null)
        setNominalData([])
        setOriginalNominalData([])
        setFilteredEndUsers(endUserOptions)
        setFilteredErpRefs(formatValues(nominalFilters, 'erp_ref'))
    }, [endUserOptions, formatValues, nominalFilters, setNominalData, setOriginalNominalData])

    return (
        <FormContext.Provider
            value={{
                erpRefOptions,
                setErpRefOptions,
                erpRef,
                setErpRef,
                numSalesOrderOptions,
                setNumSalesOrderOptions,
                numSalesOrder,
                setNumSalesOrder,
                numSalesItemOptions,
                setNumSalesItemOptions,
                numSalesItem,
                setNumSalesItem,
                coPurchaseOrderOptions,
                setCoPurchaseOrderOptions,
                coPurchaseOrder,
                setCoPurchaseOrder,
                coPurchaseItemOptions,
                setCoPurchaseItemOptions,
                coPurchaseItem,
                setCoPurchaseItem,
                endUser,
                setEndUser,
                plant,
                setPlant,
                valid,
                setValid,
                pipeNo,
                setPipeNo,
                clearActualFilters,
                clearNominalFilters,
                isToSearchActual,
                setIsToSearchActual,
                isToSearchNominal,
                setIsToSearchNominal,
                endUserOptions,
                setEndUserOptions,
                suppliersOptions,
                setSuppliersOptions,
                filteredErpRefs,
                setFilteredErpRefs,
                filteredEndUsers,
                setFilteredEndUsers,
                nominalFilters,
                actualFilters,
            }}
        >
            {children}
        </FormContext.Provider>
    )
}
