import api from 'api/api'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'
import { useWindowSize } from 'hooks/useWindowSize'
import _, { range } from 'lodash'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { PipeContext } from 'stores/PipeStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { LOAD_DATA_TYPE, loadStatusColor, loadStatusIcon, REFERENCE_SCREEN_SIZE } from 'utils/constants'

const BackButton = styled(Button)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: none;
    box-shadow: none;

    color: gray;
    font-weight: bold;
    margin: 0px;

    &:active {
        opacity: 0.4;
    }
`
const IconBack = styled(Icon)`
    width: 24px;
    height: 24px;
    margin: 0px 16px 0px 0px;
`
const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const formatLoads = (loads) => _.orderBy(loads, 'load_date', 'desc')
const getDeadSpace = (height) => (height > REFERENCE_SCREEN_SIZE ? 550 : 450)

const Loads = () => {
    const { height } = useWindowSize(0)
    const history = useHistory()
    const { t } = useTranslation()

    const { idToken } = useContext(UserContext)
    const { setPageSubtitle, setPageLoading, pageLoading } = useContext(AppContext)
    const { setLoads, loads, setStatus, setIdLoadData, setIdLoad } = useContext(PipeContext)

    const [deadSpace, setDeadSpace] = useState(0)

    const MAX_DATA_LENGTH = 5000

    useEffect(() => {
        setDeadSpace(getDeadSpace(height))
    }, [height])

    useEffect(() => {
        setPageSubtitle(' - ' + t('Data Validation'))
    }, [setPageSubtitle, t])

    const redirect = useCallback(
        (path) => {
            history.push(path)
        },
        [history],
    )

    const getDataFromLoadChunksFunction = (numberOfExecs, idToken) =>
        range(numberOfExecs).map((execNo) =>
            api.getDataLoadsWithLimitAndOffset({
                limit: MAX_DATA_LENGTH,
                offset: execNo * MAX_DATA_LENGTH,
            },
                idToken,
            ),
        )

    const handleResult = useCallback(
        (data) => {
            if (data) {
                if (data.length === 0) {
                    setPageLoading(false)
                    setLoads([])
                } else {
                    setLoads(formatLoads(data))
                    setPageLoading(false)
                }
            } else {
                setPageLoading(false)
                setLoads([])
            }
        },
        [setLoads, setPageLoading],
    )

    const executeManyCallsToGetLoadData = useCallback(
        async (count) => {
            const timesToSearch = Math.ceil(count / MAX_DATA_LENGTH)
            const promises = getDataFromLoadChunksFunction(timesToSearch, idToken)
            const loadData = []
            const results = await Promise.all(promises)
            if (results) {
                results.forEach((item) => {
                    loadData.push(...item)
                })
                handleResult(loadData)
            }
        },
        [handleResult, idToken],
    )

    useEffect(() => {
        async function fetchData() {
            try {
                setPageLoading(true)
                setIdLoad(null)
                setIdLoadData(null)
                setLoads([])
                const result = await api.getTotalAmountOfLoads({}, idToken)
                if (result) {
                    executeManyCallsToGetLoadData(result.count)
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        }
        fetchData()
    }, [idToken, setIdLoad, setIdLoadData, setLoads, setPageLoading, executeManyCallsToGetLoadData])

    const onButtonClick = useCallback(
        async (idLoad, status, idLoadData) => {
            setStatus(status)
            setIdLoad(idLoad)
            setIdLoadData(idLoadData)
            redirect('/validation/details')
        },
        [setStatus, setIdLoad, setIdLoadData, redirect],
    )

    const columns = useMemo(
        () => [
            {
                accessor: 'id_load',
                Header: t('Id'),
            },
            {
                accessor: ({ id_load_data }) =>
                    id_load_data === LOAD_DATA_TYPE.NOMINAL_DATA ? t('Nominal Data') : t('Actual Data'),
                Header: t('Load Type'),
            },
            {
                accessor: 'load_title',
                Header: t('Load Title'),
            },
            {
                accessor: 'load_status_description',
                Header: t('Load Status'),
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="flex-start" width="100%" flexDirection="row">
                        <font size="2"> {t(value)} </font>
                        <Icon
                            name={loadStatusIcon[row.original.id_load_status]}
                            width="16px"
                            height="16px"
                            margin="0px 4px 0px 8px"
                            color={loadStatusColor[row.original.id_load_status]}
                        />
                    </FlexView>
                ),
            },
            {
                accessor: 'cod_client',
                Header: t('End User'),
            },
            {
                accessor: 'load_owner',
                Header: t('Load Owner'),
            },
            {
                accessor: ({ load_date }) => (load_date ? moment(load_date).format('YYYY-MM-DD  HH:mm:ss') : ''),
                Header: t('Load Date'),
            },
            {
                accessor: ({ validation_date }) =>
                    validation_date ? moment(validation_date).format('YYYY-MM-DD  HH:mm:ss') : '',
                Header: t('Validation Date'),
            },
            {
                accessor: 'remarks',
                Header: t('Remarks'),
            },
            {
                accessor: 'created_amount',
                Header: t('Total Created'),
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="flex-start" width="100%" flexDirection="row">
                        <font size="2">{row.original.created_amount}</font>
                    </FlexView>
                ),
            },
            {
                accessor: 'updated_amount',
                Header: t('Total Updated'),
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="flex-start" width="100%" flexDirection="row">
                        <font size="2"> {row.original.updated_amount}</font>
                    </FlexView>
                ),
            },
            {
                id: 'details',
                Header: '',
                disableFilters: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="center" width="100%">
                        <Button
                            fontSize="12px"
                            padding="4px 8px"
                            margin="0px 0px 0px 8px"
                            color="secondary"
                            onClick={() => {
                                onButtonClick(
                                    row.original.id_load,
                                    row.original.id_load_status,
                                    row.original.id_load_data,
                                )
                            }}
                        >
                            {t('Details')}
                        </Button>
                    </FlexView>
                ),
            },
        ],
        [t, onButtonClick],
    )

    return (
        <FlexPage>
            <LoadingOverlay visible={pageLoading} />
            <BackButton flexDirection="row" onClick={() => history.push('/')} fontSize="subtitle">
                <IconBack name="arrow-left" />
                <div> {t('Go Back')} </div>
            </BackButton>
            <Card alignSelf="stretch" justifyContent="flex-start" alignItems="center" padding="0.5rem 1rem">
                <FilterableTable columns={columns} data={loads} deadSpace={deadSpace} />
            </Card>
        </FlexPage>
    )
}

export default Loads
