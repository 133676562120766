import AppsIcon from 'assets/icons/apps.svg'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import styled from 'styled-components'
import { withMargin } from 'utils/styled-decorators'

import { FlexView } from '../../common'

const Icon = styled.img`
    ${withMargin()};
    transition: opacity 0.2s ease;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
`

const Apps = styled.div`
    font-family: 'Roboto';
    display: grid;
    grid-template-columns: ${({ columnsCount }) => `repeat(${columnsCount}, 150px)`};
    margin: 8px 0px;
    max-height: 250px;
    background: #ffffff;
    overflow-y: auto;
    border-radius: 8px;
    z-index: 999;
    transition: all 0.2s ease;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    box-shadow: ${({ theme }) => theme.boxShadows.high};

    /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

    /* total width */
    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }
`

const App = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 8px;
    padding: 8px;
    border-radius: 8px;
    font-family: 'Roboto';
    user-select: none;
    text-align: center;
    width: calc(100% - 32px);
    white-space: pre-wrap;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray};
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.offWhite};
    }

    span {
        margin-top: 8px;
        font-size: ${({ theme }) => theme.colors.medium};
    }
`

const AppsPopper = React.forwardRef(({ style, scheduleUpdate, placement, isOpen, toggleSelect, apps }, ref) => {
    useEffect(() => {
        scheduleUpdate()
    }, [isOpen, scheduleUpdate])

    const tooltipStyle = {
        ...style,
        transform: `${style.transform} ${isOpen ? 'translateX(0)' : 'scale(0.9)'}`,
    }

    const onAppClick = (onClick) => () => {
        toggleSelect()
        onClick()
    }

    const renderApps = _.map(apps, ({ key, label, iconSrc, onClick }) => (
        <App key={key} onClick={onAppClick(onClick)}>
            <Icon src={iconSrc} height="32px" width="32px" alt={`${label} Icon`} />
            <span>{label}</span>
        </App>
    ))

    const columnsCount = apps.length > 3 ? 3 : apps.length

    return (
        <Apps ref={ref} style={tooltipStyle} data-placement={placement} open={isOpen} columnsCount={columnsCount}>
            {renderApps}
        </Apps>
    )
})

const AppsSelect = ({ apps }) => {
    const node = useRef(null)
    const [isOpen, setDropdown] = useState(false)

    const toggleSelect = () => setDropdown((isOpen) => !isOpen)

    const handleOutsideClick = (e) => {
        node && node.current && !node.current.contains(e.target) && setDropdown(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    return (
        <Manager>
            <FlexView alignItems="stretch" position="relative" ref={node}>
                <Reference>
                    {({ ref }) => (
                        <Icon
                            ref={ref}
                            src={AppsIcon}
                            onClick={toggleSelect}
                            height="18px"
                            margin="0px 8px 0px"
                            alt="Apps Select Icon"
                        />
                    )}
                </Reference>
                <Popper
                    placement="bottom"
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                        },
                    }}
                >
                    {({ ref, style, scheduleUpdate, placement }) => (
                        <AppsPopper
                            {...{
                                ref,
                                style,
                                scheduleUpdate,
                                placement,
                                toggleSelect,
                                apps,
                                isOpen,
                            }}
                        />
                    )}
                </Popper>
            </FlexView>
        </Manager>
    )
}

export default AppsSelect
