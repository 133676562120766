import api from 'api/api'
import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import ActualLoadDetails from 'containers/load/ActualLoadDetails'
import NominalLoadDetails from 'containers/load/NominalLoadDetails'
import PrintersModal from 'containers/modal/PrintersModal'
import ValidateModal from 'containers/modal/ValidateModal'
import { range } from 'lodash'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { PipeContext } from 'stores/PipeStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { LOAD_DATA_TYPE, LOAD_STATUS } from 'utils/constants'

const BackButton = styled(Button)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: none;
    box-shadow: none;

    color: gray;
    font-weight: bold;
    margin: 0px;

    &:active {
        opacity: 0.4;
    }
`
const IconBack = styled(Icon)`
    width: 24px;
    height: 24px;
    margin: 0px 16px 0px 0px;
`
const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const LoadDetails = () => {
    const { t } = useTranslation()

    const { idToken } = useContext(UserContext)
    const { setPageSubtitle, pageLoading, setPageLoading } = useContext(AppContext)
    const { loadDetails, nominalColumns, actualDataColumns, idLoad, 
            status, idLoadData, setLoadDetails, showPrintersCard, setShowPrintersCard } =
        useContext(PipeContext)
    const [isOpen, setIsOpen] = useState(false)
    const history = useHistory()

    const MAX_DATA_LENGTH = 2000

    const getTitle = useCallback(
        () =>
            idLoadData === LOAD_DATA_TYPE.NOMINAL_DATA
                ? '- ' + t('Nominal Load Details')
                : '- ' + t('Actual Load Details'),
        [idLoadData, t],
    )
    const getModalTitle = useCallback(
        () => (idLoadData === LOAD_DATA_TYPE.NOMINAL_DATA ? t('Validate Nominal Load') : t('Validate Actual Load')),
        [idLoadData, t],
    )

    useEffect(() => {
        setPageSubtitle(getTitle())
    }, [setPageSubtitle, t, loadDetails, getTitle])

    const onOutsideClick = (result) => {
        setIsOpen(false)
        if (result) redirect('/validation')
    }

    const onPrintersOutsideClick = () => setShowPrintersCard(false)

    const redirect = useCallback(
        (path) => {
            history.push(path)
        },
        [history],
    )

    const validateWhatDisplay = () => {
        if (idLoadData) {
            if (idLoadData === LOAD_DATA_TYPE.NOMINAL_DATA) {
                return <NominalLoadDetails columns={nominalColumns} tableData={loadDetails}></NominalLoadDetails>
            } else if (idLoadData === LOAD_DATA_TYPE.PIPE_DATA) {
                return <ActualLoadDetails columns={actualDataColumns} tableData={loadDetails}></ActualLoadDetails>
            } else return ''
        }
    }

    const handleErrors = useCallback(
        (error) => {
            toast.error(error.message)
            setPageLoading(false)
            setLoadDetails([])
            redirect('/validation')
        },
        [redirect, setLoadDetails, setPageLoading],
    )

    const handleResult = useCallback(
        (data, idLoad) => {
            if (data) {
                if (data.length === 0) {
                    toast.error(t('Load') + ' ' + idLoad + ' ' + t("hasn't data"))
                    setPageLoading(false)
                    setLoadDetails([])
                    redirect('/validation')
                } else {
                    setLoadDetails(data)
                    setPageLoading(false)
                }
            } else {
                toast.error(t('Load') + ' ' + idLoad + ' ' + t("hasn't data"))
                setPageLoading(false)
                setLoadDetails([])
                redirect('/validation')
            }
        },
        [redirect, setLoadDetails, setPageLoading, t],
    )

    const getDataFromLoadChunksFunction = (numberOfExecs, idLoad, idLoadData, idToken) =>
        range(numberOfExecs).map((execNo) =>
            api.getDataFromLoad(
                {
                    id_load: idLoad,
                    id_load_data: idLoadData,
                    limit: MAX_DATA_LENGTH,
                    offset: execNo * MAX_DATA_LENGTH,
                },
                idToken,
            ),
        )

    const executeManyCallsToGetLoadData = useCallback(
        async (idLoad, idLoadData, count) => {
            const timesToSearch = Math.ceil(count / MAX_DATA_LENGTH)
            const promises = getDataFromLoadChunksFunction(timesToSearch, idLoad, idLoadData, idToken)
            const loadData = []
            const results = await Promise.all(promises)
            if (results) {
                results.forEach((item) => {
                    loadData.push(...item)
                })
                handleResult(loadData, idLoad)
            }
        },
        [handleResult, idToken],
    )

    useLayoutEffect(() => {
        async function fetchData() {
            try {
                if (idLoad && idLoadData) {
                    setPageLoading(true)
                    setLoadDetails([])
                    let params = { id_load: idLoad, id_load_data: idLoadData }
                    let result = await api.getDataCountFromLoad(params, idToken)
                    if (result) {
                        executeManyCallsToGetLoadData(idLoad, idLoadData, result.count)
                    }
                }
            } catch (error) {
                handleErrors(error)
            }
        }
        fetchData()
    }, [executeManyCallsToGetLoadData, handleErrors, idLoad, idLoadData, idToken, setLoadDetails, setPageLoading])

    return (
        <FlexPage>
            <LoadingOverlay visible={pageLoading} />
            <FlexView flexWrap="wrap" width="calc(100% - 1rem)">
                <BackButton flexDirection="row" onClick={() => history.push('/validation')} fontSize="subtitle">
                    <IconBack name="arrow-left" />
                    <div> {t('Go Back')} </div>
                </BackButton>
                {validateWhatDisplay()}
                <FlexView
                    flexDirection="row"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    flex="1"
                    alignSelf="stretch"
                    margin="0.7rem"
                >
                    <Button
                        backgroundColor="secondary"
                        color="white"
                        margin="5px"
                        width="200px"
                        onClick={() => setIsOpen(true)}
                        disabled={status !== LOAD_STATUS.PENDING_VALIDATION}
                    >
                        {' '}
                        {t('Next')}{' '}
                    </Button>
                </FlexView>
            </FlexView>
            <ValidateModal
                isOpen={isOpen}
                title={getModalTitle()}
                idLoadData={idLoadData}
                onOutsideClick={onOutsideClick}
                label={t('REMARKS')}
                load={idLoad}
            ></ValidateModal>
            <PrintersModal isOpen={showPrintersCard} onOutsideClick={onPrintersOutsideClick} />
        </FlexPage>
    )
}

export default LoadDetails
