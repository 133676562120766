import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import Icon from 'components/common/Icon'
import GuideModal from 'containers/modal/GuideModal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoButton = styled(Button)`
    background-color: white;
`

const FormCardHeader = ({ type, title = 'Enter Information' }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const onOutside = () => {
        setIsOpen(false)
    }

    return (
        <FlexView flexDirection="row" width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px">
            <FlexView
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                fontWeight="bold"
                color="secondary"
            >
                <font size="5"> {t(title)} </font>
            </FlexView>
            <FlexView flexDirection="column" justifyContent="flex-end" alignItems="center">
                <InfoButton onClick={() => setIsOpen(true)}>
                    <Icon name="info" width="20px" height="20px" />
                </InfoButton>
            </FlexView>
            <GuideModal isOpen={isOpen} onOutsideClick={onOutside} type={type}>
                {' '}
            </GuideModal>
        </FlexView>
    )
}

export default FormCardHeader
