import * as _ from 'lodash';
import { ACTUAL_DUPLICATE_PROPERTIES, NOMINAL_DUPLICATE_PROPERTIES, UPLOAD_TYPES } from 'utils/constants';


const Ajv = require('ajv').default
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver')

const EXCEL_BLOB_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

/**
 * Validates the data for the current schema
 * @param {any[]} data To be analysed
 * @returns True or the error message
 */
const validateData = (data, validate) => {
    const isValid = validate(data)
    return isValid ? [] : validate.errors
}

/**
 * Converts the uploadded spreadsheet to worksheet
 * @param {Event} evt The upload event
 * @returns The converted worksheet to workbook
 */
const fileToWorkSheet = async (evt) => {
    const bstr = evt.target.result
    
    const wb = new ExcelJS.Workbook();
    await wb.xlsx.load(bstr, {
        ignoreNodes: [
            'dataValidations'
        ],
    });

    return wb.worksheets[0]
    
}

const sheetToJson = (worksheet) => {
    const rows = worksheet.getRows(1, worksheet.rowCount)
    let headers = []
    for(let i=1; i <= rows[0].cellCount; i++){
        headers.push(rows[0].getCell(i).value)
    }
    let data = []
    for(let i=1; i < rows.length; i++){
        let element = {}
        for(let j=1; j <= rows[i].cellCount; j++){
            if(!headers[j-1] || headers[j-1].trim() === '' || rows[i].getCell(j).value === null) 
                continue
            element[headers[j-1]] = rows[i].getCell(j).value
        }
        data.push(element)
    }
    return data
}

const getUniqueProp = (dataType) => {
    return dataType === UPLOAD_TYPES.ACTUAL_DATA ? ACTUAL_DUPLICATE_PROPERTIES : NOMINAL_DUPLICATE_PROPERTIES
}

const unique = (arr) => [...new Set(arr)]

/**
 * Checks if whether the uploaded file has any duplicated values on the columns that should be unique.
 * The calculation is the following: the number of values found on the `columnValues` must be equal than
 * the multiplication of `props` and `data`.
 * I.E: `data` has 2 lines
 *      `props` has 2 unique columns
 *      `columnsValues` to not have duplicated values must have the number of lines in `data` times the
 *      number of unique columns in `props`
 * @param {string[]} props The unique properties
 * @param {string[]} columnsValues The value found on each column that should be unique
 * @param {any[]} data The upload data
 * @returns If the number of columnsValues is equal than (`data.length` * `props.length`) then `true` else `false`
 */
const hasDuplicatedValues = (props, columnsValues, data) => columnsValues?.length < props?.length * data?.length

/**
 * Checks for duplicated values in the prop column
 * @param {any[]} data The data on which the validation will be applied
 * @param {any[]} nonUniqueValues The list of non unique values of the upload data
 * @param {String[]} props The the columns that have to be unique
 * @returns An object with all duplicated values on the columns
 */
const checkForDuplicates = (data, nonUniqueValues, props) => {
    const errors = []
    let duplicatedLines = []

    if (hasDuplicatedValues(props, nonUniqueValues, data)) {
        nonUniqueValues.forEach((value) => {
            props.forEach((prop) => {
                data.forEach((item, index) => {
                    if (item[prop] !== undefined && item[prop] === value) {
                        duplicatedLines.push(index + 2)
                    }
                })

                if (duplicatedLines.length > 1) {
                    errors.push({
                        Location: `At lines: ${duplicatedLines.toString()}`,
                        Error: `Duplicated values (${value}) at ${prop} column`,
                    })
                    duplicatedLines = []
                }
            })
        })
    }
    return errors
}

/**
 * Maps and find the lines to which the error were found
 * @param {Boolean} generateValid If whether or not the user wants to generate valids for that spreadsheet
 * @param {any[]} data The data inside the spreadsheet
 * @param {String} errorMessage The message that should warn the user regarding the error
 * @returns The errors, if any, on the spreadsheet
 */
const mapGenerateValidErrors = (generateValid, data, errorMessage) => {
    const errors = []
    const duplicatedLines = []
    if (generateValid) {
        data.forEach((item, index) => {
            if (item?.val_id !== undefined) {
                duplicatedLines.push(index + 2)
            }
        })

        if (duplicatedLines.length > 0) {
            errors.push({
                Location: `At Lines: ${duplicatedLines.toString()}`,
                Error: errorMessage,
            })
        }
    } else {
        data.forEach((item, index) => {
            if (item?.val_id === undefined) {
                duplicatedLines.push(index + 2)
            }
        })

        errors.push({
            Location: `At Lines: ${duplicatedLines.toString()}`,
            Error: errorMessage,
        })
    }

    return errors
}

/**
 * Checks if there are any errors regarding the generation of valids on the uploaded file
 * @param {Boolean} generateValid If whether or not the user wants to generate valids for that spreadsheet
 * @param {any[]} data The data inside the spreadsheet
 * @returns All erros found during validation
 */
const checkGenerateValidErrors = (generateValid, data) => {
    const foundErrors = []
    const totalValids = _.countBy(data, (item) => _.has(item, 'val_id')).true || 0
    const totalItems = data?.length || 0

    if (generateValid && totalValids > 0) {
        const errors = mapGenerateValidErrors(
            generateValid,
            data,
            'Valid(s) available in the input file and is not possible to generate valid for the full list. Please, delete the valid(s) from the line(s) and try again.',
        )
        foundErrors.push(...errors)
    } else if (!generateValid && totalItems !== totalValids) {
        const errors = mapGenerateValidErrors(
            generateValid,
            data,
            'Some items are missing a valid value. Please check the data and try again.',
        )
        foundErrors.push(...errors)
    }

    return foundErrors
}

/**
 * Checks all items and validates if the `erp_ref` is complying with the database length
 * @param {any[]} data The data on which the validation will be applied
 * @returns An array with all found errors during validation
 */
const checkErpDescLength = (data) => {
    const errors = []
    data.forEach((item, index) => {
        const currErpRefCharsCount = item.erp_dsc.split('').length

        if (currErpRefCharsCount > 200) {
            errors.push({
                Location: `At line: ${index + 2}`,
                Error: `The amount of char on ERP Description is too big. The allowed amount is [200] and the current chars count is [${currErpRefCharsCount}].`,
            })
        }
    })

    return errors
}

/**
 * Checks within the file and gets the duplicated lines
 * @param {any[]} data The data on which the validation will be applied
 * @param {Schema} schema The validation schema
 * @param {String} type The type of validation
 * @returns
 */
const checkIfHasErrors = (data, schema, type, generateValid) => {
    const errors = []
    const propValues = []
    const uniqueProps = getUniqueProp(type)
    data.forEach((item) => uniqueProps.forEach((prop) => propValues.push(item[prop])))

    const nonUnique = propValues?.filter((item, index) => item !== undefined && propValues.indexOf(item) !== index)
    const duplicationErrors = checkForDuplicates(data, nonUnique, uniqueProps)
    errors.push(...duplicationErrors)

    if (type === UPLOAD_TYPES.NOMINAL_DATA) {
        const erpRefLengthErrors = checkErpDescLength(data)
        errors.push(...erpRefLengthErrors)
    }

    if (type === UPLOAD_TYPES.ACTUAL_DATA) {
        const notUniqueCombinationErrors = checkUniqueActual(data, nonUnique)
        errors.push(...notUniqueCombinationErrors)
        const generationErrors = checkGenerateValidErrors(generateValid, data)
        errors.push(...generationErrors)
    }

    const jsonErrors = checkJsonErrors(data, schema)
    errors.push(...jsonErrors)

    return { errors, data }
}

const getValidationFunction = (schema) => {
    const ajv = new Ajv({ allErrors: true, coerceTypes: true })
    require('ajv-errors')(ajv)
    const validate = ajv.compile(schema)
    return validate
}

/**
 * Checks within the file for generaal erros that the user should known
 * @param {any[]} data The data on which the validation will be applied
 * @param {Schema} schema The validation schema
 * @returns All erros found within the file
 */
const checkJsonErrors = (data, schema) => {
    const errors = []
    const validate = getValidationFunction(schema)
    data.forEach((item, index) => {
        const validationErrors = validateData(item, validate)
        if (validationErrors.length >= 1) {
            validationErrors.map((error) =>
                errors.push({
                    Location: 'At line: ' + (index + 2),
                    Error: error.message,
                }),
            )
        }
    })
    return errors
}

const getUniqueKeyForActualData = (item) =>
    item['pipe_no']?.toString() + item['co_purchase_order']?.toString() + item['co_purchase_item']?.toString()

/**
 * Checks if the data in the file have duplicated IPPN
 * @param {any[]} data The data on which the validation will be applied
 * @returns The IPPN  errors in the file
 */
const checkUniqueActual = (data) => {
    const errors = []
    const uniqueKeyValues = data.map((item) => getUniqueKeyForActualData(item))
    const uniqueValues = unique(uniqueKeyValues)

    uniqueValues.forEach((value) => {
        const duplicatedIPPN = uniqueKeyValues
            .map((item, i) => (item === value ? i + 2 : null))
            .filter((p) => p !== null)

        if (duplicatedIPPN.length > 1) {
            errors.push({
                Location: 'At lines: ' + duplicatedIPPN.toString(),
                Error: 'Values combination of pipe_no, co_purchase_order and co_purchase_item are not unique in these lines',
            })
        }
    })
    return errors
}

/**
 *
 * @param {File} acceptedFile The file which the user intend to upload
 */
export const xlsxValidator = (acceptedFile, cb, schema, type, generateValid = false) => {
    const fileReader = new FileReader()
    fileReader.readAsBinaryString(acceptedFile)
    fileReader.onload = async (evt) => {
        const data = sheetToJson(await fileToWorkSheet(evt))

        if (data.length > 10000) {
            cb({ errors: 'PIPE_DATA_QUANTITY_ERROR', data: [] })
        } else {
            cb(checkIfHasErrors(data, schema.schema, type, generateValid))
        }
    }
}

export const exportErrorsToSheet = async (data) => {
    const timestamp = new Date().getTime()
    const file_name = 'validation_errors_' + timestamp + '.xlsx'

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('errors_found_on_validation')
    worksheet.columns = _.chain(data ? data[0] : []).keys().map(m => ({header: m, key: m})).value()
    worksheet.addRows(data)

    if (file_name.match(/\.\.\//g) !== null) {
        throw new Error('Invalid Path')
    } else {
        await workbook.xlsx.writeBuffer().then(binaryData => {
            const blob = new Blob([binaryData], { type: EXCEL_BLOB_TYPE }); 
            FileSaver.saveAs(blob, file_name);
        });
    }
}