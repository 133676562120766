import Alarm from 'assets/icons/alarm.svg'
import Apps from 'assets/icons/apps.svg'
import ArrowDown from 'assets/icons/arrow-down.svg'
import ArrowLeft from 'assets/icons/arrow-left.svg'
import ArrowRight from 'assets/icons/arrow-right.svg'
import ArrowUp from 'assets/icons/arrow-up.svg'
import Bars from 'assets/icons/bars.svg'
import BoxSide from 'assets/icons/box-side.svg'
import Box from 'assets/icons/box.svg'
import BreakHorizontal from 'assets/icons/break-horizontal.svg'
import Calendar from 'assets/icons/calendar.svg'
import Chart from 'assets/icons/chart.svg'
import Check from 'assets/icons/check.svg'
import ChevronDown from 'assets/icons/chevron-down.svg'
import ChevronLeft from 'assets/icons/chevron-left.svg'
import ChevronRight from 'assets/icons/chevron-right.svg'
import ChevronUp from 'assets/icons/chevron-up.svg'
import ClockAdd from 'assets/icons/clock-add.svg'
import ClockMinus from 'assets/icons/clock-minus.svg'
import ClockWarning from 'assets/icons/clock-warning.svg'
import Clock from 'assets/icons/clock.svg'
import Close from 'assets/icons/close.svg'
import Collapse from 'assets/icons/collapse.svg'
import Config from 'assets/icons/config.svg'
import Cross from 'assets/icons/cross.svg'
import CSV from 'assets/icons/csv.svg'
import Cut from 'assets/icons/cut.svg'
import DocumentAdd from 'assets/icons/document-add.svg'
import DocumentMinus from 'assets/icons/document-minus.svg'
import Document from 'assets/icons/document.svg'
import DoubleChevronDown from 'assets/icons/double-chevron-down.svg'
import DoubleChevronLeft from 'assets/icons/double-chevron-left.svg'
import DoubleChevronRight from 'assets/icons/double-chevron-right.svg'
import DoubleChevronUp from 'assets/icons/double-chevron-up.svg'
import DonwloadSq from 'assets/icons/download-sq.svg'
import Download from 'assets/icons/download.svg'
import Expand from 'assets/icons/expand.svg'
import Export from 'assets/icons/export.svg'
import Filter from 'assets/icons/filter.svg'
import Folder from 'assets/icons/folder.svg'
import Info from 'assets/icons/info.svg'
import Location from 'assets/icons/location.svg'
import LockClosed from 'assets/icons/lock-closed.svg'
import LockOpen from 'assets/icons/lock-open.svg'
import MailAdd from 'assets/icons/mail-add.svg'
import MailMinus from 'assets/icons/mail-minus.svg'
import Mail from 'assets/icons/mail.svg'
import MessageCheck from 'assets/icons/message-check.svg'
import MessageDots from 'assets/icons/message-dots.svg'
import MessageOpen from 'assets/icons/message-open.svg'
import MessageQuestion from 'assets/icons/message-question.svg'
import MessageWarning from 'assets/icons/message-warning.svg'
import Message from 'assets/icons/message.svg'
import Minus from 'assets/icons/minus.svg'
import MrStatus from 'assets/icons/mr-status.svg'
import PDF from 'assets/icons/pdf.svg'
import Pen from 'assets/icons/pen.svg'
import PipeAge from 'assets/icons/pipe-age.svg'
import Plus from 'assets/icons/plus.svg'
import Print from 'assets/icons/print.svg'
import Refresh from 'assets/icons/refresh.svg'
import Save from 'assets/icons/save.svg'
import Search from 'assets/icons/search.svg'
import Settings from 'assets/icons/settings.svg'
import Ship from 'assets/icons/ship.svg'
import Shuffle from 'assets/icons/shuffle.svg'
import SmileNeutral from 'assets/icons/smile-neutral.svg'
import SmileSad from 'assets/icons/smile-sad.svg'
import Smile from 'assets/icons/smile.svg'
import Time from 'assets/icons/time.svg'
import Trash from 'assets/icons/trash.svg'
import Upload from 'assets/icons/upload.svg'
import UserAdd from 'assets/icons/user-add.svg'
import UserGroup from 'assets/icons/user-group.svg'
import UserMinus from 'assets/icons/user-minus.svg'
import User from 'assets/icons/user.svg'
import View from 'assets/icons/view.svg'
import Warning from 'assets/icons/warning.svg'
import XLS from 'assets/icons/xls.svg'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import styled, { css } from 'styled-components'
import { withHeight, withMargin, withWidth } from 'utils/styled-decorators'

export const icons = {
    bars: Bars,
    apps: Apps,
    calendar: Calendar,
    clock: Clock,
    'clock-add': ClockAdd,
    'clock-minus': ClockMinus,
    'clock-warning': ClockWarning,
    'arrow-up': ArrowUp,
    'arrow-right': ArrowRight,
    'arrow-down': ArrowDown,
    'arrow-left': ArrowLeft,
    'chevron-up': ChevronUp,
    'chevron-right': ChevronRight,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'double-chevron-up': DoubleChevronUp,
    'double-chevron-right': DoubleChevronRight,
    'double-chevron-down': DoubleChevronDown,
    'double-chevron-left': DoubleChevronLeft,
    check: Check,
    cross: Cross,
    warning: Warning,
    info: Info,
    chart: Chart,
    download: Download,
    user: User,
    minus: Minus,
    plus: Plus,
    refresh: Refresh,
    settings: Settings,
    save: Save,
    'lock-open': LockOpen,
    'lock-closed': LockClosed,
    document: Document,
    'document-add': DocumentAdd,
    'document-minus': DocumentMinus,
    'break-horizontal': BreakHorizontal,
    pen: Pen,
    trash: Trash,
    print: Print,
    alarm: Alarm,
    message: Message,
    'message-question': MessageQuestion,
    'message-warning': MessageWarning,
    'message-check': MessageCheck,
    'message-dots': MessageDots,
    shuffle: Shuffle,
    'user-group': UserGroup,
    'user-add': UserAdd,
    'user-minus': UserMinus,
    mail: Mail,
    'mail-add': MailAdd,
    'mail-minus': MailMinus,
    upload: Upload,
    box: Box,
    collapse: Collapse,
    expand: Expand,
    folder: Folder,
    time: Time,
    'download-sq': DonwloadSq,
    cut: Cut,
    search: Search,
    pdf: PDF,
    csv: CSV,
    xls: XLS,
    filter: Filter,
    'box-side': BoxSide,
    location: Location,
    'message-open': MessageOpen,
    ship: Ship,
    export: Export,
    smile: Smile,
    'smile-sad': SmileSad,
    'smile-neutral': SmileNeutral,
    close: Close,
    'mr-status': MrStatus,
    'pipe-age': PipeAge,
    view: View,
    config: Config,
}

const StyledIcon = styled.div`
    background-color: ${({ theme, color }) => theme.colors[color] || theme.colors.gray};
    mask-image: url(${({ name }) => icons[name] || null});
    background-repeat: no-repeat;
    background-size: cover;
    mask-size: cover;
    min-width: ${({ width }) => width || '24px'};
    min-height: ${({ height }) => height || '24px'};
    ${({ onClick }) => onClick && 'cursor: pointer;'}
    ${withMargin()}
  ${withWidth('24px')}
  ${withHeight('24px')}
  ${({ disabled }) => disabled && 'opacity: 0.4; cursor: not-allowed;'}
  ${({ visible }) => !visible && 'display: none;'}
`

const Tooltip = styled.div`
    position: relative;
    font-family: 'Roboto';
    display: flex;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    flex-direction: column;
    padding: 8px;
    margin: 8px;
    border-radius: 8px;
    z-index: 999;
    transition: all 0.2s ease;
    ${({ theme }) => css`
        background-color: ${theme.colors.darkGray};
        color: ${theme.colors.white};
        font-size: ${theme.fontSizes.small};
        box-shadow: ${theme.boxShadows.high};
    `}

    /* Arrow Style */
  div {
        content: '';
        transform: rotate(45deg);
        background: ${({ theme }) => theme.colors.darkGray};
        width: 10px;
        height: 10px;
        position: absolute;
        z-index: -1;
    }

    &[data-placement='bottom'] div {
        top: -4px;
    }
    &[data-placement='right'] div {
        left: -4px;
    }
    &[data-placement='left'] div {
        right: -4px;
    }
    &[data-placement='top'] div {
        bottom: -4px;
    }
`

const TooltipPopper = React.forwardRef(
    ({ style, scheduleUpdate, tooltipPosition, placement, visibility, tooltip, arrowProps }, ref) => {
        useEffect(() => {
            scheduleUpdate()
        }, [visibility, scheduleUpdate])

        const tooltipStyle = {
            ...style,
            transform: `${style.transform} ${visibility ? 'translateX(0)' : 'scale(0.9)'}`,
        }

        return (
            <Tooltip
                open={visibility}
                tooltipPosition={tooltipPosition}
                ref={ref}
                style={tooltipStyle}
                data-placement={placement}
            >
                {tooltip}
                <div ref={arrowProps.ref} style={arrowProps.style} />
            </Tooltip>
        )
    },
)

const Icon = ({ tooltip, tooltipPosition, ...rest }) => {
    const [visibility, setVisibility] = useState(false)

    const showTooltip = () => setVisibility(true)
    const hideTooltip = () => setVisibility(false)

    const renderIcon = tooltip ? (
        <Manager>
            <Reference>
                {({ ref }) => <StyledIcon {...rest} ref={ref} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} />}
            </Reference>
            <Popper
                placement={tooltipPosition || 'top'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'viewport',
                    },
                }}
            >
                {({ ref, style, placement, scheduleUpdate, arrowProps }) => (
                    <TooltipPopper
                        {...{
                            style,
                            scheduleUpdate,
                            tooltipPosition,
                            placement,
                            visibility,
                            tooltip,
                            arrowProps,
                            ref,
                        }}
                    />
                )}
            </Popper>
        </Manager>
    ) : (
        <StyledIcon {...rest} />
    )

    return renderIcon
}

Icon.propTypes = {
    /**
     * Define the icon that should be rendered
     */
    name: PropTypes.string,
    /**
     * Tooltip text that will appear when you hover the icon
     */
    tooltip: PropTypes.string,
    /**
     * Tooltip position (top | right | bottom | left)
     */
    tooltipPosition: PropTypes.string,
    /**
     * A color key defined in the theme
     */
    color: PropTypes.string,
    /**
     * Override CSS width property. Must be a valid CSS width value as a string
     */
    width: PropTypes.string,
    /**
     * Override CSS height property. Must be a valid CSS height value as a string
     */
    height: PropTypes.string,
    /**
     * Override CSS margin property. Must be a valid CSS margin value as a string
     */
    margin: PropTypes.string,
    /**
     * Override CSS to disable the icon if true
     */
    disabled: PropTypes.bool,
    /**
     * Override CSS to hide the icon if false
     */
    visible: PropTypes.bool,
}

Icon.defaultProps = {
    disabled: false,
    visible: true,
}

export default Icon
