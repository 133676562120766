import axios from 'axios'

import { manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instanceS3 = axios.create({
    headers: {},
})

const requests = {
    uploadFile: async (url, params) => {
        try {
            const response = await instanceS3.put(url, params)
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    },
}
export default requests
