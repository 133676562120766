import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import SearchForm from 'containers/form/view/SearchForm'
import TableCard from 'containers/table/TableCard'
import TableColumnSelect from 'containers/table/TableColumnSelect'
import PrintersModal from 'containers/modal/PrintersModal'

import { useWindowSize } from 'hooks/useWindowSize'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'stores/AppStore'
import { PipeContext } from 'stores/PipeStore'
import styled from 'styled-components'
import { REFERENCE_SCREEN_SIZE } from 'utils/constants'

const IconBack = styled(Icon)`
    width: 24px;
    height: 24px;
    margin: 0px 16px 0px 0px;
`

const FlexColumnData = styled(FlexView)`
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    margin-right: 1 rem;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
`

const BackButton = styled(Button)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: none;
    box-shadow: none;

    color: gray;
    font-weight: bold;
    margin: 0px;

    &:active {
        opacity: 0.4;
    }
`

const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const getDeadSpace = (height) => (height > REFERENCE_SCREEN_SIZE ? 550 : 650)

const ViewActualData = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { setPageSubtitle, pageLoading } = useContext(AppContext)
    const { actualDataColumns, actualData, showPrintersCard, setShowPrintersCard } = useContext(PipeContext)

    const [deadSpace, setDeadSpace] = useState(0)
    const { height } = useWindowSize(0)

    useEffect(() => {
        setDeadSpace(getDeadSpace(height))
    }, [height])

    useEffect(() => {
        setPageSubtitle(' - ' + t('View Actual Data'))
    }, [setPageSubtitle, t])

    const onOutsideClick = () => setShowPrintersCard(false)

    return (
        <FlexPage>
            <LoadingOverlay visible={pageLoading} />
            <FlexView flexDirection="row" alignItems="flex-end" width="100%">
                <BackButton flexDirection="row" onClick={() => history.push('/')} fontSize="subtitle">
                    <IconBack name="arrow-left" />
                    <div> {t('Go Back')} </div>
                </BackButton>
            </FlexView>
            <FlexView flexDirection="row" width="100%" margin="0.5rem">
                <SearchForm isNominal={false}> </SearchForm>
            </FlexView>
            <FlexView flexDirection="row" justifyContent="flex-start" maxWidth="100%">
                <FlexColumnData maxWidth="100%">
                    <TableColumnSelect
                        columns={actualDataColumns}
                        data={actualData}
                        isLoad={false}
                        isNominal={false}
                        deadSpace={deadSpace}
                    ></TableColumnSelect>
                </FlexColumnData>
                <FlexColumnData maxWidth="100%" minWidth="10rem" height="100%">
                    <TableCard columns={actualDataColumns} tableData={ actualData ? actualData : [] } customDeadSpace={deadSpace}>
                        {' '}
                    </TableCard>
                </FlexColumnData>
            </FlexView>
            <PrintersModal isOpen={showPrintersCard} onOutsideClick={onOutsideClick} />
        </FlexPage>
    )
}
export default ViewActualData
