import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { withColor, withFontSize, withMargin, withPadding } from 'utils/styled-decorators'

import { FlexView } from '../common'

const StyledTextArea = styled.textarea`
    ${withMargin('8px 0px')}
    ${withColor('darkGray')}
  ${withFontSize('medium')}
  ${withPadding('8px 16px')}
  width: 100%;
    min-height: 44px;
    font-weight: bold;
    border-radius: ${({ theme }) => theme.borderRadius.input};
    font-family: 'Roboto';
    outline: none;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    transition: border 0.2s linear;
    resize: ${({ resize }) => resize || 'none'};

    /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

    /* total width */
    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.lightGray};
    }
`

const Label = styled.label`
    white-space: nowrap;
    margin-right: 8px;
`

/**
 * Default textarea component
 */
const TextArea = React.forwardRef(({ label, inline, width, margin, message, messageType, ...rest }, ref) => (
    <FlexView
        width={width}
        flexDirection={inline ? 'row' : 'column'}
        alignItems={inline ? 'center' : 'flex-start'}
        justifyContent="flex-start"
        margin={margin}
        fontSize="medium"
        fontWeight="bold"
    >
        {label && <Label>{label}</Label>}
        <StyledTextArea {...rest} ref={ref} />
    </FlexView>
))

TextArea.propTypes = {
    /**
     * Label that accompanies the input
     */
    label: PropTypes.string,
    /**
     * Defines if the label should be rendered in the same line as the input
     */
    inline: PropTypes.bool,
    /**
     * A color key defined in the theme
     */
    color: PropTypes.string,
    /**
     * Override CSS resize property. Must be a valid CSS resize value as a string.
     */
    resize: PropTypes.string,
    /**
     * Override CSS width property. Must be a valid CSS width value as a string. IMPORTANT: THE WIDTH IS SHARED WITH THE LABEL ON INLINE INPUTS
     */
    width: PropTypes.string,
    /**
     * Override CSS margin property. Must be a valid CSS margin value as a string
     */
    margin: PropTypes.string,
    /**
     * Override CSS padding property. Must be a valid CSS padding value as a string
     */
    padding: PropTypes.string,
    /**
     * A font size key defined in the theme
     */
    fontSize: PropTypes.string,
}

TextArea.defaultProps = {
    margin: '8px 0px',
}

export default TextArea
