import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { withFontSize, withMargin } from 'utils/styled-decorators'

const Wrapper = styled.label`
    -webkit-tap-highlight-color: transparent;
    font-family: 'Roboto';
    height: 24px;
    display: flex;
    flex-direction: row;
    user-select: none;
    align-items: center;
    width: fit-content;
    position: relative;
    cursor: pointer;
    ${withMargin('8px 0px')}
    ${withFontSize('small')}
  & div {
        color: ${({ state, theme }) => (state ? theme.colors.gray : theme.colors.primary)};
        margin-right: 8px;
        font-weight: 500;
        transition: color 0.3s ease;

        &:last-child {
            color: ${({ state, theme }) => (state ? theme.colors.primary : theme.colors.gray)};
            margin-right: 0px;
        }
    }
    input {
        display: none;
        & + span {
            padding-left: 50px;
            min-height: 24px;
            line-height: 24px;
            display: block;
            color: ${({ theme }) => theme.colors.gray};
            position: relative;
            white-space: nowrap;
            transition: color 0.3s ease;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                border-radius: 12px;
            }
            &:before {
                top: 0;
                left: 0;
                width: 42px;
                height: 24px;
                background: ${({ theme }) => theme.colors.lightGray};
                transition: all 0.3s ease;
            }
            &:after {
                width: 18px;
                height: 18px;
                background: #fff;
                top: 3px;
                left: 3px;
                box-shadow: 0 1px 3px rgba(#121621, 0.1);
                transition: all 0.45s ease;
            }
            strong {
                font-weight: normal;
                position: relative;
                display: block;
                top: 1px;
                &:before,
                &:after {
                    font-size: 14px;
                    font-weight: 500;
                    display: block;
                    backface-visibility: hidden;
                }
                &:before {
                    content: '${({ alternate, textOff }) => (alternate ? '' : textOff)}';
                    ${withFontSize('small')}
                    transition: all .3s ease .2s;
                }
                &:after {
                    content: '${({ alternate, textOn }) => (alternate ? '' : textOn)}';
                    ${withFontSize('small')}
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: ${({ alternate, theme }) => (alternate ? theme.colors.gray : theme.colors.fontSuccess)};
                    transition: all 0.3s ease;
                    transform: translate(2px, 0);
                }
            }
        }

        &:checked {
            & + span {
                &:before {
                    background: ${({ alternate, theme }) => (alternate ? theme.colors.lighGray : theme.colors.success)};
                }
                &:after {
                    background: #fff;
                    transform: translate(18px, 0);
                }
                strong {
                    &:before {
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.3s ease;
                        transform: translate(-2px, 0);
                    }
                    &:after {
                        opacity: 1;
                        visibility: visible;
                        transform: translate(0, 0);
                        transition: all 0.3s ease 0.2s;
                    }
                }
            }
        }
    }
`

/**
 * Toggle component for implementing boolean input
 */
const Toggle = ({ state, onChange, ...rest }) => {
    const handleHTMLEventChange = (event) => {
        onChange(event.target.checked)
    }

    return (
        <Wrapper state={state} {...rest}>
            {rest.alternate && <div>{rest.textOff}</div>}
            <input type="checkbox" checked={state} onChange={handleHTMLEventChange} />
            <span>
                <strong></strong>
            </span>
            {rest.alternate && <div>{rest.textOn}</div>}
        </Wrapper>
    )
}

Toggle.propTypes = {
    /**
     * Controls the current state of the component
     */
    state: PropTypes.bool.isRequired,
    /**
     * Off (left in alternate mode) option label
     */
    textOff: PropTypes.string.isRequired,
    /**
     * On (right in alternate mode) option label
     */
    textOn: PropTypes.string.isRequired,
    /**
     * Function called when the toggle is clicked, passing the new state as the only parameter
     */
    onChange: PropTypes.func,
    /**
     * Set the alternate UI style for the toggle if true
     */
    alternate: PropTypes.bool,
    /**
     * Override CSS margin property. Must be a valid CSS margin value as a string
     */
    margin: PropTypes.string,
    /**
     * A font size key defined in the theme
     */
    fontSize: PropTypes.string,
}

Toggle.defaultProps = {
    onChange: null,
    alternate: false,
}

export default Toggle
