import { Button, FlexView } from 'components/common'
import { Input, Select } from 'components/form'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'stores/AppStore'
import { FormContext } from 'stores/FormStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'

import api from '../../../api/api'

const FlexInput = styled(FlexView)`
    padding: 1px 8px 0px;
    flex-wrap: wrap;
    margin: 0px 10px 0px 10px;
    align-self: stretch;
`
const FlexSelect = styled(FlexView)`
    padding: 1px 8px 0px;
    flex-wrap: wrap;
    min-width: 1rem;
    max-width: 13rem;
    margin: 0px 10px 0px 10px;
    /* align-self: stretch; */
`
const FlexButton = styled(FlexView)`
    padding: 1px 1px 0px;
    flex-wrap: wrap;
    min-width: 1rem;
    max-width: 13rem;
    margin: 0px 0px 0px 15px;
    /* align-self: center; */
`

const FormButton = styled(Button)`
    margin: 20px 10px 5px 0px;
    max-width: 13rem;
    width: 13rem;
    flex-wrap: wrap;
    display: flex;
    align-self: stretch;
    color: white;
`

const FieldsActualForm = () => {
    const { t } = useTranslation()

    const {
        valid,
        setValid,
        pipeNo,
        setPipeNo,
        plant,
        setPlant,
        clearActualFilters,
        setIsToSearchActual,
        erpRefOptions,
        erpRef,
        setErpRef,
        numSalesOrderOptions,
        numSalesOrder,
        setNumSalesOrder,
        numSalesItemOptions,
        numSalesItem,
        setNumSalesItem,
        coPurchaseOrderOptions,
        coPurchaseOrder,
        setCoPurchaseOrder,
        coPurchaseItemOptions,
        coPurchaseItem,
        setCoPurchaseItem,
        actualFilters,
        setErpRefOptions,
        setCoPurchaseOrderOptions,
        setCoPurchaseItemOptions,
        setNumSalesOrderOptions,
        setNumSalesItemOptions,
    } = useContext(FormContext)

    const { idToken } = useContext(UserContext)
    const { setPageLoading } = useContext(AppContext)

    const [plants, setPlants] = useState([])
    const [canLoad, setCanLoad] = useState(true)

    useEffect(() => {
        async function fetchData() {
            try {
                if (canLoad) {
                    setPageLoading(true)
                    const result = await api.getSuppliers({}, idToken)
                    setCanLoad(false)
                    if (result) {
                        setPlants(
                            result.map((item) => ({
                                label: item.cod_plant,
                                value: item.id_plant,
                            })),
                        )
                        setPageLoading(false)
                    } else {
                        setPageLoading(false)
                    }
                }
            } catch (error) {
                setPageLoading(false)
                throw new Error(error)
            }
        }
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const canSearch =
        !valid &&
        !pipeNo &&
        erpRef === null &&
        numSalesOrder === null &&
        numSalesItem === null &&
        coPurchaseOrder === null &&
        coPurchaseItem === null &&
        plant === null
            ? true
            : false

    const formatValues = useCallback(
        (data, prop) => {
            const uniqData = _.uniqBy(data, prop)
            return _.orderBy(
                uniqData.map((item) => ({
                    value: item[prop] ? item[prop] : '',
                    label: item[prop] ? item[prop] : '<' + t('Empty') + '>',
                })),
                'label',
            )
        },
        [t],
    )

    const filterOtherFields = (filteredData, prop) => {
        if (prop !== 'erp_ref') setErpRefOptions(formatValues(filteredData, 'erp_ref'))
        if (prop !== 'co_purchase_order') setCoPurchaseOrderOptions(formatValues(filteredData, 'co_purchase_order'))
        if (prop !== 'co_purchase_item') setCoPurchaseItemOptions(formatValues(filteredData, 'co_purchase_item'))
        if (prop !== 'num_sales_order') setNumSalesOrderOptions(formatValues(filteredData, 'num_sales_order'))
        if (prop !== 'num_sales_item') setNumSalesItemOptions(formatValues(filteredData, 'num_sales_item'))
    }

    const onSelectAnyValue = (value, prop) => {
        const valueToFilter = value === '' ? null : value
        const filteredData = actualFilters.filter((actual) => actual[prop] === valueToFilter)
        switch (prop) {
            case 'erp_ref':
                setErpRef(value)
                filterOtherFields(filteredData, prop)
                break
            case 'co_purchase_order':
                setCoPurchaseOrder(value)
                filterOtherFields(filteredData, prop)
                break
            case 'co_purchase_item':
                setCoPurchaseItem(value)
                filterOtherFields(filteredData, prop)
                break
            case 'num_sales_order':
                setNumSalesOrder(value)
                filterOtherFields(filteredData, prop)
                break
            case 'num_sales_item':
                setNumSalesItem(value)
                filterOtherFields(filteredData, prop)
                break
            default:
                break
        }
    }

    return (
        <>
            <FlexInput>
                <Input
                    placeholder={t('Insert Valid')}
                    label={t('Valid')}
                    value={valid}
                    messageType="warning"
                    onChange={(e) => setValid(e.target.value)}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexInput>
            <FlexInput>
                <Input
                    placeholder={t('Insert IPPN')}
                    label={t('IPPN')}
                    value={pipeNo}
                    messageType="warning"
                    onChange={(e) => setPipeNo(e.target.value)}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexInput>
            <FlexSelect>
                <Select
                    placeholder={t('Select Erp Reference')}
                    label={t('Erp Reference')}
                    options={erpRefOptions}
                    value={erpRef}
                    onChange={(erpRef) => {
                        onSelectAnyValue(erpRef, 'erp_ref')
                    }}
                    searchable={true}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexSelect>
            <FlexSelect>
                <Select
                    placeholder={t('Select Sales Order')}
                    label={t('Sales Order')}
                    searchable={true}
                    options={numSalesOrderOptions}
                    value={numSalesOrder}
                    minWidth="13rem"
                    width="13rem"
                    onChange={(numSalesOrder) => {
                        onSelectAnyValue(numSalesOrder, 'num_sales_order')
                    }}
                />
            </FlexSelect>
            <FlexSelect>
                <Select
                    placeholder={t('Select Sales Item')}
                    label={t('Sales Item')}
                    searchable={true}
                    minWidth="13rem"
                    width="13rem"
                    options={numSalesItemOptions}
                    value={numSalesItem}
                    onChange={(numSalesItem) => {
                        onSelectAnyValue(numSalesItem, 'num_sales_item')
                    }}
                />
            </FlexSelect>
            <FlexSelect>
                <Select
                    placeholder={t('Select Purchase Order')}
                    label={t('Customer Purchase Order')}
                    searchable={true}
                    options={coPurchaseOrderOptions}
                    value={coPurchaseOrder}
                    onChange={(coPurchaseOrder) => {
                        onSelectAnyValue(coPurchaseOrder, 'co_purchase_order')
                    }}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexSelect>
            <FlexSelect>
                <Select
                    placeholder={t('Select Purchase Item')}
                    label={t('Customer Purchase Item')}
                    searchable={true}
                    options={coPurchaseItemOptions}
                    value={coPurchaseItem}
                    onChange={(coPurchaseItem) => {
                        onSelectAnyValue(coPurchaseItem, 'co_purchase_item')
                    }}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexSelect>
            <FlexSelect>
                <Select
                    label={t('Supplier')}
                    placeholder={t('Select a Supplier')}
                    searchable={true}
                    options={plants}
                    value={plant}
                    onChange={(plant) => {
                        setPlant(plant)
                    }}
                    minWidth="13rem"
                    width="13rem"
                />
            </FlexSelect>
            <FlexButton alignSelf="center" justifyContent="center">
                <FormButton
                    backgroundColor="error"
                    maxWidth="13rem"
                    width="13rem"
                    onClick={() => clearActualFilters()}
                    disabled={canSearch}
                >
                    {t('CLEAR')}
                </FormButton>
            </FlexButton>
            <FlexButton alignSelf="center" justifyContent="center">
                <FormButton backgroundColor="primary" onClick={() => setIsToSearchActual(true)} disabled={canSearch}>
                    {t('SEARCH')}
                </FormButton>
            </FlexButton>
        </>
    )
}

export default FieldsActualForm
