import { FlexView } from 'components/common'
import { Toggle } from 'components/form'
import React, { useContext } from 'react'
import { AppContext } from 'stores/AppStore'

const UnitSelect = (props) => {
    const { lengthUnit, setLengthUnit, weightUnit, setWeightUnit } = useContext(AppContext)

    const onWeightChange = (value) => {
        setWeightUnit(value ? 'kg' : 'lb')
    }

    const onLengthChange = (value) => {
        setLengthUnit(value ? 'mm' : 'ft')
    }

    return (
        <FlexView flexDirection="row" alignItems="center" margin="0px 16px" {...props}>
            <Toggle
                state={weightUnit === 'kg'}
                textOff="Lb"
                textOn="Kg"
                onChange={onWeightChange}
                alternate
                margin="0px 8px 0px 0px"
            />
            <Toggle
                state={lengthUnit === 'mm'}
                textOff="Ft"
                textOn="mm"
                onChange={onLengthChange}
                alternate
                margin="0px 0px 0px 8px"
            />
        </FlexView>
    )
}

export default UnitSelect
