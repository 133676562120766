import { Card } from 'components/common'
import TabButtons from 'containers/layout/TabButtons'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

// import { UserContext } from 'stores/UserStore'

const Wrapper = styled(Card)`
    flex-direction: row;
    display: flex;
    align-self: stretch;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.component};
    flex-wrap: wrap;
    box-shadow: ${({ theme }) => theme.boxShadows.medium};
    padding: 0px;
    margin: 16px 16px 0px;
    overflow: hidden;
`

const StockTabs = ({ group }) => {
    const { t } = useTranslation()
    // const { currentUser } = useContext(UserContext)
    const history = useHistory()

    const redirect = useCallback(
        (path) => () => {
            history.push(path)
        },
        [history],
    )

    const tabOptions = useMemo(
        () => [
            {
                value: 'nominal-data',
                index: 0,
                label: t('Nominal Data'),
                role: 'id',
                uri: `/upload/nominal-data`,
                onClick: redirect(`/upload/nominal-data`),
                group: 'upload',
                subItems: [],
            },
            {
                value: 'actual-data',
                index: 1,
                label: t('Actual Data'),
                role: 'id',
                uri: `/upload/actual-data`,
                onClick: redirect(`/upload/actual-data`),
                group: 'upload',
                subItems: [],
            },
            {
                value: 'certificates',
                index: 2,
                label: t('Certificates'),
                role: 'id',
                uri: `/upload/certificates`,
                onClick: redirect(`/upload/certificates`),
                group: 'upload',
                subItems: [],
            },
        ],
        [t, redirect],
    )

    const content =
        tabOptions.length > 0 ? (
            <Wrapper>
                {tabOptions
                    .filter((p) => p.group === group)
                    .map((option, i) => (
                        <TabButtons key={i} option={option} index={i}></TabButtons>
                    ))}
            </Wrapper>
        ) : null

    return content
}

export default StockTabs
