import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import LogoImg from '../../assets/images/logo-small.svg'
import Header from '../../components/layout/Header'
import { AppContext } from '../../stores/AppStore'
import { UserContext } from '../../stores/UserStore'

const Logo = styled.div`
    align-self: center;
    text-align: center;
    font-family: Roboto;
    span {
        font-weight: 100;
    }
    span:first-child {
        color: ${({ theme }) => theme.colors.primary};
        margin-right: 4px;
        font-weight: bold;
    }
    font-size: 24px;
    margin: 32px 0px 16px;
`

const AppHeader = () => {
    const { t } = useTranslation()
    const { isHeaderVisible, pageTitle, pageSubtitle } = useContext(AppContext)

    const { authState, signOut, currentUser } = useContext(UserContext)
    const history = useHistory()

    const redirect = useCallback(
        (path) => () => {
            history.push(path)
        },
        [history],
    )

    const [userSettings, setUserSettings] = useState({})

    // const downloadUserGuide = useCallback((key) => new Promise((resolve, reject) => {
    //   api.getUserGuideDownloadUrl(key, process.env.REACT_APP_VERSION, idToken).then(url => {
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.target = '_blank'
    //     link.click()
    //   }).catch(e => {
    //     reject(e)
    //   })
    // }), [idToken])

    useEffect(() => {
        const options = [
            {
                key: 'version',
                label: 'v' + process.env.REACT_APP_VERSION,
                onClick: () => {},
            },
            {
                key: 'sign-out',
                label: t('Sign Out'),
                onClick: signOut,
                divider: true,
            },
        ]

        setUserSettings({
            username: currentUser ? currentUser?.firstName : currentUser,
            options: options,
        })
    }, [currentUser, signOut, t, setUserSettings])

    const defaultSideMenuOptions = useMemo(
        () => [
            {
                key: 'view',
                label: t('View Data').toUpperCase(),
                role: 'view',
                items: [
                    {
                        key: 'nominal-data',
                        icon: 'view',
                        label: t('Nominal Data'),
                        role: 'id',
                        onClick: redirect(`/view/nominal-data`),
                    },
                    {
                        key: 'actual-data',
                        icon: 'view',
                        label: t('Actual Data'),
                        role: 'id',
                        onClick: redirect(`/view/actual-data`),
                    },
                ],
            },
            {
                key: 'upload',
                label: t('Upload Data').toUpperCase(),
                role: 'view',
                items: [
                    {
                        key: 'nominal-data',
                        icon: 'upload',
                        label: t('Nominal Data'),
                        role: 'id',
                        onClick: redirect(`/upload/nominal-data`),
                    },
                    {
                        key: 'actual-data',
                        icon: 'upload',
                        label: t('Actual Data'),
                        role: 'id',
                        onClick: redirect(`/upload/actual-data`),
                    },
                    {
                        key: 'certificates',
                        icon: 'upload',
                        label: t('Certificates'),
                        role: 'id',
                        onClick: redirect(`/upload/certificates`),
                    },
                ],
            },
            {
                key: 'loads',
                label: t('Data Loads').toUpperCase(),
                role: 'view',
                items: [
                    {
                        key: 'loads',
                        icon: 'folder',
                        label: t('Data Validation'),
                        role: 'id',
                        onClick: redirect(`/validation`),
                    },
                ],
            },
            {
                key: 'management',
                label: t('Management').toUpperCase(),
                role: 'management',
                items: [
                    {
                        key: 'management',
                        icon: 'config',
                        label: t('End Users'),
                        role: 'id',
                        onClick: redirect(`/management/end-users`),
                    },
                    {
                        key: 'management',
                        icon: 'config',
                        label: t('Suppliers'),
                        role: 'id',
                        onClick: redirect(`/management/supplier-management`),
                    },
                    {
                        key: 'management',
                        icon: 'config',
                        label: t('Printers'),
                        role: 'id',
                        onClick: redirect(`/management/printers-management`),
                    },
                ],
            },
        ],
        [t, redirect],
    )

    const sideMenuSettings = {
        appLogoComponent: (
            <Logo>
                <span>Smartengo</span>
                <span>INVENTORY</span>
            </Logo>
        ),
        itemGroups: _.chain(defaultSideMenuOptions)
            // .filter(({ role }) => _.get(currentUser, `roles.${role}`))
            .value(),
    }

    const apps =
        currentUser &&
        currentUser.applications.map((app) => {
            return {
                key: app.id,
                label: app.label,
                iconSrc: app.icon,
                onClick: () => (window.location.href = app.link),
            }
        })

    return isHeaderVisible && authState === 'SIGNED_IN' ? (
        <Header
            title={pageTitle}
            subtitle={pageSubtitle}
            appIconSrc={LogoImg}
            onAppIconClick={redirect('/')}
            userSettings={userSettings}
            sideMenuSettings={sideMenuSettings}
            apps={apps}
        />
    ) : null
}

export default AppHeader
