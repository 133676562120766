import { FlexView, Link, Icon, Button } from 'components/common'
import { TableWrapper } from 'components/common/Table'
import { Input, Select } from 'components/form'
import { useWindowSize } from 'hooks/useWindowSize'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFilters, usePagination, useSortBy, useTable } from 'react-table'
import styled from 'styled-components'

const Table = styled(TableWrapper)`
    flex-direction: column;

    th {
        white-space: nowrap;
    }
    td {
        white-space: nowrap;
    }
    min-width: 0px;

    th input {
        font-size: ${({ theme }) => theme.fontSizes.small};
        padding: 4px;
        min-height: 0px;
    }
`

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const onChange = useCallback(
        e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        },
        [setFilter],
    )

    return (
        <Input value={filterValue || ''} onChange={onChange} placeholder="Type to Filter" margin="0px" width="100%" />
    )
}

const sortByDate = (rowA, rowB, columnId) => {
    const valueA = _.get(rowA, `original.${columnId}`)
    const valueB = _.get(rowB, `original.${columnId}`)
    return moment(valueA).isAfter(valueB) ? 1 : -1
}

const tableOptions = {
    defaultColumn: {
        Filter: DefaultColumnFilter,
    },
    autoResetPage: false,
    sortTypes: {
        date: sortByDate,
    },
}

const FilterableTable = ({ data, columns, onRowsChange, maxHeight, deadSpace, ...rest }) => {
    const { t } = useTranslation()
    const avgLineHeight = 32
    const { height } = useWindowSize(0)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        rows,
        state: { pageIndex, pageSize, filters },
    } = useTable(
        {
            columns,
            data,
            ...tableOptions,
        },
        useFilters,
        useSortBy,
        usePagination,
    )

    useEffect(() => {
        try {
            onRowsChange(rows.map(row => row.original))
        } catch (e) {
            onRowsChange && typeof onRowsChange !== 'function' && console.error('onRowsChange must be a function!')
        }
    }, [filters, rows, onRowsChange])

    useEffect(() => {
        if (height > 0) {
            const pageSize = Math.floor((height - deadSpace) / avgLineHeight) > 1 
                                ? Math.floor((height - deadSpace) / avgLineHeight) : 1
            setPageSize(pageSize)
        }
    }, [deadSpace, height, setPageSize])

    return (
        <Table elevation="none" width="100%" fontSize="13px" {...rest}>
            <div className="table-wrap">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.customHeaderProps)} className="sortable">
                                        <div {...column.getSortByToggleProps({ title: '' })}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <svg
                                                            width="13"
                                                            height="7"
                                                            viewBox="0 0 13 7"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            transform="rotate(180)"
                                                        >
                                                            <path
                                                                d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z"
                                                                fill="#777777"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="13"
                                                            height="7"
                                                            viewBox="0 0 13 7"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z"
                                                                fill="#777777"
                                                            />
                                                        </svg>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        {column.hasIcon ? (
                                            <FlexView
                                                flexDirection="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                flex="1"
                                                minWidth="0px"
                                                flexWrap="wrap"
                                            >
                                                <Button
                                                    backgroundColor="white"
                                                    onClick={() => column.headerIconClickFunction(data)}
                                                >
                                                    <Icon name={column.iconName} />
                                                </Button>
                                            </FlexView>
                                        ) : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps(cell.column.customCellProps)}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <FlexView
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    flex="1"
                    minWidth="0px"
                    flexWrap="wrap"
                >
                    <Input
                        inline
                        type="number"
                        label={t('Go to page')}
                        fontSize="13px"
                        margin="0px"
                        padding="8px 4px"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        width="180px"
                    />
                </FlexView>
                <FlexView
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="13px"
                    minWidth="0px"
                    flexWrap="wrap"
                >
                    <Link
                        noDecoration
                        color="gray"
                        fontSize="big"
                        fontWeight="bold"
                        padding="4px"
                        margin="0px 4px"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'«'}
                    </Link>{' '}
                    <Link
                        noDecoration
                        color="gray"
                        fontSize="big"
                        fontWeight="bold"
                        padding="4px"
                        margin="0px 4px"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {'‹'}
                    </Link>{' '}
                    <FlexView flexDirection="row" margin="0px 8px" fontWeight="bold">
                        {pageIndex + 1} {t('of')} {pageOptions.length}
                    </FlexView>
                    <Link
                        noDecoration
                        color="gray"
                        fontSize="big"
                        fontWeight="bold"
                        padding="4px"
                        margin="0px 4px"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        {'›'}
                    </Link>{' '}
                    <Link
                        noDecoration
                        color="gray"
                        fontSize="big"
                        fontWeight="bold"
                        padding="4px"
                        margin="0px 4px"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {'»'}
                    </Link>{' '}
                </FlexView>
                <FlexView
                    flex="1"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    minWidth="0px"
                    flexWrap="wrap"
                >
                    <FlexView
                        flexDirection="row"
                        alignItems="center"
                        fontSize="small"
                        borderRadius="component"
                        backgroundColor="whitesmoke"
                        padding="8px"
                        margin="0px 16px"
                    >
                        <FlexView margin="0px 8px 0px 0px" color="secondary">
                            {data.length}
                        </FlexView>
                        <span>{t('Items')}</span>
                    </FlexView>
                    <Select
                        margin="0px"
                        value={pageSize}
                        options={[...new Set([5, 10, 15, 20, 25, 30, pageSize].sort((a, b) => a - b))].map(
                            pageSize => ({
                                value: pageSize,
                                label: `${t('Show')} ${pageSize}`,
                            }),
                        )}
                        onChange={value => {
                            setPageSize(Number(value))
                        }}
                    />
                </FlexView>
            </div>
        </Table>
    )
}

export default FilterableTable
