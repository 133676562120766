import { FlexView } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import UnitSelect from './UnitSelect'

const UnitFlex = styled(FlexView)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: 1px dashed #ddd;
    box-shadow: none;
    margin: 0px;
    padding: 5px 0px 5px 0px;
    align-items: center;
`
const Label = styled.label`
    white-space: nowrap;
    margin-top: 0px;
    margin-bottom: 8px;
    font-weight: bold;
`

const UnitSelectActual = (props) => {
    const { t } = useTranslation()
    return (
        <UnitFlex>
            <Label> {t('Select Units')} </Label>
            <UnitSelect></UnitSelect>
        </UnitFlex>
    )
}

export default UnitSelectActual
