import { FlexView } from 'components/common'
import TableCard from 'containers/table/TableCard'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const FlexColumnData = styled(FlexView)`
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    margin-right: 1 rem;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
`

const NominalLoadDetails = ({ tableData, columns }) => {
    const { height } = useWindowSize(0)
    const [deadSpace, setDeadSpace] = useState(0)
    useEffect(() => {
        setDeadSpace(450)
    }, [height])

    return (
        <FlexView flexDirection="row" justifyContent="flex-start" maxWidth="100%" width="100%" flexWrap="wrap">
            <FlexColumnData maxWidth="100%" minWidth="10rem" height="100%">
                <TableCard columns={columns} tableData={tableData} customDeadSpace={deadSpace} title="Nominal Data">
                    {' '}
                </TableCard>
            </FlexColumnData>
        </FlexView>
    )
}

export default NominalLoadDetails
