import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import { FlexView } from 'components/common'

import Header from 'containers/layout/Header'
import PrivateRoute from 'containers/router/PrivateRoute'

import Home from 'pages/Home'

import ValidationDetails from 'pages/data-validation/ValidationDetails'
import DataValidation from 'pages/data-validation/DataValidation'
import UploadActualData from 'pages/data-upload/UploadActualData'
import UploadNominalData from 'pages/data-upload/UploadNominalData'
import ViewActualData from 'pages/data-view/ViewActualData'
import ViewNominalData from 'pages/data-view/ViewNominalData'
import EndUserManagement from 'pages/management/EndUserManagement'
import UploadCertificates from 'pages/data-upload/UploadCertificates'
import SupplierManagement from 'pages/management/SupplierManagement'
import PrintersManagement from 'pages/management/PrintersManagement'

import { ExportProvider } from 'stores/ExportStore'
import { FormProvider } from 'stores/FormStore'
import { PipeProvider } from 'stores/PipeStore'
import { UploadProvider } from 'stores/UploadStore'
import RedirectTo from 'pages/RedirectTo'

const Router = () => {
  return (
    <FlexView height="100vh">
      <BrowserRouter>
        <Header />
          <Switch>
            <Route path="/redirect/:to" exact>
                    <RedirectTo />
            </Route>
            <ExportProvider>
                <UploadProvider>
                  <PipeProvider>
                  <FormProvider>
                    <PrivateRoute path="/view/actual-data" exact>
                          <ViewActualData type="view" />   
                    </PrivateRoute>
                    <PrivateRoute path="/view/nominal-data" exact>
                          <ViewNominalData type="view" />   
                    </PrivateRoute>
                    <PrivateRoute path="/upload/nominal-data" exact>
                      <UploadNominalData type="upload" />   
                    </PrivateRoute>
                    <PrivateRoute path="/upload/actual-data" exact>
                      <UploadActualData type="upload" />
                    </PrivateRoute>
                    <PrivateRoute path="/upload/certificates" exact>
                      <UploadCertificates type="upload" />
                    </PrivateRoute>     
                    <PrivateRoute path="/validation" exact>
                      <DataValidation type="validation" />
                    </PrivateRoute>     
                    <PrivateRoute path="/validation/details">
                      <ValidationDetails type="validation" />
                    </PrivateRoute>    
                    <PrivateRoute path="/management/end-users" exact>
                      <EndUserManagement type="management" />
                    </PrivateRoute>  
                    <PrivateRoute path="/management/supplier-management" exact>
                      <SupplierManagement type="supplier" />
                    </PrivateRoute>
                    <PrivateRoute path="/management/printers-management" exact>
                      <PrintersManagement type="printers" />
                    </PrivateRoute>
                    <PrivateRoute path="/" exact>
                      <PrivateRoute exact path='/'>
                        <Home />
                      </PrivateRoute>
                      <Route>
                        <Redirect to="/" />
                      </Route>
                  </PrivateRoute>
                </FormProvider>
            </PipeProvider>
          </UploadProvider>
        </ExportProvider>
          </Switch>
      </BrowserRouter>
    </FlexView>
  )
}

export default Router