import LanguageSelect from 'containers/common/LanguageSelect'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { withColor, withMargin } from 'utils/styled-decorators'

import { FlexView } from '../../common'
import AppsSelect from './AppsSelect'
import SideMenu from './SideMenu'
import UserOptions from './UserOptions'

const Icon = styled.img`
    ${withMargin()};
    cursor: pointer;
`

const LeftSide = styled(FlexView)`
    *:first-child {
        margin-left: 0px !important;
    }
`

const RightSide = styled(FlexView)`
    *:last-child {
        margin-right: 0px !important;
    }
`
const CustomFlexView = styled(FlexView)`
    min-height: 50px;
`

const Title = styled.div`
    ${withColor('darkGray')}
`

const Subtitle = styled.div`
    ${withColor('gray')}
    font-size: medium;
    margin-left: 10px;
    margin-top: 3px;
`

const Header = ({ title, subtitle, sideMenuSettings, appIconSrc, onAppIconClick, apps, userSettings }) => (
    <CustomFlexView
        height="50px"
        width="calc(100% - 32px)"
        padding="10px 16px"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="stretch"
        elevation="low"
    >
        <LeftSide flexDirection="row" justifyContent="flex-start" alignItems="center">
            {sideMenuSettings && <SideMenu {...sideMenuSettings} />}
            <FlexView
                fontSize="big"
                fontWeight="bold"
                margin="0px 12px 0px"
                color="gray"
                flexDirection="row"
                alignItems="center"
            >
                {appIconSrc && (
                    <Icon
                        src={appIconSrc}
                        onClick={onAppIconClick}
                        height="36px"
                        margin="0px 12px 0px 0px"
                        alt="Application Logo"
                    />
                )}
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </FlexView>
        </LeftSide>
        <RightSide flexDirection="row" justifyContent="flex-end" alignItems="center">
            <LanguageSelect margin="0px 16px" fontSize="small" noLabel noWrapper />
            {apps && <AppsSelect apps={apps} />}
            {userSettings && <UserOptions {...userSettings} />}
        </RightSide>
    </CustomFlexView>
)

Header.propTypes = {
    /**
     * The title that will appear on the header
     */
    // title: PropTypes.string.isRequired,
    /**
     * The application icon source, positioned to the left of the title
     */
    appIconSrc: PropTypes.any,
    /**
     * Function called when the application icon is clicked, normally redirecting to the home page
     */
    onAppIconClick: PropTypes.func,
    /**
     * Side menu settings. Object composed of the application logo source and item groups that will form the side menu entries
     */
    sideMenuSettings: PropTypes.shape({
        appLogoSrc: PropTypes.any,
        itemGroups: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                label: PropTypes.string,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        key: PropTypes.string.isRequired,
                        label: PropTypes.string.isRequired,
                        iconSrc: PropTypes.string,
                        onClick: PropTypes.func,
                    }),
                ),
            }),
        ),
    }),
    /**
     * Array of configuration objects to render the applications tooltip
     */
    apps: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            iconSrc: PropTypes.any.isRequired,
            onClick: PropTypes.func,
        }),
    ),
    /**
     * Side menu settings. Object composed of the application logo source and item groups that will form the side menu entries
     */
    userSettings: PropTypes.shape({
        username: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                onClick: PropTypes.func,
                divider: PropTypes.bool,
            }),
        ),
    }),
}

export default Header
