import { Card, Icon } from 'components/common'
import StockLinkSelect from 'components/home/StockLinkSelect'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'

const Wrapper = styled(Card)`
    flex-direction: row;
    display: flex;
    align-self: stretch;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.component};
    flex-wrap: wrap;
    box-shadow: ${({ theme }) => theme.boxShadows.medium};
    padding: 0px;
    margin: 16px 16px 0px;
    overflow: hidden;
    height: 25px;
`

const StockButtons = styled.button`
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 10px;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    border: none;
    border-radius: 0;
    transition: all 0.1s linear;
    cursor: pointer;
    outline: 0;
    opacity: 1;
    user-select: none;
    flex: 1;
    justify-content: space-around;
    margin: 0px;
    padding: 0px 8px;
    height: 25px;
    display: inline-flex;
    background-color: ${({ active, theme }) => (active ? theme.colors.secondary : 'transparent')};
    color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.gray)};

    &:hover {
        color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.secondary)};
    }
    &:active {
        opacity: 0.6;
    }
`

const MarginIcon = styled(Icon)`
    margin-left: 5px;
`

const TabButtons = ({ stock, option, index }) => {
    const node = useRef(null)

    const history = useHistory()

    const { currentUser } = useContext(UserContext)

    const [isOpen, setIsOpen] = useState(false)

    const handleOutsideClick = (e) => {
        node && node.current && !node.current.contains(e.target) && setIsOpen(false)
    }

    const options = _.filter(
        option.subItems,
        ({ value, role }) => stock.endpoints.includes(value) && _.get(currentUser, `roles.${stock.key}.${role}`),
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const onClick = () => {
        options.length > 0 ? setIsOpen((prev) => !prev) : option.onClick(option)
    }

    const onSelect = (value) => {
        history.push(`/${stock.baseUrl}/${value}`)
    }

    return (
        <Manager>
            <Wrapper ref={node} style={{ display: 'contents' }}>
                <Reference>
                    {({ ref }) => (
                        <StockButtons
                            ref={ref}
                            key={index}
                            onClick={() => onClick()}
                            active={
                                option.subItems.length > 0
                                    ? option.subItems.find((p) => p.uri === history.location.pathname)
                                    : option.uri === history.location.pathname
                            }
                        >
                            {option.label.toUpperCase()}
                            {option.subItems && option.subItems.length > 0 ? (
                                isOpen ? (
                                    <MarginIcon
                                        name="chevron-up"
                                        width="13px"
                                        height="13px"
                                        color={
                                            option.subItems.find((p) => p.uri === history.location.pathname)
                                                ? 'white'
                                                : 'grey'
                                        }
                                    />
                                ) : (
                                    <MarginIcon
                                        name="chevron-down"
                                        width="13px"
                                        height="13px"
                                        color={
                                            option.subItems.find((p) => p.uri === history.location.pathname)
                                                ? 'white'
                                                : 'grey'
                                        }
                                    />
                                )
                            ) : (
                                ''
                            )}
                        </StockButtons>
                    )}
                </Reference>
            </Wrapper>
            <Popper placement="bottom">
                {({ ref, style, scheduleUpdate, placement }) => (
                    <StockLinkSelect
                        {...{
                            ref,
                            style,
                            scheduleUpdate,
                            placement,
                            isOpen,
                            options,
                            onSelect,
                        }}
                    />
                )}
            </Popper>
        </Manager>
    )
}

export default TabButtons
