import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components'
import { withBackgroundColor, withColor, withFontSize } from 'utils/styled-decorators'

import { FlexView, Icon, Link } from '../../common'

const Grid = styled.div`
    ${withFontSize('medium')}
    font-family: 'Roboto';
    display: grid;
    grid-template-columns: repeat(3, 105px);
`

const Cell = styled.div`
    ${withColor('darkGray')}
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: transparent;
    margin: 8px;
    height: 50px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;

    &:hover {
        ${withBackgroundColor('background')}
    }

    &:active {
        opacity: 0.3;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            font-weight: bold;
            ${withBackgroundColor('lightGray')}

            &:hover {
                ${withBackgroundColor('lightGray')}
            }
        `};
`

const YearView = ({ value, currentYear, onYearClick, onMonthClick, onPrevYear, onNextYear }) => {
    const startOfYear = moment(currentYear).startOf('year')
    const endOfYear = moment(currentYear).endOf('year')

    const onMonthClickFunc = (month) => () => {
        onMonthClick(month)
    }

    const renderYearMonths = () => {
        return (
            <>
                {_.times(endOfYear.diff(startOfYear, 'months') + 1, (offset) => {
                    const month = moment(startOfYear).add(offset, 'months')
                    const isSelected = value && value.format('MM/YYYY') === month.format('MM/YYYY')

                    return (
                        <Cell key={`month${offset}`} onClick={onMonthClickFunc(month)} isSelected={isSelected}>
                            {month.format('MMM')}
                        </Cell>
                    )
                })}
            </>
        )
    }

    return (
        <FlexView width="fit-content">
            <FlexView
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                margin="8px 0px"
            >
                <Icon name="arrow-left" width="16px" height="16px" onClick={onPrevYear} margin="0px 32px" />
                <Link noDecoration color="gray" fontWeight="bold" margin="0px 8px" onClick={onYearClick}>
                    {currentYear.format('YYYY')}
                </Link>
                <Icon name="arrow-right" width="16px" height="16px" onClick={onNextYear} margin="0px 32px" />
            </FlexView>
            <Grid>{renderYearMonths()}</Grid>
        </FlexView>
    )
}

export default YearView
