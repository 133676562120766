import React, { useContext, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { UploadContext } from 'stores/UploadStore'
import styled from 'styled-components'
import { DEFAULT_UPLOAD_FILE_TYPES } from 'utils/constants'

import { FlexView } from '../common'

const getColor = (props) => {
    if (props.isDragAccept) {
        return props.theme.colors.success
    }
    if (props.isDragReject) {
        return props.theme.colors.error
    }
    if (props.isDragActive) {
        return props.theme.colors.primary
    }
    return props.theme.colors.lightGray
}

const Container = styled(FlexView)`
    font-family: 'Roboto';
    flex-wrap: nowrap;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    margin: 16px 16px 16px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    min-width: 20rem;
    transition: border 0.24s ease-in-out;

    &:hover {
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
    }
`

const UploadBox = ({ text, fileTypes = DEFAULT_UPLOAD_FILE_TYPES, fontSize = 3, setFile, height }) => {
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: fileTypes,
        multiple: false,
    })

    const { setPageLoading } = useContext(AppContext)
    const { setIsUploading, setFileType } = useContext(UploadContext)

    useEffect(() => {
        async function receiveFile() {
            try {
                if (acceptedFiles !== undefined) {
                    if (acceptedFiles.length && acceptedFiles[0].size <= 314572800) {
                        setFile(acceptedFiles[0])
                        const fileExtension = acceptedFiles[0]?.name?.split('.').pop() // takes last string after a dot
                        setFileType(fileExtension)
                        setIsUploading(true)
                    }
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
                setIsUploading(false)
            }
        }
        receiveFile()
    }, [acceptedFiles, setFile, setPageLoading, setIsUploading, setFileType])

    // const toggleModal = () => setOpen(currentState => !currentState)
    return (
        <Container
            {...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject,
                onClick: (event) => console.log(event),
            })}
            style={{
                paddingTop: `${height / 2}px`,
                paddingBottom: `${height / 2}px`,
            }}
        >
            <input {...getInputProps()} />
            {
                <font size={fontSize}>
                    {' '}
                    <p>
                        {' '}
                        <b> {text} </b>{' '}
                    </p>
                </font>
            }
            {/* {isDragReject && t("Sorry, this file type is not permitted!")} */}
        </Container>
    )
}

export default UploadBox
