import api from 'api/api'
import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'
import StockTabs from 'containers/layout/StockTabs'
import UploadCertificatesModal from 'containers/modal/UploadCertificatesModal'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { UploadContext } from 'stores/UploadStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { hasFileStyle } from 'utils/constants'

const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const UploadCertificates = () => {
    const { idToken } = useContext(UserContext)
    const { setPageSubtitle, pageLoading, setPageLoading } = useContext(AppContext)
    const { onDownloadClick } = useContext(ExportContext)
    const {
        setFilePath,
        setIdCertificate,
        setCertificateData,
        certificateData,
        setCertificateFile,
        setDscVersion,
        setCertificateName,
    } = useContext(UploadContext)

    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        setPageSubtitle(' - ' + t('Upload Certificates'))
    }, [setPageSubtitle, t])

    useEffect(() => {
        async function fetchData() {
            try {
                setPageLoading(true)
                setCertificateData([])
                const result = await api.getAllCertificatesByClient({}, idToken)
                if (result) {
                    setCertificateData(result)
                    setPageLoading(false)
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        }
        fetchData()
    }, [idToken, setCertificateData, setPageLoading])

    const openModal = useCallback(
        (filePath, idCertificate, certificateName) => {
            setCertificateName(certificateName)
            setFilePath(filePath)
            setIdCertificate(idCertificate)
            setIsOpen(true)
        },
        [setCertificateName, setFilePath, setIdCertificate],
    )

    let certificateColumns = useMemo(
        () => [
            {
                accessor: 'num_certificate',
                Header: t('Certificate Number'),
                show: true,
            },
            {
                accessor: 'desc_version',
                Header: t('Version Description'),
                show: true,
            },
            {
                accessor: 'erp_ref',
                Header: t('Erp Reference'),
                show: true,
            },
            {
                accessor: 'num_sales_order',
                Header: t('Sales Order'),
                show: true,
            },
            {
                accessor: 'num_sales_item',
                Header: t('Sales Item'),
                show: true,
            },
            {
                accessor: 'co_purchase_order',
                Header: t('Customer Purchase Order'),
                show: true,
            },
            {
                accessor: 'co_purchase_item',
                Header: t('Customer Purchase Item'),
                show: true,
            },
            {
                accessor: 'dsc_client',
                Header: t('Client'),
                show: true,
            },
            {
                accessor: 'dsc_plant',
                Header: t('Supplier'),
                show: true,
            },
            {
                accessor: 'has_certificate_file',
                Header: t('Has Certificate File') + ' ?',
                Cell: ({ cell: { value } }) => (
                    <FlexView alignItems="center" justifyContent="center" width="100%" flexDirection="row">
                        <font size="2" color={hasFileStyle[value]?.color}>
                            {' '}
                            {value ? t('Yes') : t('No')}{' '}
                        </font>
                        <Icon
                            name={hasFileStyle[value]?.icon}
                            width="16px"
                            height="16px"
                            margin="0px 4px 0px 8px"
                            color={hasFileStyle[value]?.color}
                        />
                    </FlexView>
                ),
                show: true,
                disableFilters: true,
            },
            {
                id: 'download',
                Header: t('Download'),
                disableFilters: true,
                show: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { row } }) => (
                    <FlexView alignItems="center" justifyContent="center" width="100%">
                        <Icon
                            name="download"
                            width="16px"
                            height="16px"
                            onClick={() =>
                                row.original.has_certificate_file ? onDownloadClick(row.original.file_path) : ''
                            }
                            disabled={!row.original.has_certificate_file}
                        />
                    </FlexView>
                ),
            },
            {
                id: 'upload',
                Header: t('Upload'),
                disableFilters: true,
                show: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { row } }) => (
                    <FlexView alignItems="center" justifyContent="center" width="100%">
                        <Icon
                            name="upload"
                            width="16px"
                            height="16px"
                            onClick={() =>
                                openModal(
                                    row.original.file_path,
                                    row.original.id_quality_certificate,
                                    row.original.num_certificate,
                                )
                            }
                        />
                    </FlexView>
                ),
            },
        ],
        [t, onDownloadClick, openModal],
    )

    const onCancelHistory = () => {
        clearModal()
    }

    const clearModal = (clearData) => {
        setDscVersion('')
        setCertificateFile([])
        if (clearData) setCertificateData([])
        setIsOpen(false)
    }

    const onOutsideClick = (clearData) => {
        clearModal(clearData)
    }

    return (
        <FlexPage>
            <StockTabs group="upload"> </StockTabs>
            <LoadingOverlay visible={pageLoading} />
            <Card
                alignSelf="stretch"
                justifyContent="flex-start"
                alignItems="center"
                padding="0.5rem 1rem"
                margin="10px 0px 0px 10px"
            >
                <FilterableTable columns={certificateColumns} data={certificateData} deadSpace={350} />
            </Card>
            <UploadCertificatesModal
                onOutsideClick={onOutsideClick}
                onModalExit={onCancelHistory}
                isOpen={isOpen}
                title="Upload Certificate"
                subtitle=""
            ></UploadCertificatesModal>
        </FlexPage>
    )
}

export default UploadCertificates
