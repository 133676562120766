import { Card, FlexView } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import FieldsActualForm from './FieldsActualForm'
import FieldsNominalForm from './FieldsNominalForm'

const Grid = styled(FlexView)`
    display: grid;
    grid-template-columns: repeat(6, auto);

    @media screen and (max-width: 3000px) {
        grid-template-columns: repeat(10, auto);
    }

    @media screen and (max-width: 2400px) {
        grid-template-columns: repeat(9, auto);
    }

    @media screen and (max-width: 2200px) {
        grid-template-columns: repeat(8, auto);
    }

    @media screen and (max-width: 1920px) {
        grid-template-columns: repeat(7, auto);
    }

    @media screen and (max-width: 1660px) {
        grid-template-columns: repeat(6, auto);
    }

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(5, auto);
    }

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(4, auto);
    }

    @media screen and (max-width: 1040px) {
        grid-template-columns: repeat(3, auto);
    }

    @media screen and (max-width: 820px) {
        grid-template-columns: repeat(2, auto);
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, auto);
    }
`

const FlexWrap = styled(FlexView)`
    flex-wrap: wrap;
    margin-left: 15px;
`

const CardWrap = styled(Card)`
    flex-wrap: wrap;
    margin: 0px;
`

const SearchForm = ({ isNominal }) => {
    const { t } = useTranslation()

    return (
        <>
            <CardWrap alignSelf="stretch" flex="1">
                <FlexWrap fontSize="big" fontWeight="bold" color="secondary" alignSelf="flex-start">
                    {' '}
                    {t('Search Data')}{' '}
                </FlexWrap>
                <Grid flexDirection="row" justifyContent="flex-start">
                    {isNominal ? <FieldsNominalForm> </FieldsNominalForm> : <FieldsActualForm> </FieldsActualForm>}
                </Grid>
            </CardWrap>
        </>
    )
}

export default SearchForm
