import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { withBorderRadius } from 'utils/styled-decorators'

import Spinner from './Spinner'

const Wrapper = styled.div`
    position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 999;
    ${withBorderRadius('0px')}
`

/**
 * Loading Overlay component used to indicate activity on any component
 */
const LoadingOverlay = ({ color, size, ...rest }) => (
    <Wrapper {...rest}>
        <Spinner {...{ color, size }} />
    </Wrapper>
)

LoadingOverlay.propTypes = {
    /**
     * A color key defined in the theme (for the Spinner)
     */
    color: PropTypes.string,
    /**
     * Define spinner size with CSS compliant value or font size theme key. Examples: medium, big, 8px, 1rem, 40%, 6vw
     */
    size: PropTypes.string,
    /**
     * Override default border radius with CSS compliant value or a border key in the theme. Examples: card, button, 8px, 1rem, 40%, 6vw
     */
    borderRadius: PropTypes.string,
    /**
     * Boolean to apply the loading overlay if true
     */
    visible: PropTypes.bool,
    /**
     * Boolean that applies fixed position to the overlay, when it's desired that it cover the entire screen
     */
    fixed: PropTypes.bool,
}

export default React.memo(LoadingOverlay)
