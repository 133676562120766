import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components'
import { withBackgroundColor, withColor, withFontSize } from 'utils/styled-decorators'

import { FlexView, Icon, Link } from '../../common'

const Grid = styled.div`
    ${withFontSize('medium')}
    font-family: 'Roboto';
    display: grid;
    grid-template-columns: repeat(7, 45px);
`

const HeaderCell = styled.div`
    ${withColor('gray')}
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const CellWrapper = styled.div`
    margin: 2px 0px;
    ${withBackgroundColor('white')}
    ${({ isRowStart }) => isRowStart && 'border-radius: 8px 0px 0px 8px;'}
  ${({ isRowEnd }) => isRowEnd && 'border-radius: 0px 8px 8px 0px;'}
  ${({ range, inRange }) =>
        range &&
        inRange &&
        css`
            ${withBackgroundColor('lightGray')}
            ${withColor('white')}
        `}
`

const Cell = styled.div`
    ${withColor('darkGray')}
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: transparent;
    padding: 6px;
    height: 29px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;

    &:hover {
        ${withBackgroundColor('background')}
    }

    &:active {
        opacity: 0.3;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            font-weight: bold;
            ${withBackgroundColor('lightGray')}

            &:hover {
                ${withBackgroundColor('lightGray')}
            }
        `};
`

const MonthView = ({ value, currentMonth, onMonthClick, onDayClick, onPrevMonth, onNextMonth }) => {
    const startOfMonth = moment(currentMonth).startOf('month')
    const endOfMonth = moment(currentMonth).endOf('month')

    const onDayClickFunc = (day) => () => {
        onDayClick(day)
    }

    const renderMonthDays = () => {
        return (
            <>
                {_.times(startOfMonth.day(), (day) => (
                    <div key={`empty${day}`} />
                ))}
                {_.times(endOfMonth.diff(startOfMonth, 'days') + 1, (offset) => {
                    const day = moment(startOfMonth).add(offset, 'days')
                    const isRowStart = day.day() === 0 || offset === 0
                    const isRowEnd = day.day() === 6 || offset + 1 === endOfMonth.date()
                    const isSelected = value && value.format('DD/MM/YYYY') === day.format('DD/MM/YYYY')

                    return (
                        <CellWrapper key={`day${offset}`} isRowStart={isRowStart} isRowEnd={isRowEnd}>
                            <Cell onClick={onDayClickFunc(day)} isSelected={isSelected}>
                                {offset + 1}
                            </Cell>
                        </CellWrapper>
                    )
                })}
            </>
        )
    }

    return (
        <FlexView width="fit-content">
            <FlexView
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                margin="8px 0px"
            >
                <Icon name="arrow-left" width="16px" height="16px" onClick={onPrevMonth} margin="0px 32px" />
                <Link noDecoration color="gray" fontWeight="bold" margin="0px 8px" onClick={onMonthClick}>
                    {currentMonth.format('MMMM YYYY')}
                </Link>
                <Icon name="arrow-right" width="16px" height="16px" onClick={onNextMonth} margin="0px 32px" />
            </FlexView>
            <Grid>
                {_.map(moment.weekdaysMin(), (weekDay) => (
                    <HeaderCell key={weekDay}>{weekDay}</HeaderCell>
                ))}
                {renderMonthDays()}
            </Grid>
        </FlexView>
    )
}

export default MonthView
