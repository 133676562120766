import { FlexView } from 'components/common'
import { Toggle } from 'components/form'
import Input from 'components/form/Input'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadContext } from 'stores/UploadStore'
import styled from 'styled-components'

const FlexMargin = styled(FlexView)`
    margin: 10px 10px;
`

const Label = styled.label`
    font-size: ${({ theme }) => theme.fontSizes.small};
    white-space: nowrap;
    margin-right: 8px;
`

const CertificateDescriptionSelect = (props) => {
    const { dscVersion, setDscVersion, toggleStatus, setToggleStatus } = useContext(UploadContext)
    const { t } = useTranslation()

    const onStatusChange = (value) => {
        setToggleStatus(!value ? false : true)
    }

    return (
        <>
            <FlexView flexDirection="row" justifyContent="flex-end" alignItems="center" width="100%">
                <Label> {t('Do you need to update the certificate version description')}? </Label>
                <Toggle
                    state={toggleStatus}
                    textOff="No"
                    textOn="Yes"
                    onChange={onStatusChange}
                    alternate
                    margin="0px 8px 0px 0px"
                />
            </FlexView>
            <FlexMargin flexDirection="row" flexWrap="wrap" width="100%">
                {toggleStatus ? (
                    <Input
                        width="100%"
                        placeholder={t('Insert Certificate Version Description')}
                        label={t('Certificate Version Description')}
                        value={dscVersion}
                        onChange={(e) => setDscVersion(e.target.value)}
                        margin="0vh 2vh 0vh 0vh"
                    />
                ) : (
                    ''
                )}
            </FlexMargin>
        </>
    )
}

export default CertificateDescriptionSelect
