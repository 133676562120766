import api from 'api/api'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { TextArea } from 'components/form'
import moment from 'moment'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { LOAD_STATUS } from 'utils/constants'

const Title = styled.div`
    font-family: Roboto;
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CustomButton = styled(Button)`
    border-radius: 5px;
    border: 0px;
    display: inline-block;
    cursor: pointer;
    font-size: 15px;
    font-weight: 800;
    padding: 10px 0px;
    margin: 8px 8px;
    width: 200px;
    &:active {
        position: relative;
        top: 1px;
    }
`
const CloseButton = styled(Button)`
    border-radius: 5px;
    border: 0px;
    display: inline-block;
    cursor: pointer;
    font-size: 15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position: relative;
        top: 1px;
    }
`
const ModalCard = styled(Card)`
    width: 528px;
`

const ValidateModal = ({ title, subtitle, label, idLoadData, isOpen, onOutsideClick, load }) => {
    const { t } = useTranslation()
    const [remarks, setRemarks] = useState('')
    const { idToken, currentUser } = useContext(UserContext)
    const { pageLoading, setPageLoading } = useContext(AppContext)

    const callValidate = useCallback(
        async (status) => {
            try {
                setPageLoading(true)
                const params = {
                    id_load: load,
                    validation_owner: currentUser.firstName,
                    validation_date: moment(),
                    remarks: remarks,
                    id_load_status: status,
                    id_load_data: idLoadData,
                }
                const result = await api.validateLoad(params, idToken)
                if (result) {
                    await onOutsideClick(true)
                    const message =
                        status === LOAD_STATUS.REJECTED ? 'Successfull Load Rejection' : 'Successfull Load Validation'
                    await toast.success(t(message) + '!')
                }
            } catch (error) {
                toast.error(error.message.includes('Constraint Error:') ? t('CONSTRAINT_ERROR') : error.message)
                setPageLoading(false)
            }
        },
        [idToken, load, onOutsideClick, remarks, setPageLoading, t, currentUser, idLoadData],
    )

    const onOutside = () => {
        setPageLoading(false)
        setRemarks('')
        onOutsideClick(false)
    }

    const onDescriptionChange = useCallback((event) => setRemarks(event.target.value), [])

    return (
        <Modal isOpen={isOpen} onOutsideClick={onOutside} onModalExit={onOutside}>
            <LoadingOverlay visible={pageLoading} />
            <ModalCard flexDirection="column" alignItems="center" justifyContent="center">
                <FlexView
                    flexDirection="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    margin="0px 0px 30px 0px "
                >
                    <FlexView flexDirection="row" justifyContent="flex-start" width="90%">
                        <FlexView flexDirection="column" alignItems="flex-start" justifyContent="center">
                            <Title>
                                {' '}
                                <i> {title} </i>{' '}
                            </Title>
                            <Subtitle> {subtitle} </Subtitle>
                        </FlexView>
                    </FlexView>
                    <FlexView flexDirection="row" justifyContent="flex-end">
                        <CloseButton onClick={onOutside}>
                            <Icon name="cross" width="20px" height="20px" color="error" />
                        </CloseButton>
                    </FlexView>
                </FlexView>
                <FlexView flexDirection="column" alignItems="center" justifyContent="flex-start" width="100%">
                    <TextArea
                        label={t(label)}
                        width="100%"
                        value={remarks}
                        onChange={onDescriptionChange}
                        rows="10"
                    ></TextArea>
                    <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" width="100%">
                        <CustomButton
                            backgroundColor="error"
                            color="white"
                            onClick={() => callValidate(LOAD_STATUS.REJECTED)}
                            disabled={!remarks}
                        >
                            {t('REJECT')}
                        </CustomButton>
                        <CustomButton
                            margin="0px 8px"
                            backgroundColor="success"
                            color="white"
                            onClick={() => callValidate(LOAD_STATUS.VALIDATED)}
                        >
                            {t('ACCEPT')}
                        </CustomButton>
                    </FlexView>
                </FlexView>
            </ModalCard>
        </Modal>
    )
}

export default ValidateModal
