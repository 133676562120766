import { Button, Card, FlexView } from 'components/common'
import { Checkbox } from 'components/form'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportContext } from 'stores/ExportStore'
import { PipeContext } from 'stores/PipeStore'

const TableColumnSelect = ({ isLoad, deadSpace, columns, data, isNominal }) => {
    const { t } = useTranslation()
    const { height } = useWindowSize()

    const { onPdfExportClick, onExcelExportClick } = useContext(ExportContext)
    const { setIsNominalView } = useContext(PipeContext)

    const maxHeight = height - deadSpace + 'px'
    const { setIndex } = useContext(PipeContext)

    const isToDisabled = !isLoad && data.length === 0 ? true : false

    useEffect(() => {
        setIsNominalView(isNominal)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card backgroundColor="white" flexDirection="column" padding="1rem">
            <FlexView
                flexDirection="row"
                fontSize="big"
                alignSelf="center"
                color="secondary"
                fontWeight="bold"
                margin="0.5rem"
            >
                {t('Columns Select')}
            </FlexView>
            <Card
                margin="0rem 0rem 1rem"
                style={{ textOverflow: 'ellipsis', overflow: 'auto' }}
                backgroundColor="background"
                maxHeight={maxHeight}
            >
                {columns &&
                    columns.map((column, i) => (
                        <Checkbox checked={column.show} onChange={() => setIndex(i)} label={column.Header} key={i} />
                    ))}
            </Card>
            <Button
                alignSelf="flex-end"
                backgroundColor="primary"
                color="white"
                width="100%"
                disabled={isToDisabled}
                onClick={() => onPdfExportClick(columns, data, t('Pipe Data'))}
            >
                {t('Export PDF')}
            </Button>
            <Button
                alignSelf="flex-end"
                backgroundColor="secondary"
                color="white"
                width="100%"
                disabled={isToDisabled}
                onClick={() => onExcelExportClick(columns, data, t('Pipe Data'))}
            >
                {t('Export Excel')}
            </Button>
        </Card>
    )
}

export default TableColumnSelect
