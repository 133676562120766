import React from 'react'
import { Slide, toast } from 'react-toastify'
import { ThemeProvider } from 'styled-components'

import './i18n'
import Router from './Router'
import { AppProvider } from './stores/AppStore'
import { UserProvider } from './stores/UserStore'
import theme from './utils/theme'

toast.configure({
    autoClose: 5000,
    draggable: false,
    transition: Slide,
    hideProgressBar: false,
    closeButton: false,
})

const App = () => (
    <ThemeProvider theme={theme}>
        <AppProvider>
            <UserProvider>
                <Router />
            </UserProvider>
        </AppProvider>
    </ThemeProvider>
)

export default App
