import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Card, FlexView, LoadingOverlay, Modal } from 'components/common'
import { Select } from 'components/form'
import wmsApi from 'api/wms-api'
import { UserContext } from 'stores/UserStore'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { PipeContext } from 'stores/PipeStore'

const Title = styled.div`
  font-family: Roboto;
  font-size: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;
`

const PrintersModal = ({ isOpen, onOutsideClick }) => {
  const { t } = useTranslation()
  const { idToken } = useContext(UserContext)
  const { pageLoading, setPageLoading } = useContext(AppContext)
  const { valIdsToPrint } = useContext(PipeContext)
  const [selectedPrinter, setSelectedPrinter] = useState(null)
  const [printers, setPrinters] = useState(null)

  const formatPrinters = (printers) => {
    const formattedPrinters = []

    for (const printer of printers) {
      const currPrinter = {
        label: printer,
        value: printer,
      }

      formattedPrinters.push(currPrinter)
    }

    return formattedPrinters
  }

  const printValIds = async () => {
    try {
      setPageLoading(true)

      const params = {
        slug: 'WMS_PRINT_ITEMS',
        params: {
          printer: selectedPrinter,
          items: valIdsToPrint,
        },
      }

      const response = await wmsApi.sendDataToWms(params, idToken)
      toast.success(t('Print done successfully') + '!')
      onOutsideClick()
      return response
    } catch (error) {
      toast.error(error.message)
    } finally {
      setPageLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        if(!printers){
          setPageLoading(true)
          const response = await wmsApi.getDataFromWms({ slug: 'WMS_PRINT' }, idToken)
          const formattedPrinters = formatPrinters(response?.result?.printers)

          setPrinters(formattedPrinters)
          setSelectedPrinter(selectedPrinter ? selectedPrinter : formattedPrinters?.length > 0 ? formattedPrinters?.[0]?.value : t('Select a Printer'))
        }
      } catch (error) {
        toast.error(error.message)
        setPrinters(null)
      } finally {
        setPageLoading(false)
      }
    })()
  }, [idToken, printers, setPageLoading, t, selectedPrinter])

  return (
    <Modal isOpen={isOpen} onOutsideClick={onOutsideClick} onModalExit={onOutsideClick}>
      <Card width='400px' height='200px'>
        <LoadingOverlay visible={pageLoading} />
        <FlexView height='20%'>
          <Title>
            <i>{t('Select Printer')}</i>
          </Title>
        </FlexView>
        <FlexView height='60%'>
          <Select value={selectedPrinter} options={printers} onChange={(e) => setSelectedPrinter(e)} />
        </FlexView>
        <FlexView flexDirection='row' alignSelf='flex-end' justifyContent='center' height='20%'>
          <Button
            backgroundColor='error'
            color='white'
            margin='10px'
            height='80%'
            width='100px'
            onClick={onOutsideClick}
          >
            {t('Cancel')}
          </Button>
          <Button
            backgroundColor='primary'
            color='white'
            margin='10px'
            height='80%'
            width='100px'
            onClick={async () => await printValIds()}
          >
            {t('Print')}
          </Button>
        </FlexView>
      </Card>
    </Modal>
  )
}

export default PrintersModal
