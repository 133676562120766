import { FlexView } from 'components/common'
import { Toggle } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'stores/AppStore'
import styled from 'styled-components'

const UnitFlex = styled(FlexView)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: 1px dashed #ddd;
    box-shadow: none;
    margin: 0px;
    padding-top: 5px;
    padding: 0px;
    align-items: center;
`

const Label = styled.label`
    font-size: ${({ theme }) => theme.fontSizes.small};
    white-space: nowrap;
    margin-top: 0px;
    margin-bottom: 8px;
    font-weight: bold;
`

const UnitSelectNominal = (props) => {
    const { odNominal, setOdNominal, wtNominal, setWtNominal, mkloss, setMkloss } = useContext(AppContext)
    const { t } = useTranslation()

    const onOdNominalChange = (value) => {
        setOdNominal(value ? 'in' : 'mm')
    }

    const onwtNominalChange = (value) => {
        setWtNominal(value ? 'in' : 'mm')
    }

    const onMklossChange = (value) => {
        setMkloss(value ? 'mm' : 'in')
    }

    return (
        <UnitFlex flexDirection="row" alignItems="center" margin="0px 0px" {...props}>
            <FlexView flexDirection="column" alignItems="center" margin="5px" padding="3px">
                <Label>{t('Nominal OD')}</Label>
                <Toggle
                    state={odNominal === 'in'}
                    textOff="mm"
                    textOn="in"
                    onChange={onOdNominalChange}
                    alternate
                    margin="0px 0px 0px 8px"
                />
            </FlexView>
            <FlexView flexDirection="column" alignItems="center" margin="5px">
                <Label>{t('WT Nominal')}</Label>
                <Toggle
                    state={wtNominal === 'in'}
                    textOff="mm"
                    textOn="in"
                    onChange={onwtNominalChange}
                    alternate
                    margin="0px 0px 0px 8px"
                />
            </FlexView>
            <FlexView flexDirection="column" alignItems="center" margin="5px" padding="3px">
                <Label>{t('Make Up Loss')}</Label>
                <Toggle
                    state={mkloss === 'mm'}
                    textOff="in"
                    textOn="mm"
                    onChange={onMklossChange}
                    alternate
                    margin="0px 0px 0px 8px"
                />
            </FlexView>
        </UnitFlex>
    )
}

export default UnitSelectNominal
