import api from 'api/api'
import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import ManagementForm from 'containers/form/management/ManagementForm'
import TableCard from 'containers/table/TableCard'
import { useWindowSize } from 'hooks/useWindowSize'
import { orderBy } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { FormContext } from 'stores/FormStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { REFERENCE_SCREEN_SIZE } from 'utils/constants'
import { dateFormatter } from 'utils/formatter'

const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const BackButton = styled(Button)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: none;
    box-shadow: none;

    color: gray;
    font-weight: bold;
    margin: 0px;

    &:active {
        opacity: 0.4;
    }
`

const IconBack = styled(Icon)`
    width: 24px;
    height: 24px;
    margin: 0px 16px 0px 0px;
`

const EndUserManagement = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { setPageSubtitle, pageLoading, setPageLoading } = useContext(AppContext)
    const { idToken } = useContext(UserContext)
    const { endUserOptions } = useContext(FormContext)
    const [deadSpace, setDeadSpace] = useState(0)
    const { height } = useWindowSize(0)
    const [endUserData, setEndUserData] = useState([])
    const [canLoad, setCanLoad] = useState(true)

    useEffect(() => {
        setDeadSpace(height > REFERENCE_SCREEN_SIZE ? 720 : 610)
    }, [height])

    useEffect(() => {
        setPageSubtitle(' - ' + t('End User Management'))
    }, [setPageSubtitle, t])

    const getStyle = (hasPermission) => (hasPermission ? { opacity: 1 } : { opacity: 0.6 })

    useEffect(() => {
        async function fetchData() {
            try {
                if (canLoad) {
                    setPageLoading(true)
                    const result = await api.getEndUsers({}, idToken)
                    if (result) {
                        const endUsersList = result.end_users.map((item) => ({
                            ...item,
                            hasPermission:
                                endUserOptions.filter((p) => Number(p.value) === Number(item.id_client)).length > 0,
                        }))
                        setEndUserData(orderBy(endUsersList, ['hasPermission', 'id'], ['desc', 'asc']))
                        setPageLoading(false)
                        setCanLoad(false)
                    }
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
                setEndUserData([])
            }
        }
        fetchData()
    }, [canLoad, idToken, endUserOptions, setPageLoading])

    const onDelete = useCallback(
        async (id) => {
            try {
                setPageLoading(true)
                const result = await api.deleteEndUser({ id_client: id }, idToken)
                if (result) {
                    setPageLoading(false)
                    setCanLoad(true)
                    toast.success(t('End User removed successfully') + '!')
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        },
        [idToken, setPageLoading, t],
    )

    const endUserColumns = useMemo(
        () => [
            {
                accessor: 'id_client',
                Header: t('Id'),
                show: true,
                Cell: ({ row }) => <span style={getStyle(row.original.hasPermission)}>{row.original.id_client} </span>,
            },
            {
                accessor: 'cod_client',
                Header: t('Name'),
                show: true,
                Cell: ({ row }) => <span style={getStyle(row.original.hasPermission)}>{row.original.cod_client} </span>,
            },
            {
                accessor: 'dsc_client',
                Header: t('Description'),
                show: true,
                Cell: ({ row }) => <span style={getStyle(row.original.hasPermission)}>{row.original.dsc_client} </span>,
            },
            {
                accessor: 'change_owner',
                Header: t('Created by'),
                show: true,
                Cell: ({ row }) => (
                    <span style={getStyle(row.original.hasPermission)}>{row.original.change_owner} </span>
                ),
            },
            {
                accessor: 'dth_reg_creation',
                Header: t('Creation Date'),
                show: true,
                Cell: ({ row }) => (
                    <span style={getStyle(row.original.hasPermission)}>
                        {dateFormatter(row.original.dth_reg_creation)}{' '}
                    </span>
                ),
            },
            {
                id: 'delete',
                Header: '',
                disableFilters: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { value, row } }) => (
                    <Button
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        onClick={() => {
                            onDelete(row.original.id_client)
                        }}
                        backgroundColor="transparent"
                        margin="0px"
                        padding="0px"
                        disabled={!row.original.hasPermission}
                    >
                        <Icon name="trash" color="error" width="24px" height="24px" margin="0px 16px 0px 0px" />
                    </Button>
                ),
                show: true,
            },
        ],
        [onDelete, t],
    )

    return (
        <FlexPage>
            <LoadingOverlay visible={pageLoading} />
            <FlexView flexDirection="row" alignItems="flex-end" width="100%">
                <BackButton flexDirection="row" onClick={() => history.push('/')} fontSize="subtitle">
                    <IconBack name="arrow-left" />
                    <div> {t('Go Back')} </div>
                </BackButton>
            </FlexView>
            <FlexView width="100%" height="100%" margin="10px 0px 10px 10px">
                <ManagementForm title="Enter Information" setCanLoad={setCanLoad}>
                    {' '}
                </ManagementForm>
                <TableCard
                    columns={endUserColumns}
                    tableData={endUserData}
                    title="End Users"
                    customDeadSpace={deadSpace}
                >
                    {' '}
                </TableCard>
            </FlexView>
        </FlexPage>
    )
}
export default EndUserManagement
