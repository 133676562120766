import { FlexView } from 'components/common'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Manager, Popper, Reference } from 'react-popper'
import styled from 'styled-components'

import Input from './Input'

const SelectBox = styled.div`
    width: calc(100% - 32px);
    min-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding: 8px 16px;
    margin: ${({ label }) => (label ? '8px 0px' : '0px')};
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    outline: none;
`

const SelectText = styled.span`
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Dropdown = styled.div`
    display: flex;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};

    svg {
        height: 10px;
        fill: ${({ theme }) => theme.colors.gray};
    }
`

const OptionsWrapper = styled.div`
    font-family: 'Roboto';
    display: flex;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    transform: ${({ popperTransform, open }) =>
        `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
    flex-direction: column;
    margin: 8px 0px;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadows.high};
    overflow-y: auto;
    border-radius: 8px;
    z-index: 999;
    transition: all 0.2s ease;
`

const Options = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: auto;
    ${({ theme, searchable }) => searchable && `border-top: 1px solid ${theme.colors.lightGray};`};

    /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

    /* total width */
    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }
`

const Option = styled.div`
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Roboto';
    user-select: none;
    min-width: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray};
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.offWhite};
    }
`

const Label = styled.label`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    white-space: nowrap;
    font-weight: bold;
    margin-right: ${({ inline }) => (inline ? '8px' : '0px')};
`

const OptionsPopper = React.forwardRef(
    ({ style, scheduleUpdate, searchable, placement, isOpen, t, search, handleSearchChange, renderOptions }, ref) => {
        useEffect(() => {
            scheduleUpdate()
        }, [isOpen, scheduleUpdate])

        return (
            <OptionsWrapper
                ref={ref}
                style={style}
                data-placement={placement}
                open={isOpen}
                popperTransform={style.transform}
            >
                {searchable && (
                    <Input
                        margin="8px"
                        width="calc(100% - 8px)"
                        placeholder={t('Search')}
                        value={search}
                        onChange={handleSearchChange}
                    />
                )}
                <Options searchable={searchable}>{renderOptions}</Options>
            </OptionsWrapper>
        )
    },
)

const Select = ({
    label,
    placeholder,
    value,
    options,
    onChange,
    searchable,
    inline,
    margin,
    width,
    fontSize,
    removePosition,
}) => {
    const node = useRef(null)
    const [isOpen, setDropdown] = useState(false)
    const [search, setSearch] = useState('')
    const { t } = useTranslation()

    const toggleSelect = () => setDropdown(isOpen => !isOpen)

    const handleSearchChange = e => {
        setSearch(e.target.value)
    }

    const onSelect = selectedValue => () => {
        onChange(selectedValue)
        toggleSelect()
    }

    const renderOptions = _.map(
        _.filter(options, ({ label }) => label.toLowerCase().includes(search.toLowerCase())),
        ({ value, label }) => (
            <Option key={value} value={value} onClick={onSelect(value)}>
                {label}
            </Option>
        ),
    )

    const getPlaceholder = useCallback(() => {
        const selectedOption = _.find(options, { value })
        return (selectedOption && selectedOption.label) || placeholder
    }, [options, placeholder, value])

    const handleOutsideClick = e => {
        node && node.current && !node.current.contains(e.target) && setDropdown(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    return (
        <Manager>
            <FlexView
                flexDirection={inline ? 'row' : 'column'}
                alignItems={inline ? 'center' : 'flex-start'}
                justifyContent="flex-start"
                position={!removePosition ? 'relative' : null}
                {...{ width, margin, fontSize }}
                ref={node}
            >
                {label && <Label inline={inline}>{label}</Label>}
                <Reference>
                    {({ ref }) => (
                        <SelectBox ref={ref} onClick={toggleSelect} label={label}>
                            <SelectText>{getPlaceholder()}</SelectText>
                            <Dropdown open={isOpen}>
                                <svg
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 1000 1000"
                                    enableBackground="new 0 0 1000 1000"
                                >
                                    <g>
                                        <path d="M500,775.4L10,287.2l64.4-62.6L500,650.2l425.6-425.6l64.4,62.6L500,775.4z" />
                                    </g>
                                </svg>
                            </Dropdown>
                        </SelectBox>
                    )}
                </Reference>
                <Popper
                    placement="bottom"
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                        },
                    }}
                >
                    {({ ref, style, scheduleUpdate, placement }) => (
                        <OptionsPopper
                            {...{
                                ref,
                                style,
                                scheduleUpdate,
                                searchable,
                                placement,
                                isOpen,
                                t,
                                search,
                                handleSearchChange,
                                renderOptions,
                            }}
                        />
                    )}
                </Popper>
            </FlexView>
        </Manager>
    )
}

Select.propTypes = {
    /**
     * Label that accompanies the input
     */
    label: PropTypes.string,
    /**
     * Placeholder when no option is selected (value is null)
     */
    placeholder: PropTypes.string,
    /**
     * Selected option value
     */
    value: PropTypes.any,
    /**
     * Array of options
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
        }),
    ).isRequired,
    /**
     * Function that is called when the value is changed, being passed as parameter the selected option value
     */
    onChange: PropTypes.func,
    /**
     * Defines if the select should support option search
     */
    searchable: PropTypes.bool,
    /**
     * Defines if the label should be rendered in the same line as the input
     */
    inline: PropTypes.bool,
    /**
     * Override CSS width property. Must be a valid CSS width value as a string
     */
    width: PropTypes.string,
    /**
     * Override CSS margin property. Must be a valid CSS margin value as a string
     */
    margin: PropTypes.string,
    /**
     * A font size key defined in the theme
     */
    fontSize: PropTypes.string,
}

Select.defaultProps = {
    value: null,
    width: 'fit-content',
    fontSize: 'medium',
    margin: '8px 0px',
}

export default Select
