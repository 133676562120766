import api from 'api/api'
import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import SupplierForm from 'containers/form/management/SupplierForm'
import TableCard from 'containers/table/TableCard'
import { useWindowSize } from 'hooks/useWindowSize'
import { orderBy } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { FormContext } from 'stores/FormStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { REFERENCE_SCREEN_SIZE } from 'utils/constants'
import { dateFormatter } from 'utils/formatter'

const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const BackButton = styled(Button)`
    font-family: 'Roboto';
    background: none;
    border-radius: 0px;
    border: none;
    box-shadow: none;

    color: gray;
    font-weight: bold;
    margin: 0px;

    &:active {
        opacity: 0.4;
    }
`

const IconBack = styled(Icon)`
    width: 24px;
    height: 24px;
    margin: 0px 16px 0px 0px;
`

const SupplierManagement = () => {
    const { setPageSubtitle, pageLoading, setPageLoading } = useContext(AppContext)
    const { idToken } = useContext(UserContext)
    const { suppliersOptions } = useContext(FormContext)
    const { t } = useTranslation()
    const history = useHistory()
    const { height } = useWindowSize()
    const [deadSpace, setDeadSpace] = useState(0)
    const [canLoad, setCanLoad] = useState(true)
    const [supplierData, setSupplierData] = useState([])

    useEffect(() => {
        setDeadSpace(height > REFERENCE_SCREEN_SIZE ? 720 : 610)
    }, [height])

    useEffect(() => {
        setPageSubtitle(' - ' + t('Supplier Management'))
    }, [setPageSubtitle, t])

    useEffect(() => {
        async function fetchData() {
            try {
                if (canLoad) {
                    setPageLoading(true)
                    const result = await api.getSuppliers({}, idToken)
                    setCanLoad(false)
                    if (result) {
                        setSupplierData(orderBy(result, ['id'], ['desc', 'asc']))
                        setPageLoading(false)
                    } else {
                        setPageLoading(false)
                    }
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
                setSupplierData([])
            }
        }
        fetchData()
    }, [canLoad, idToken, setPageLoading, suppliersOptions])

    const onDelete = useCallback(
        async (id) => {
            try {
                setPageLoading(true)
                const result = await api.deleteSupplier({ id_plant: id }, idToken)
                if (result) {
                    setPageLoading(false)
                    setCanLoad(true)
                    toast.success(t('Supplier removed successfully') + '!')
                }
            } catch (error) {
                toast.error(error.message)
                setPageLoading(false)
            }
        },
        [idToken, setPageLoading, t],
    )

    const supplierColumns = useMemo(
        () => [
            {
                accessor: 'id_plant',
                Header: t('Id'),
                show: true,
                Cell: ({ row }) => <span style={{ opacity: 1 }}>{row.original.id_plant}</span>,
            },
            {
                accessor: 'cod_plant',
                Header: t('Name'),
                show: true,
                Cell: ({ row }) => <span style={{ opacity: 1 }}>{row.original.cod_plant}</span>,
            },
            {
                accessor: 'dsc_plant',
                Header: t('Description'),
                show: true,
                Cell: ({ row }) => <span style={{ opacity: 1 }}>{row.original.dsc_plant}</span>,
            },
            {
                accessor: 'change_owner',
                Header: t('Created by'),
                show: true,
                Cell: ({ row }) => <span style={{ opacity: 1 }}>{row.original.change_owner}</span>,
            },
            {
                accessor: 'dth_reg_creation',
                Header: t('Creation Date'),
                show: true,
                Cell: ({ row }) => <span style={{ opacity: 1 }}>{dateFormatter(row.original.dth_reg_creation)}</span>,
            },
            {
                id: 'delete',
                Header: '',
                disableFilters: true,
                show: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { row } }) => (
                    <Button
                        allignItems="center"
                        justifyContent="center"
                        width="100%"
                        backgroundColor="transparent"
                        margin="0px"
                        padding="0px"
                        onClick={() => {
                            onDelete(row.original.id_plant)
                        }}
                    >
                        <Icon name="trash" color="error" width="24px" margin="0px 16px 0px 0px" />
                    </Button>
                ),
            },
        ],
        [onDelete, t],
    )

    return (
        <FlexPage>
            <LoadingOverlay visible={pageLoading} />
            <FlexView flexDirection="row" allignItems="flex-end" width="100%">
                <BackButton flexDirection="row" onClick={() => history.push('/')} fontSize="subtitle">
                    <IconBack name="arrow-left" />
                    <div>{t('Go Back')}</div>
                </BackButton>
            </FlexView>
            <FlexView width="100%" height="100%" margin="10px 0px 10px 10px">
                <SupplierForm setCanLoad={setCanLoad} />
                <TableCard
                    columns={supplierColumns}
                    tableData={supplierData}
                    title="Suppliers"
                    customDeadSpace={deadSpace}
                ></TableCard>
            </FlexView>
        </FlexPage>
    )
}

export default SupplierManagement
