import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import UploadBox from 'components/upload/UploadBox'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadContext } from 'stores/UploadStore'
import styled from 'styled-components'

import { REFERENCE_SCREEN_SIZE } from '../../utils/constants'
import { exportErrorsToSheet } from '../../utils/xlsxSchemaValidation'

const Grid = styled(FlexView)`
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (max-width: 1280px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 920px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const UploadNominalBox = ({ height }) => {
    const { t } = useTranslation()
    const { schemaErrors, nominalFile, setNominalFile, createMaterial, enable } = useContext(UploadContext)

    return (
        <>
            <Grid width="100%">
                <UploadBox
                    fontSize={height > REFERENCE_SCREEN_SIZE ? 7 : 6}
                    fileTypes=".xlsx"
                    setFile={setNominalFile}
                    text={
                        !_.isEmpty(nominalFile) ? nominalFile?.name : t('Click or drag a Nominal Data file to upload')
                    }
                    height={height}
                ></UploadBox>
            </Grid>
            <FlexView flexDirection="row" justifyContent="flex-end" alignItems="flex-end" width="100%">
                {schemaErrors.length === 0 ? (
                    <Button
                        backgroundColor="primary"
                        color="white"
                        fontSize="big"
                        margin="15px"
                        disabled={!enable}
                        onClick={() => createMaterial()}
                        width="15rem"
                    >
                        {' '}
                        {t('SAVE')}{' '}
                    </Button>
                ) : (
                    <Button
                        backgroundColor="error"
                        color="white"
                        fontSize="big"
                        margin="15px"
                        disabled={schemaErrors.length === 0}
                        onClick={() => exportErrorsToSheet(schemaErrors)}
                        width="15rem"
                    >
                        {' '}
                        {t('Download Errors Log')}{' '}
                    </Button>
                )}
            </FlexView>
        </>
    )
}

export default UploadNominalBox
