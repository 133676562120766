import { FlexView } from 'components/common'
import HomeCard from 'containers/home/HomeCard'
import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import { AppContext } from 'stores/AppStore'
import styled from 'styled-components'

const Logo = styled.div`
    font-family: Roboto;
    span {
        font-weight: 100;
    }
    span:first-child {
        color: ${({ theme }) => theme.colors.primary};
        margin-right: 16px;
        font-weight: bold;
    }
    font-size: 80px;
    margin-bottom: 32px;

    @media screen and (min-height: 800px) {
        margin-bottom: 32px;
    }

    @media screen and (max-width: 1280px) {
        font-size: 64px;
    }

    @media screen and (max-width: 800px) {
        font-size: 56px;
    }

    @media screen and (max-width: 680px) {
        font-size: 48px;
    }
`

const Grid = styled(FlexView)`
    display: grid;
    grid-template-columns: repeat(4, auto);

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(3, auto);
    }

    @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, auto);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, auto);
    }
`

const Home = () => {
    const { options, setPageSubtitle } = useContext(AppContext)
    const onInfoClick = (key) => console.log(key)

    useEffect(() => {
        setPageSubtitle('')
    }, [setPageSubtitle])

    return (
        <FlexView flex="1" alignSelf="stretch" alignItems="center" backgroundColor="background" padding="40px">
            <Logo>
                <span>Smartengo</span>
                <span> PIPE DATA </span>
            </Logo>
            <Grid width="100%" backgroundColor="transparent" alignItems="stretch" justifyContent="center">
                {_.chain(options)
                    //.filter(({ key }) => _.get(currentUser, `roles.${key}`, false))
                    .map((option) => <HomeCard option={option} onCardInfoClick={onInfoClick} key={option.key} />)
                    .value()}
            </Grid>
        </FlexView>
    )
}

export default Home
