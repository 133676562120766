// import { responseHandle, errorHandle } from '../utils/callback-handle'
import { getDefaultHeader } from 'api/api-util'
import axios from 'axios'
import i18n from 'i18n'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { decompressResponse } from 'utils/decompress'

import LoadingOverlay from '../components/common/LoadingOverlay'

const initialState = {
    user: null,
}

const isSuccessfulResponse = (response) => response && response.status === 200

const manageResponse = (response, shouldDecompress) => {
    if (isSuccessfulResponse(response)) {
        if (shouldDecompress) {
            return decompressResponse(response.data)
        }
        return response.data.body ? response?.data?.body : response.data
    } else {
        throw new Error("Couldn't retrieve data")
    }
}

export const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
    const TOKEN_COOKIE = '_ssoToken'

    const [currentUser, setCurrentUser] = useState(null)
    const [authState, setAuthState] = useState('LOGIN')
    const [isLoading, setLoading] = useState(true)
    const [idToken, setIdToken] = useState(null)
    const [, setIntervalId] = useState(0)

    const authApi = axios.create({
        baseURL: process.env.REACT_APP_AUTH_URL,
        headers: {
            'Content-Type': 'application/json',
            'VLR-Authorization': btoa(JSON.stringify({ id: process.env.REACT_APP_ID })),
        },
    })

    useEffect(() => {
        const hydrate = () =>
            new Promise((resolve, reject) => {
                //Load user in session. If no user or session expired try to refresh token;
                const token = Cookies.get(TOKEN_COOKIE)
                if (token) {
                    setIdToken(token)
                    getUserDetails(token)
                        .then(() => {
                            setAuthState('SIGNED_IN')
                            resolve()
                        })
                        .catch((err) => {
                            reject(catchAuthState(err))
                        })
                } else {
                    const query = new URLSearchParams(window.location.search)
                    const verifyToken = query.get('token')
                    if (verifyToken) {
                        getUserVerify(verifyToken)
                            .then((token) => {
                                const expires = new Date(new Date().getTime() + 60 * 60 * 1000) //expires in 60 minutes
                                Cookies.set(TOKEN_COOKIE, token, {
                                    expires: expires,
                                    domain: process.env.REACT_APP_DOMAIN,
                                })
                                setAuthState('RELOAD')
                                resolve()
                            })
                            .catch((err) => {
                                reject(catchAuthState(err))
                            })
                    } else {
                        setAuthState('REFRESH_TOKEN')
                        reject('Token Expired')
                    }
                }
            })

        hydrate()
            .catch((err) => {
                toast.error(err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUserDetails = (token) =>
        new Promise((resolve, reject) => {
            //Load user details from server using access token
            authApi
                .post('/user/detail', {}, getDefaultHeader(token))
                .then((response) => {
                    //If success save user detail data in state;
                    const data = manageResponse(response)
                    setCurrentUser(data?.user)
                    i18n.changeLanguage(data?.user?.language)
                    localStorage.setItem('language', data?.user?.language)
                    Cookies.set('language', data?.user?.language, {
                        expires: 365,
                        domain: process.env.REACT_APP_DOMAIN,
                    })
                    resolve()
                })
                .catch((err) => {
                    console.error('currentAuthenticatedUser', err)
                    reject(err)
                })
        })

    const getUserVerify = (token) =>
        new Promise((resolve, reject) => {
            authApi
                .post('/user/verify', {}, getDefaultHeader(token))
                .then((response) => {
                    const data = manageResponse(response)
                    resolve(data)
                })
                .catch((err) => {
                    console.error('verifyUser', err)
                    reject(err)
                })
        })

    const catchAuthState = (err) => {
        if (err.response) {
            if (err.response.status === 402) {
                setAuthState('WAITING_FOR_ACCESS')
            } else if (err.response.status === 403) {
                setAuthState('ACCESS_DENIED')
            } else if (err.response.status === 401) {
                setAuthState('REFRESH_TOKEN')
            }
        }
        return errorHandle(err)
    }

    const signOut = async () => {
        try {
            Cookies.remove(TOKEN_COOKIE, {
                path: '/',
                domain: process.env.REACT_APP_DOMAIN,
            })
            setCurrentUser(null)
            setAuthState(null)
            setIdToken(null)
        } catch (e) {
            console.debug(e)
        }
    }

    const refreshSession = async () => {
        try {
            Cookies.remove(TOKEN_COOKIE, {
                path: '/',
                domain: process.env.REACT_APP_DOMAIN,
            })
            setCurrentUser(null)
            setIdToken(null)
            setAuthState('REFRESH_TOKEN')
        } catch (e) {
            console.debug(e)
        }
    }

    const errorHandle = (error) => {
        let message = JSON.stringify(error)
        if (error.response) {
            message = error.response.data.message ? error.response.data.message : error.response.data
        } else {
            message = error.message ? error.message : message
        }
        return message
    }

    useEffect(() => {
        const newIntervalId = setInterval(() => {
            const haveToken = Cookies.get(TOKEN_COOKIE)
            if (!haveToken) {
                setIntervalId(0)
                refreshSession()
            }
        }, 30 * 60 * 1000) // 30 minutes
        setIntervalId(newIntervalId)
    }, [])

    const renderStore = isLoading ? (
        <LoadingOverlay open={true} />
    ) : (
        <UserContext.Provider
            value={{
                currentUser,
                authState,
                refreshSession,
                signOut,
                idToken,
                setIdToken,
                getUserDetails,
            }}
        >
            {children}
        </UserContext.Provider>
    )

    return renderStore
}
