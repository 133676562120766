import api from 'api/api'
import { FlexView, LoadingOverlay } from 'components/common'
import FormCard from 'containers/form/upload/FormCard'
import StockTabs from 'containers/layout/StockTabs'
import UploadActualBox from 'containers/upload/UploadActualBox'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { UploadContext } from 'stores/UploadStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { UPLOAD_TYPES } from 'utils/constants'

import { xlsxValidator } from '../../utils/xlsxSchemaValidation'

const FlexWrap = styled(FlexView)`
    flex-wrap: wrap;
`

const FlexPage = styled(FlexView)`
    align-self: stretch;
    align-items: flex-start;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 32px);
`

const UploadActualData = () => {
    const { setPageSubtitle, pageLoading, setPageLoading, generateValid } = useContext(AppContext)
    const { idToken } = useContext(UserContext)
    const { actualFile, setSchemaErrors, setUploadData, schemaErrors, isUploading, setIsUploading, setActualFile } =
        useContext(UploadContext)
    const { t } = useTranslation()
    const { height } = useWindowSize(0)
    const [deadSpace, setDeadSpace] = useState(0)

    useEffect(() => {
        setPageSubtitle(' - ' + t('Upload Actual Data'))
    }, [setPageSubtitle, t])

    useEffect(() => {
        setDeadSpace(550)
    }, [height])

    useEffect(() => {
        async function validateSchema() {
            try {
                if (isUploading) {
                    setIsUploading(false)
                    setPageLoading(true)
                    const type = { schemaType: UPLOAD_TYPES.ACTUAL_DATA }
                    const schema = await api.getValidationSchema(type, idToken)
                    if (schema) {
                        xlsxValidator(
                            actualFile,
                            (result) => {
                                if (result.errors === 'PIPE_DATA_QUANTITY_ERROR') {
                                    setActualFile({})
                                    setPageLoading(false)
                                    setIsUploading(false)
                                    setSchemaErrors([])
                                    setUploadData([])
                                    toast.error(t(result.errors))
                                } else if (result.errors.length === 0) {
                                    setUploadData(result.data)
                                    setSchemaErrors([])
                                    setPageLoading(false)
                                } else {
                                    setSchemaErrors(result.errors)
                                    setUploadData([])
                                    setPageLoading(false)
                                    toast.error(t('Upload Error Message'))
                                }
                            },
                            schema,
                            UPLOAD_TYPES.ACTUAL_DATA,
                            generateValid,
                        )
                    }
                }
            } catch (error) {
                setActualFile({})
                setPageLoading(false)
                setIsUploading(false)
                setSchemaErrors([])
                setUploadData([])
                toast.error(error.message)
            }
        }
        validateSchema()
    }, [
        actualFile,
        idToken,
        setSchemaErrors,
        setPageLoading,
        t,
        setActualFile,
        setUploadData,
        schemaErrors,
        isUploading,
        setIsUploading,
        generateValid,
    ])

    return (
        <FlexPage>
            <LoadingOverlay visible={pageLoading} />
            <StockTabs group="upload"> </StockTabs>
            <FlexWrap flexDirection="row" alignItems="flex-start" alignSelf="stretch" margin="0" padding="0">
                <FormCard type={UPLOAD_TYPES.ACTUAL_DATA}> </FormCard>
            </FlexWrap>
            <UploadActualBox height={height - deadSpace}></UploadActualBox>
        </FlexPage>
    )
}

export default UploadActualData
