import api from 'api/api'
import { Button, Card, FlexView } from 'components/common'
import { Input } from 'components/form'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'

// import ConfirmModal from 'containers/modal/ConfirmModal'

const Grid = styled(FlexView)`
    display: grid;
    grid-template-columns: repeat(6, auto);

    @media screen and (max-width: 3000px) {
        grid-template-columns: repeat(10, auto);
    }

    @media screen and (max-width: 2400px) {
        grid-template-columns: repeat(9, auto);
    }

    @media screen and (max-width: 2200px) {
        grid-template-columns: repeat(8, auto);
    }

    @media screen and (max-width: 1920px) {
        grid-template-columns: repeat(7, auto);
    }

    @media screen and (max-width: 1660px) {
        grid-template-columns: repeat(6, auto);
    }

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(5, auto);
    }

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(4, auto);
    }

    @media screen and (max-width: 1040px) {
        grid-template-columns: repeat(3, auto);
    }

    @media screen and (max-width: 820px) {
        grid-template-columns: repeat(2, auto);
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, auto);
    }
`

const FlexWrap = styled(FlexView)`
    flex-wrap: wrap;
    margin-left: 15px;
`

const CardWrap = styled(Card)`
    flex-wrap: wrap;
    margin: 0px 20px 0px 0px;
`
const FlexButton = styled(FlexView)`
    padding: 1px 1px 0px;
    flex-wrap: wrap;
    min-width: 1rem;
    max-width: 13rem;
    margin: 0px 0px 0px 15px;
    /* align-self: center; */
`
const FlexInput = styled(FlexView)`
    padding: 1px 8px 0px;
    flex-wrap: wrap;
    margin: 0px 10px 0px 10px;
    align-self: stretch;
`

const FormButton = styled(Button)`
    margin: 20px 10px 5px 0px;
    max-width: 13rem;
    width: 13rem;
    flex-wrap: wrap;
    display: flex;
    align-self: stretch;
    color: white;
`

const SupplierForm = ({ title = 'Enter Information', setCanLoad }) => {
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const { t } = useTranslation()
    const { setPageLoading } = useContext(AppContext)
    const { idToken, currentUser } = useContext(UserContext)

    const onCreate = useCallback(async () => {
        try {
            setPageLoading(true)
            const result = await api.createSupplier(
                {
                    cod_plant: code,
                    dsc_plant: description,
                    change_owner: currentUser.firstName,
                },
                idToken,
            )
            if (result) {
                setCode('')
                setDescription('')
                setPageLoading(false)
                setCanLoad(true)
                toast.success(t('Supplier successfully created') + '!')
            }
        } catch (error) {
            toast.error(error.message)
            setPageLoading(false)
        }
    }, [code, currentUser, description, idToken, setCanLoad, setPageLoading, t])

    return (
        <CardWrap alignSelf="stretch">
            <FlexWrap fontSize="big" fontWeight="bold" color="secondary" alignSelf="flex-start">
                {t(title)}
            </FlexWrap>
            <Grid flexDirection="row" justifyContent="flex-start">
                <FlexInput>
                    <Input
                        placeholder={t('Insert Name')}
                        label={t('Supplier Name')}
                        value={code}
                        messageType="warning"
                        onChange={(e) => setCode(e.target.value)}
                        minWidth="13rem"
                        width="13rem"
                        maxLength="100"
                    />
                </FlexInput>
                <FlexInput>
                    <Input
                        placeholder={t('Insert Description')}
                        label={t('Supplier Description')}
                        value={description}
                        messageType="warning"
                        onChange={(e) => setDescription(e.target.value)}
                        minWidth="13rem"
                        width="13rem"
                        maxLength="100"
                    />
                </FlexInput>
                <FlexButton alignSelf="center" justifyContent="center">
                    <FormButton backgroundColor="primary" disabled={!code || !description} onClick={() => onCreate()}>
                        {t('Create').toUpperCase()}
                    </FormButton>
                </FlexButton>
            </Grid>
        </CardWrap>
    )
}

export default SupplierForm
