import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import * as _ from 'lodash'

// import * as path from 'path'

// import { toast } from 'react-toastify'

const EXCEL_EXTENSION = '.xlsx'
const EXCEL_BLOB_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
// const root = '/var/www/'

export const exportAsExcelFile = (json, excelFileName) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Data')
    worksheet.columns = _.chain(json ? json[0] : []).keys().map(m => ({header: m, key: m})).value()
    worksheet.addRows(json)
    const filenamePath = excelFileName + EXCEL_EXTENSION

    workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: EXCEL_BLOB_TYPE }); 
        FileSaver.saveAs(blob, filenamePath);
    });
}

export const exportAsExcelFileMultiSheet = (json, excelFileName) => {
    const workbook = new ExcelJS.Workbook()

    json.forEach(function (row) {
        const sheetName = row.name
        console.log(row.data)

        const worksheet = workbook.addWorksheet(sheetName)
        worksheet.columns = _.chain(row?.data ? row.data[0] : []).keys().map(m => ({header: m, key: m})).value()
        worksheet.addRows(json)
        
    })

    const filenamePath = excelFileName + EXCEL_EXTENSION

    workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: EXCEL_BLOB_TYPE }); 
        FileSaver.saveAs(blob, filenamePath);
    });
}
