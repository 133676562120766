export const assignMetadataStrategies = {
    PIPEDATA_NOMINAL: 'pipedata_nominal',
    PIPEDATA_REAL: 'pipedata_real',
}

export const UPLOAD_TYPES = {
    NOMINAL_DATA: 'NOMINAL_DATA',
    ACTUAL_DATA: 'ACTUAL_DATA',
    CERTIFICATES: 'CERTIFICATES',
    PACKING_LIST: 'PACKING_LIST',
}

export const SCHEMA_TYPE = {
    NOMINAL_DATA: 'NOMINAL_DATA_INPUT',
    ACTUAL_DATA: 'ACTUAL_DATA_INPUT',
}

export const userGuide = 'USER_GUIDE'

export const loadStatusIcon = ['warning', 'clock', 'check', 'cross']
export const loadStatusColor = ['warning', 'primary', 'success', 'error']

export const hasFileStyle = {
    false: {
        icon: 'cross',
        color: 'error',
    },
    null: {
        icon: 'cross',
        color: 'error',
    },
    true: {
        icon: 'check',
        color: 'success',
    },
}

export const LOAD_STATUS = {
    NOT_CONCLUDED: 0,
    PENDING_VALIDATION: 1,
    VALIDATED: 2,
    REJECTED: 3,
}

export const LOAD_DATA_TYPE = {
    ALL_DATA: 0,
    NOMINAL_DATA: 1,
    PIPE_DATA: 2,
    UNDEFINED: 3,
}

export const s3Actions = {
    putObject: 'putObject',
    getObject: 'getObject',
}

export const ACTUAL_DUPLICATE_PROPERTIES = ['val_id', 'external_id']

export const NOMINAL_DUPLICATE_PROPERTIES = ['erp_ref']

export const REFERENCE_SCREEN_SIZE = 1040

export const DEFAULT_UPLOAD_FILE_TYPES =
    '.xls, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .csv, .pdf, .doc, .docx, .jpg, .jpeg, .png, .ppt, .pptx'
