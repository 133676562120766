import axios from 'axios'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'VLR-Authorization': btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))
  }
})

addLogger(instance, SHOULD_DECOMPRESS)

export default {
  getDataFromWms: async (params, idToken) => {
    try {
      const response = await instance.post('/wms/get-from-wms', params, getDefaultHeader(idToken))

      return manageResponse(response)
    } catch (error) {
      manageError(error)
    }
  },
  sendDataToWms: async (params, idToken) => {
    try {
      const response = await instance.post('/wms/post-to-wms', params, getDefaultHeader(idToken))

      return manageResponse(response)
    } catch (error) {
      manageError(error)
    }
  }
}