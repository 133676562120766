import React, { useEffect, useState, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FlexView, Button, Icon, LoadingOverlay } from 'components/common'
import { REFERENCE_SCREEN_SIZE } from 'utils/constants'
import { AppContext } from 'stores/AppStore'
import { useWindowSize } from 'hooks/useWindowSize'
import { UserContext } from 'stores/UserStore'
import TableCard from 'containers/table/TableCard'
import wmsApi from 'api/wms-api'
import { Select } from 'components/form'
import { useCallback } from 'react'

const FlexPage = styled(FlexView)`
  align-self: stretch;
  align-items: flex-start;
  margin: 0px 0px 0px 10px;
  width: calc(100% - 32px);
`

const BackButton = styled(Button)`
  font-family: 'Roboto';
  background: none;
  border-radius: 0px;
  border: none;
  box-shadow: none;

  color: gray;
  font-weight: bold;
  margin: 0px;

  &:active {
    opacity: 0.4;
  }
`

const IconBack = styled(Icon)`
  width: 24px;
  height: 24px;
  margin: 0px 16px 0px 0px;
`

const PrintersManagement = () => {
  const { setPageSubtitle, pageLoading, setPageLoading } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const { height } = useWindowSize()
  const { t } = useTranslation()
  const history = useHistory()

  const [deadSpace, setDeadSpace] = useState(0)
  const [printersData, setPrintersData] = useState([])
  const [tagServerResult, setTagServerResult] = useState({})
  const [availableTemplates, setAvailableTemplates] = useState([])
  const [availableSizes, setAvailableSizes] = useState([])
  const [hasAvailableRfid, setHasAvailableRfid] = useState(true)
  const [selectedTemplate, setSelectedTemplate] = useState()
  // eslint-disable-next-line
  const [_selectedSize, setSelectedSize] = useState() //Find a better way to use this hook (read `value` tag on `Select` component bellow)
  // eslint-disable-next-line
  const [_selectedRfid, setSelectedRfid] = useState() //Find a better way to use this hook (read `value` tag on `Select` component bellow)

  const formatAvailablePrinters = (printers) => {
    const keys = Object.keys(printers)
    const allPrinters = []

    for (const key of keys) {
      const currPrinterObj = printers[key]
      const printerConfig = {
        printer: key,
        template: currPrinterObj.tagname,
        size: currPrinterObj.tagsize,
        rfid: currPrinterObj.rfid,
        tagType: currPrinterObj.tagtype,
      }

      allPrinters.push(printerConfig)
    }

    return allPrinters
  }

  const formatTemplates = (templatesNames) => {
    const allTemplates = []

    if (templatesNames) {
      for (const template of templatesNames) {
        const currTemplate = {
          label: template,
          value: template,
        }

        allTemplates.push(currTemplate)
      }
    }

    return allTemplates
  }

  const formatSizes = (sizes) => {
    const allSizes = []

    for (const size of sizes) {
      const currSize = {
        label: size,
        value: size,
      }

      allSizes.push(currSize)
    }

    return allSizes
  }

  const testPrinter = useCallback(
    async (printerName) => {
      try {
        setPageLoading(true)
        const params = {
          slug: 'WMS_PRINT_ITEMS',
          params: {
            printer: printerName,
            items: [{ puuid: 'PUUID', valid: 'A1B2C3D4E5F' }],
          },
        }

        await wmsApi.sendDataToWms(params, idToken)

        toast.success(t('Success Print'))
      } catch (error) {
        toast.error(error.message)
      } finally {
        setPageLoading(false)
      }
    },
    [t, setPageLoading, idToken]
  )

  const savePrinterConfig = useCallback(
    async (row) => {
      try {
        setPageLoading(true)
        const { printer, rfid, template, size, tagType } = row
        const params = {
          slug: 'WMS_PRINT_CONFIG',
          urlParam: printer,
          params: {
            tagsize: size,
            printersize: size,
            rfid: rfid === 'Yes' ? true : false,
            tagname: template,
            tagtype: tagType,
          },
        }

        await wmsApi.sendDataToWms(params, idToken)
        toast.success(t('Save Printer'))
      } catch (error) {
        toast.error(error.message)
      } finally {
        setPageLoading(false)
      }
    },
    [t, setPageLoading, idToken]
  )

  useEffect(() => {
    setDeadSpace(height > REFERENCE_SCREEN_SIZE ? 720 : 610)
  }, [height])

  useEffect(() => {
    setPageSubtitle(` - ${t('Tag Printing')}`)
  }, [setPageSubtitle, t])

  useEffect(() => {
    (async () => {
      try {
        setPageLoading(true)
        const tagServer = await wmsApi.getDataFromWms({ slug: 'WMS_PRINT_CONFIG' }, idToken)
        if(tagServer?.result){
          const printers = formatAvailablePrinters(tagServer?.result?.printersconfig)
          setPrintersData(printers)
          setTagServerResult(tagServer?.result)
        }
      } catch (error) {
        toast.error(error.message)
        setPrintersData([])
      } finally {
        setPageLoading(false)
      }
    })()
  }, [setPageLoading, idToken])

  useEffect(() => {
    const templates = tagServerResult?.availabletemplates?.map((item) => item.name)
    const formattedTemplates = formatTemplates(templates)

    setAvailableTemplates(formattedTemplates)
  }, [tagServerResult])

  useEffect(() => {
    if (selectedTemplate) {
      const templateObj = tagServerResult?.availabletemplates?.filter((item) => item.name === selectedTemplate)
      const formattedSizes = formatSizes(templateObj?.[0]?.sizes)

      setAvailableSizes(formattedSizes)
      setHasAvailableRfid(templateObj?.[0]?.availablerfid)
    }
  }, [selectedTemplate, tagServerResult])

  const printersColumns = useMemo(
    () => [
      {
        accessor: 'printer',
        Header: t('Printer'),
        show: true,
        Cell: ({ row }) => <span style={{ opacity: 1 }}>{row.original.printer}</span>,
      },
      {
        id: 'template',
        Header: t('Tag Template'),
        disableFilters: true,
        show: true,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <Select
            removePosition={true}
            value={row.original.template} //Not using the hook here to avoid updates on all printers
            options={availableTemplates}
            onChange={(e) => {
              setSelectedTemplate(e)
              row.original.template = e
            }}
          />
        ),
      },
      {
        id: 'size',
        Header: t('Tag Size'),
        disableFilters: true,
        show: true,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <Select
            placeholder={row.original.size}
            removePosition={true}
            value={row.original.size} //Not using the hook here to avoid updates on all printers
            options={availableSizes}
            onChange={(e) => {
              setSelectedSize(e)
              row.original.size = e
            }}
          />
        ),
      },
      {
        id: 'rifd',
        Header: 'RFID?',
        disableFilters: true,
        show: hasAvailableRfid,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <Select
            removePosition={true}
            placeholder={row.original.rfid ? 'Yes' : 'No'} //Not using the hook here to avoid updates on all printers
            value={row.original.rfid}
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            onChange={(e) => {
              setSelectedRfid(e)
              row.original.rfid = e === 'Yes' ? true : false
            }}
          />
        ),
      },
      {
        id: 'save',
        disableFilters: true,
        show: true,
        Header: t('Save configuration'),
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <FlexView width='100%' height='40px' alignItems='center' justifyContent='center'>
            <Button
              alignItems='center'
              justifyContent='center'
              height='100%'
              backgroundColor='primary'
              color='white'
              fontSize="medium"
              margin="15px"
              width="15rem"
              onClick={() => {
                savePrinterConfig(row.original)
              }}
            >
              {t('Save Tag')}
            </Button>
          </FlexView>
        ),
      },
      {
        id: 'test',
        disableFilters: true,
        Header: t('Test printer'),
        show: true,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <FlexView width='100%' height='40px' alignItems='center' justifyContent='center'>
            <Button
              alignItems='center'
              justifyContent='center'
              backgroundColor='primary'
              color='white'
              fontSize="medium"
              margin="15px"
              width="15rem"
              height='100%'
              onClick={async () => {
                await testPrinter(row.original.printer)
              }}
            >
              {t('Test Tag')}
            </Button>
          </FlexView>
        ),
      },
    ],
    [t, testPrinter, savePrinterConfig, availableTemplates, availableSizes, hasAvailableRfid]
  )

  return (
    <FlexPage>
      <LoadingOverlay visible={pageLoading} />
      <FlexView flexDirection='row' alignItems='flex-end' width='100%'>
        <BackButton flexDirection='row' onClick={() => history.push('/')} fontSize='subtitle'>
          <IconBack name='arrow-left' />
          <div>{t('Go Back')}</div>
        </BackButton>
      </FlexView>
      <FlexView width='100%' height='100%' margin='10px 0 10px 10px'>
        <TableCard columns={printersColumns} tableData={printersData} customDeadSpace={deadSpace} title='Printers' />
      </FlexView>
    </FlexPage>
  )
}

export default PrintersManagement
