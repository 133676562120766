import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import UploadBox from 'components/upload/UploadBox'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadContext } from 'stores/UploadStore'
import styled from 'styled-components'

import { REFERENCE_SCREEN_SIZE } from '../../utils/constants'
import { exportErrorsToSheet } from '../../utils/xlsxSchemaValidation'

const Grid = styled(FlexView)`
    display: grid;
    grid-template-columns: repeat(1, 100%);

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(1, 100%);
    }

    @media screen and (max-width: 920px) {
        grid-template-columns: repeat(1, 100%);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 100%);
    }
`

const UploadActualBox = ({ height }) => {
    const { t } = useTranslation()
    // setPackingListFile, packingListFile,
    const { schemaErrors, actualFile, setActualFile, createProduct, enable } = useContext(UploadContext)

    return (
        <>
            <Grid width="100%">
                <UploadBox
                    fontSize={height > REFERENCE_SCREEN_SIZE ? 7 : 6}
                    fileTypes=".xlsx"
                    setFile={setActualFile}
                    text={!_.isEmpty(actualFile) ? actualFile?.name : t('Click or drag a Actual Data file to upload')}
                    height={height}
                ></UploadBox>

                {/* <UploadBox fontSize="5" fileTypes=".pdf" setFile={ setPackingListFile } 
                    text={ !_.isEmpty(packingListFile) ? packingListFile?.name : t('Click or drag a Packing List file to upload')}>
          </UploadBox> */}
            </Grid>
            <FlexView flexDirection="row" justifyContent="flex-end" alignItems="flex-end" width="100%">
                {schemaErrors.length === 0 ? (
                    <Button
                        backgroundColor="primary"
                        color="white"
                        fontSize="big"
                        margin="15px"
                        width="15rem"
                        disabled={!enable}
                        onClick={() => createProduct()}
                    >
                        {' '}
                        {t('SAVE')}{' '}
                    </Button>
                ) : (
                    <Button
                        backgroundColor="error"
                        color="white"
                        fontSize="big"
                        margin="15px"
                        disabled={schemaErrors.length === 0}
                        onClick={() => exportErrorsToSheet(schemaErrors)}
                        width="15rem"
                    >
                        {' '}
                        {t('Download Errors Log')}{' '}
                    </Button>
                )}
            </FlexView>
        </>
    )
}

export default UploadActualBox
