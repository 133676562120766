import { decompressResponse } from 'utils/decompress'

export const getDefaultHeader = (token) => ({
    headers: { Authorization: token },
})

const isSuccessfulResponse = (response) => response && response.status === 200
const isUploadError = (response) => response && ( response?.data?.isUploadError || response?.data?.body?.isUploadError)
const hasMessageError = (response) => response && ( response?.data?.message || response?.data?.body?.messageError)

export const manageResponse = (response, shouldDecompress) => {
    if (isSuccessfulResponse(response)) {
        if (shouldDecompress) {
            return decompressResponse(response.data)
        }
        return response.data.body ? response?.data?.body : response.data
    } else {
        throw new Error("Couldn't retrieve data")
    }
}

export const manageError = (error) => {
  const { response } = error
  if (isUploadError(response)) {
      throw response?.data.hasOwnProperty('messageError') ? response.data : response.data?.body
  } else if (hasMessageError(response)) {
      throw Error(response?.data?.message || response?.data?.body?.messageError)
  } else {
      throw Error(response.data.messageError || error)
  }
}
