import { FlexView, Icon } from 'components/common'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { hasFileStyle } from 'utils/constants'

import { ExportContext } from './ExportStore'
import { toast } from 'react-toastify'
import { useCallback } from 'react'

const initialState = {
    nominalData: [],
    actualData: [],
    originalActualData: [],
    loads: [],
    index: null,
}

export const PipeContext = React.createContext(initialState)

export const PipeProvider = ({ children }) => {
    const { onDownloadClick } = useContext(ExportContext)
    const { t } = useTranslation()
    const history = useHistory()

    const [nominalData, setNominalData] = useState([])
    const [actualData, setActualData] = useState([])
    const [originalActualData, setOriginalActualData] = useState([])
    const [originalNominalData, setOriginalNominalData] = useState([])

    const [loads, setLoads] = useState([])
    const [status, setStatus] = useState('')
    const [index, setIndex] = useState(null)
    const [idLoad, setIdLoad] = useState(null)
    const [idLoadData, setIdLoadData] = useState(null)
    const [loadDetails, setLoadDetails] = useState([])
    const [isNominalView, setIsNominalView] = useState(true)
    const [showPrintersCard, setShowPrintersCard] = useState(false)
    const [valIdsToPrint, setValIdsToPrint] = useState([])

    const initState = () => {
        setNominalData([])
        setActualData([])
        setOriginalActualData([])
        setIndex(null)
        setIsNominalView(true)
    }

    history.listen(() => {
        initState()
    })

    const headerIconClickFunction = useCallback((data) => {
        const toPrint = []
        if(data?.length > 3000){
            toast.warn(t('PRINT_LIMIT'))
        }
        else {
            data.forEach(item => {
                const currItem = {
                    Puuid: item?.pipe_no,
                    Valid: item?.val_id,
                }
    
                toPrint.push(currItem)
            })
    
            if (toPrint.length > 0) {
                setValIdsToPrint(toPrint)
                setShowPrintersCard(true)
            }
        }
    }, [ t ])

    const iconClickFunction = item => {
        const itemToPrint = {
            Puuid: item.original.pipe_no,
            Valid: item.original.val_id,
        }

        setValIdsToPrint([itemToPrint])
        setShowPrintersCard(true)
    }

    const nominalColumns = useMemo(
        () => [
            {
                accessor: 'erp_ref',
                Header: t('Erp Reference'),
                show: true,
            },
            {
                accessor: 'erp_dsc',
                Header: t('Erp Description'),
                show: true,
            },
            {
                accessor: 'cod_client',
                Header: t('End User'),
                show: true,
            },
            {
                accessor: 'serial_number',
                Header: t('Serial Number'),
                show: true,
            },
            {
                accessor: 'product_type',
                Header: t('Product Type'),
                show: true,
            },
            {
                accessor: 'length_nominal',
                Header: t('Length Nominal'),
                show: true,
            },
            {
                accessor: 'makeup_loss',
                Header: t('Makeup Loss'),
                formatter: makeup_loss => Number(makeup_loss),
                show: true,
            },
            {
                accessor: 'makeup_loss2',
                Header: t('Makeup Loss') + ' 2',
                formatter: makeup_loss2 => Number(makeup_loss2),
                show: true,
            },
            {
                accessor: 'weight_nominal',
                Header: t('Weight Nominal'),
                formatter: weight_nominal => Number(weight_nominal),
                show: true,
            },
            {
                accessor: 'weight_nominal2',
                Header: t('Weight Nominal') + ' 2',
                formatter: weight_nominal2 => Number(weight_nominal2),
                show: true,
            },
            {
                accessor: 'od_nominal',
                Header: t('OD (")'),
                formatter: od_nominal => Number(od_nominal),
                show: true,
            },
            {
                accessor: 'od_nominal2',
                Header: t('OD (")') + ' 2',
                formatter: od_nominal2 => Number(od_nominal2),
                show: true,
            },
            {
                accessor: 'wt_nominal',
                Header: t('Wall Thickness'),
                formatter: wt_nominal => Number(wt_nominal),
                show: true,
            },
            {
                accessor: 'wt_nominal2',
                Header: t('Wall Thickness') + ' 2',
                formatter: wt_nominal2 => Number(wt_nominal2),
                show: true,
            },
            {
                accessor: 'grade',
                Header: t('Grade'),
                show: true,
            },
            {
                accessor: 'grade2',
                Header: t('Grade') + ' 2',
                show: true,
            },
            {
                accessor: 'connection',
                Header: t('Connection'),
                show: true,
            },
            {
                accessor: 'connection2',
                Header: t('Connection') + ' 2',
                show: true,
            },
            {
                accessor: 'coupling_option',
                Header: t('Coupling Option'),
                show: true,
            },
            {
                accessor: 'coupling_option2',
                Header: t('Coupling Option') + ' 2',
                show: true,
            },
            {
                accessor: 'drift',
                Header: t('Drift'),
                show: true,
            },
            {
                accessor: 'standard',
                Header: t('Standard'),
                show: true,
            },
            {
                accessor: 'revision',
                Header: t('Revision'),
                show: true,
            },
            {
                accessor: 'is_modified_product',
                Header: t('Is Modified Product') + '?',
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="flex-start" width="100%" flexDirection="row">
                        <font size="2" color={hasFileStyle[value]?.color}>
                            {' '}
                            {value ? t('Yes') : t('No')}{' '}
                        </font>
                        <Icon
                            name={hasFileStyle[value]?.icon}
                            width="16px"
                            height="16px"
                            margin="0px 4px 0px 8px"
                            color={hasFileStyle[value]?.color}
                        />
                    </FlexView>
                ),
                show: true,
            },
            {
                accessor: 'modified_product_desc',
                Header: t('Modified Product Description'),
                show: true,
            },
            {
                accessor: ({ load_date }) => (load_date ? moment(load_date).format('YYYY-MM-DD  HH:mm:ss') : ''),
                sortType: 'date',
                Header: t('Load Date'),
                show: true,
            },
        ],
        [t],
    )

    let actualDataColumns = useMemo(
        () => [
            {
                id: 'print',
                accessor: 'print',
                Header: t('Print'),
                disableFilters: true,
                hasIcon: true,
                iconName: 'print',
                headerIconClickFunction: headerIconClickFunction,
                show: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { row } }) => (
                    <FlexView alignItems="center" justifyContent="center" width="100%">
                        <Icon name="print" width="16px" height="16px" onClick={() => iconClickFunction(row)} />
                    </FlexView>
                ),
            },
            {
                accessor: 'val_id',
                Header: t('Valid'),
                show: true,
            },
            {
                accessor: 'external_id',
                Header: t('ExternalId'),
                show: true,
            },
            {
                accessor: 'pipe_no',
                Header: t('IPPN'),
                show: true,
            },
            {
                accessor: 'num_sales_order',
                Header: t('Sales Order'),
                show: true,
            },
            {
                accessor: 'num_sales_item',
                Header: t('Sales Item'),
                show: true,
            },
            {
                accessor: 'co_purchase_order',
                Header: t('Customer Purchase Order'),
                show: true,
            },
            {
                accessor: 'co_purchase_item',
                Header: t('Customer Purchase Item'),
                show: true,
            },
            {
                accessor: 'erp_ref',
                Header: t('Erp Reference'),
                show: true,
            },
            {
                accessor: 'erp_dsc',
                Header: t('Erp Description'),
                show: true,
            },
            {
                accessor: 'product_type',
                Header: t('Product Type'),
                show: true,
            },
            {
                Header: t('Length Real'),
                accessor: 'length_real',
                formatter: length_real => parseFloat(Number(length_real).toFixed(3)),
                show: true,
            },
            {
                Header: t('Weight Real'),
                accessor: 'weight_real',
                formatter: weight_real => parseFloat(Number(weight_real).toFixed(3)),
                show: true,
            },
            {
                accessor: 'int_diameter_real',
                Header: t('Int Diameter Real'),
                formatter: int_diameter_real => Number(int_diameter_real),
                show: true,
            },
            {
                accessor: 'out_diameter_real',
                Header: t('Out Diameter Real'),
                formatter: out_diameter_real => Number(out_diameter_real),
                show: true,
            },
            {
                accessor: 'heat',
                Header: t('Heat'),
                show: true,
            },
            {
                accessor: 'length_nominal',
                Header: t('Length Nominal'),
                show: true,
            },
            {
                accessor: 'makeup_loss',
                Header: t('Make Up Loss'),
                formatter: makeup_loss => Number(makeup_loss),
                show: true,
            },
            {
                accessor: 'weight_nominal',
                Header: t('Weight Nominal'),
                formatter: weight_nominal => Number(weight_nominal),
                show: true,
            },
            {
                accessor: 'weight_nominal2',
                Header: t('Weight Nominal') + ' 2',
                formatter: weight_nominal2 => Number(weight_nominal2),
                show: true,
            },
            {
                accessor: 'revision',
                Header: t('Revision'),
                show: true,
            },
            {
                accessor: 'standard',
                Header: t('Standard'),
                show: true,
            },
            {
                accessor: 'drift',
                Header: t('Drift'),
                show: true,
            },
            {
                accessor: 'od_nominal',
                Header: t('OD (")'),
                formatter: od_nominal => Number(od_nominal),
                show: true,
            },
            {
                accessor: 'od_nominal2',
                Header: t('OD (")') + ' 2',
                formatter: od_nominal2 => Number(od_nominal2),
                show: true,
            },
            {
                accessor: 'wt_nominal',
                Header: t('Wall Thickness'),
                formatter: wt_nominal => Number(wt_nominal),
                show: true,
            },
            {
                accessor: 'wt_nominal2',
                Header: t('Wall Thickness') + ' 2',
                formatter: wt_nominal2 => Number(wt_nominal2),
                show: true,
            },
            {
                accessor: 'grade',
                Header: t('Grade'),
                show: true,
            },
            {
                accessor: 'grade2',
                Header: t('Grade') + ' 2',
                show: true,
            },
            {
                accessor: 'connection',
                Header: t('Connection'),
                show: true,
            },
            {
                accessor: 'connection2',
                Header: t('Connection') + ' 2',
                show: true,
            },
            {
                accessor: 'coupling_option',
                Header: t('Coupling Option'),
                show: true,
            },
            {
                accessor: 'coupling_option2',
                Header: t('Coupling Option') + ' 2',
                show: true,
            },
            {
                accessor: 'consignment_type',
                Header: t('Consignment Type'),
                show: true,
            },
            {
                accessor: 'serial_number',
                Header: t('Serial Number'),
                show: true,
            },
            {
                accessor: 'end_user',
                Header: t('End User'),
                show: true,
            },
            {
                accessor: 'id_plant',
                Header: t('Id Plant'),
                show: true,
            },
            {
                accessor: 'cod_plant',
                Header: t('Supplier'),
                show: true,
            },
            {
                accessor: 'is_modified_product',
                Header: t('Is Modified Product') + '?',
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="flex-start" width="100%" flexDirection="row">
                        <font size="2" color={hasFileStyle[value]?.color}>
                            {' '}
                            {value ? t('Yes') : t('No')}{' '}
                        </font>
                        <Icon
                            name={hasFileStyle[value]?.icon}
                            width="16px"
                            height="16px"
                            margin="0px 4px 0px 8px"
                            color={hasFileStyle[value]?.color}
                        />
                    </FlexView>
                ),
                show: true,
            },
            {
                accessor: 'modified_product_desc',
                Header: t('Modified Product Description'),
                show: true,
            },
            {
                accessor: 'id_quality_certificate',
                Header: 'Id ' + t('Quality Certificate'),
                show: true,
            },
            {
                accessor: 'num_certificate',
                Header: t('Certificate Number'),
                show: true,
            },
            {
                accessor: 'desc_version',
                Header: t('Version Description'),
                show: true,
            },
            {
                accessor: 'has_certificate_file',
                Header: t('Has Certificate File') + ' ?',
                Cell: ({ cell: { value, row } }) => (
                    <FlexView alignItems="center" justifyContent="flex-start" width="100%" flexDirection="row">
                        <font size="2" color={hasFileStyle[value]?.color}>
                            {' '}
                            {value ? t('Yes') : t('No')}{' '}
                        </font>
                        <Icon
                            name={hasFileStyle[value]?.icon}
                            width="16px"
                            height="16px"
                            margin="0px 4px 0px 8px"
                            color={hasFileStyle[value]?.color}
                        />
                    </FlexView>
                ),
                show: true,
            },
            {
                id: 'download',
                Header: t('Download'),
                disableFilters: true,
                show: true,
                customHeaderProps: {
                    style: {
                        minWidth: '32px',
                    },
                },
                Cell: ({ cell: { row } }) => (
                    <FlexView alignItems="center" justifyContent="center" width="100%">
                        <Icon
                            name="download"
                            width="16px"
                            height="16px"
                            onClick={() =>
                                row.original.has_certificate_file ? onDownloadClick(row.original.certificate_path) : ''
                            }
                            disabled={!row.original.has_certificate_file}
                        />
                    </FlexView>
                ),
            },
        ],
        [t, onDownloadClick, headerIconClickFunction],
    )

    useEffect(() => {
        if (index !== undefined && index !== null) {
            if (isNominalView) {
                nominalColumns[index].show = !nominalColumns[index].show
            } else {
                actualDataColumns[index].show = !actualDataColumns[index].show
            }
            setIndex(null)
        }
    }, [index, actualDataColumns, nominalColumns, isNominalView])

    return (
        <PipeContext.Provider
            value={{
                ...initialState,
                actualDataColumns,
                index,
                setIndex,
                actualData,
                setActualData,
                originalActualData,
                setOriginalActualData,
                nominalData,
                setNominalData,
                loads,
                setLoads,
                idLoad,
                setIdLoad,
                loadDetails,
                setLoadDetails,
                nominalColumns,
                status,
                setStatus,
                idLoadData,
                setIdLoadData,
                isNominalView,
                setIsNominalView,
                originalNominalData,
                setOriginalNominalData,
                showPrintersCard,
                setShowPrintersCard,
                valIdsToPrint,
            }}
        >
            {children}
        </PipeContext.Provider>
    )
}