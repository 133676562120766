import { FlexView } from 'components/common'
import { Input, Select } from 'components/form'
import GenerateValIdSelect from 'containers/common/GenerateValIdSelect'
import UnitSelectActual from 'containers/common/UnitSelectActual'
import UnitSelectNominal from 'containers/common/UnitSelectNominal'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'stores/AppStore'
import { FormContext } from 'stores/FormStore'
import { UploadContext } from 'stores/UploadStore'
import { UserContext } from 'stores/UserStore'
import styled from 'styled-components'
import { UPLOAD_TYPES } from 'utils/constants'

import api from '../../../api/api'

const FlexWrap = styled(FlexView)`
    flex-wrap: wrap;
`

const FormCardContent = ({ type }) => {
    const { t } = useTranslation()
    const { plant, setPlant, endUser, setEndUser, loadTitle, setLoadTitle, isSaving } = useContext(UploadContext)
    const { endUserOptions } = useContext(FormContext)
    const { idToken } = useContext(UserContext)
    const { setPageLoading } = useContext(AppContext)

    const [showMessage, setShowMessage] = useState(false)
    const [canLoad, setCanLoad] = useState(true)

    const [plants, setPlants] = useState([])

    useEffect(() => {
        async function fetchData() {
            try {
                if (canLoad) {
                    setPageLoading(true)
                    const result = await api.getSuppliers({}, idToken)
                    setCanLoad(false)
                    if (result) {
                        setPlants(
                            result.map((item) => ({
                                label: item.cod_plant,
                                value: item.id_plant,
                            })),
                        )
                        setPageLoading(false)
                    } else {
                        setPageLoading(false)
                    }
                }
            } catch (error) {
                setPageLoading(false)
                throw new Error(error)
            }
        }
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSaving) {
            if (!loadTitle) {
                setShowMessage(true)
            } else {
                setShowMessage(false)
            }
        }
    }, [loadTitle, isSaving])

    const onChangeTitle = (value) => {
        setLoadTitle(value)
        if (value !== '') {
            setShowMessage(false)
        }
    }

    return (
        <FlexWrap flexDirection="row" alignItems="center" justifyContent="flex-start">
            <Input
                width="13rem"
                placeholder={t('Insert Load Title')}
                label={t('Load Title')}
                value={loadTitle}
                messageType="warning"
                onChange={(e) => onChangeTitle(e.target.value)}
                warning={showMessage}
                message={showMessage ? t('Required Field') : ''}
                margin="0rem 1rem 0rem 0rem"
            />
            {type === UPLOAD_TYPES.ACTUAL_DATA ? (
                <Select
                    width="13rem"
                    label={t('Supplier')}
                    placeholder={plant ? plant : t('Select a Supplier')}
                    options={plants}
                    value={plant}
                    onChange={(plant) => {
                        setPlant(plant)
                    }}
                    margin="0vh 1rem 0vh 0vh"
                />
            ) : (
                ''
            )}
            <Select
                width="13rem"
                label={t('End User')}
                placeholder={endUser ? endUser : t('Select End User')}
                options={endUserOptions}
                value={endUser}
                onChange={(endUser) => {
                    setEndUser(endUser)
                }}
                margin="0vh 1rem 0vh 0vh"
            />

            {type === UPLOAD_TYPES.NOMINAL_DATA ? (
                <UnitSelectNominal></UnitSelectNominal>
            ) : (
                <UnitSelectActual></UnitSelectActual>
            )}
            {type === UPLOAD_TYPES.ACTUAL_DATA ? <GenerateValIdSelect /> : null}
        </FlexWrap>
    )
}

export default FormCardContent
